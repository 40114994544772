import { HostListener, Component, OnInit, Output, Input, ElementRef , Renderer2, ViewChild, AfterViewChecked} from '@angular/core';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { configJson } from '../../../shared/services/dashboard-config.service';
import { cloneDeep } from 'lodash';
import { CommonService } from './../../../shared/services/common.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ModalComponent} from './../../../shared/modal/modal.component';
import { TableModalComponent} from './../../../shared/table-modal/table-modal.component';
import { IncentivePointsModalComponent } from './../../../shared/incentive-points-modal/incentive-points-modal.component';
import { WeeklyFastStartBonusModalComponent } from '../weekly-fast-start-bonus-modal/weekly-fast-start-bonus-modal.component';
import { ApiConstant } from '../../constants/api-constant';
import { LocalstorageService } from '../../services/localstorage.service';
import { CommonConstant } from '../../constants/common-constant';
import { TableService } from './table.service';
import { forkJoin } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import { ChartsService } from '../chart/charts.service';
import Helper from '../../utils/helper';

declare var $:any;
interface TableSearch {
    column:string ,
    value: string ,
    api?: string
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewChecked {
    @Input() defaulttableConfig: any;
    @Input() my_modal_title: string;
    @Input() my_modal_popupObj: object;
    @Input() my_modal_content: object;
    @Output() product_count_arr: Array<object> = [];
    @Input() my_table_modal_object: any;
    @Input() incentive_modal_object: any;
    @Input() performanceModalObj: any;
    @ViewChild('breadcrumbDiv', { static: false }) breadcrumbDiv: ElementRef;
    @ViewChild('tableContainer', { static: false }) tableContainer: ElementRef;
    @ViewChild('mapTablePopupDiv', { static: false }) mapTablePopupDiv: ElementRef;
    @Input() events: Observable<void>;
    chartInfo = configJson.charts;
    userInfo: object;
    activeTab: number;
    userRole:string = '';
    orderByConfig: Array<object> = [];
    orderByConfigTap: Array<object> = [];
    tableClickEvents: Array<object> = [];
    tableClickEventsOn: Array<object> = [];
    commissiontableClickEvent: Array<object> = [];
    tableDataConfigCommission: Array<object> = [];
    yearlyReportSaleClickEvents: Array<object> = [];
    yearlyReportClickEvents: Array<object> = [];
    columnSearchOn: Array<object> = [];
    searchObj: Array<TableSearch>;
    allCarriersArr: Array<object> = [];
    crmId:string= '';
    pageOffset: number;
    clientType: string = '';
    getTableConfig: {};
    isTAPRequired: string;
    agentMoreDetails: string;
    lineOfBusinessFilter: String;
    popupLineOfBusiness: any;
    companyFilter: string;
    activeTabId: number;
    activeSubTabId: number;
    charetoptions:any;
    data:any;
    obj:any;
    getParameters: object = {};
    response: object = {};
    seachTableColunBy: string = '';
    seachTableColunIndex: string = '';
    popupSearchObj: Array<Object> = [];
    my_columns: object[] = [];
    keyStrokesDelay: number = 2000;
    table: any;
    buttons:Array<Object> = [];
    showReportToggle:Boolean = false;
    selectedColumnName: string = '';
    agentMarketingCompany: string = '';
    hierarchyDetails: Object = {
        'levelName': '',
        'hierarchyId': '',
        'hierarchyName': ''
    }
    sapAndTapObj:any;
    incentiveAgentId: string = '';
    modalOptions:NgbModalOptions;
    productCountInfoArray: any;
    drilledDownAgentId: any;
    drilledDownAgentLevel:any;
    drilledDownAgentName: string;
    breadcrumbFilters: Array<Object> = [];
    multiple_agency_levels:Array<Object> = [];
    multipleAgencyLevelsArr: Array<object> = [];
    contractDateInterval: any ='';
    activeAgentTab: any;
    performanceTapYesNo: boolean = false;
    q1Title: string = '';
    q2Title: string = '';
    chartObj : any;
    private eventsSubscription: Subscription;
    private updateTableSubscription: Subscription;
    agentPopupDetailsObj: any ={};
    agentDetailsObject: any ;
    showModalPopup: boolean = false;
    toDate: any;
    fromDate: any;
    popupDateCount: number = 0;
    showOverride: number;
    isYOYFlag: boolean;
    isApplicationsApplicable: string;
    showPopupSubmittedSubTab: string;
    showPremiumSubtab: string = 'yes';
    isPlacedToggleRequired: string;
    modalLOB: any;
    modalProduct: any;
    reportTotal: any;
    popupProducts: any;
    policyDropdownList = [];
    policySelectedItems = [];
    policyDropdownSettings = {};
    carrierOrAgencyDropdownList = [];
    carrierOrAgencySelectedItems = [];
    carrierOrAgencyDropdownSettings = {};
    curr_year = new Date().getFullYear();
    last_year = new Date().getFullYear() - 1;
    private yoytooltipSubscription: Subscription;
    baseFilterLob: string;
    performanceSapTapEnable: boolean = false;
    currentTableData:any = [];
    agentLevel: string;
    program_start_date:string;
    program_end_date:string;
    apiName:string;
    params: {[key: string]:any};
    policiesStartDate = ''
    policiesEndDate = ''
    tableTooltips;
    isUpdatePieChartFlag: boolean = false;
    constructor(
        private _commonService: CommonService,
        private modalService: NgbModal,
        private localStorageService: LocalstorageService,
        private spinner: NgxSpinnerService,
        private tableService: TableService,
        private renderer:Renderer2,
        private chartService : ChartsService
    ) { }
    ngAfterViewChecked(): void {
        this.updateMultiselectText();
        if(this.activeTabId === 5 && [27,37,30].includes(this.activeSubTabId)) {
            if(this.userRole != 'Agent' && ![ApiConstant.getAgentBonusIncentiveReport, ApiConstant.getFastStartBonusForTrainer, ApiConstant.getReferralBonusIncentiveReport, 
                ApiConstant.getWeeklyAwardIncentiveReport, ApiConstant.getWeeklyPremiumByAgent, ApiConstant.getIncentiveProgramAgents, ApiConstant.getPolicyAlerts, ApiConstant.getBrokerageBucks].includes(this.apiName)) {
              $('#breadcrumb').show();
            } else {
              $('#breadcrumb').hide();
            }
            if([ApiConstant.getWeeklyPremiumByAgent].includes(this.apiName)){
                $('#terminatedAgentRecords').hide();
            }
          } else if(this.activeTabId === 5 && this.activeSubTabId === 13) {
            $('#breadcrumb').hide();
          }
    }

    ngOnInit(): void {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.multiple_agency_levels = this.userInfo['multiple_agency_levels'];
        this.activeTabId = this.localStorageService.get('activeTabId')
        let activeSubTab = this.localStorageService.get('activeSubTab');
        if(activeSubTab && Object.keys(activeSubTab).length){
            this.activeSubTabId = activeSubTab['id'];
        }
        this.keyStrokesDelay = this.userInfo['keyStrokesDelay'];
        this.userRole = this.userInfo['role'];
        this.isTAPRequired = this.userInfo['isTAPRequired'];
        this.isApplicationsApplicable = this.userInfo['isApplicationsApplicable'];
        this.isPlacedToggleRequired = this.userInfo['isPlacedToggleRequired'];
        this.agentMoreDetails = configJson['agentMoreDetails'];
        this.companyFilter = this.userInfo['companyFilter'];
        this.lineOfBusinessFilter = this.userInfo['lineOfBusinessFilter'];
        this.showOverride = this.userInfo['show_override'];
        this.tableTooltips = configJson['tableTooltips'];
        this.searchObj = [];
        this.crmId = configJson.crmId;
        this.pageOffset = configJson.pageOffset;
        this.clientType = configJson.clientType;
        this.modalOptions = {
           backdrop:'static',
           backdropClass:'customBackdrop'
        }
        let params = this._commonService.getParameters();
        this.sapAndTapObj = params['sapOrTapToggle'];
        this.getData();
        if(this.events){
            this.eventsSubscription = this.events.subscribe(() => {
                this.getData()
            });
        }

        this.updateTableSubscription = this._commonService.updateTableData.subscribe(res=>{
            this.getData();
        })
    }

    tableColumnConfig = (config) => {
        this.orderByConfig = config.tableColumnConfig.orderByConfig;
        this.orderByConfigTap = config.tableColumnConfig.orderByConfigTap;
        this.tableClickEvents = config.tableClickEvents.tableClickEvents;
        this.tableClickEventsOn = config.tableClickEvents;
        this.commissiontableClickEvent = config.tableClickEvents.commissiontableClickEvent;
        this.yearlyReportSaleClickEvents = config.tableClickEvents.yearlyReportSaleClickEvents;
        this.yearlyReportClickEvents = config.tableClickEvents.yearlyReportClickEvents;
        this.columnSearchOn = config.tableClickEvents.columnSearchOn;
        let params = this._commonService.getParameters();
        if(params['sapOrTapToggle']['analyticsTabTapValue'] === 'tap'){
            this.orderByConfig = this.orderByConfigTap.filter((el) => {
                return el['role'] === this.userRole;
            });
        } else {
            this.orderByConfig = this.orderByConfig.filter((el) => {
                return el['role'] === this.userRole;
            });
        }
    }

    onPerformanceSapTapToggleon = (val, detailsObj) => {
        this.performanceSapTapEnable = val;
        let strVal = (val === true) ? 'tap' : 'sap';
        let params = this._commonService.getParameters();
        params['sapOrTapToggle']['analyticsTabTapValue'] = strVal;
        this.performancePopupFiltersChanged();
    }

    @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.closeCustom();
        }
    }

    // get the clicked element
    @HostListener('click', ['$event'])
        onClick(e: any) {
        this._commonService.parametersObj = cloneDeep(this._commonService.getParameters());
        this._commonService.setParameters('param5', 'All');
        var columnDefs;
        let activeSubTab = this.localStorageService.get('activeSubTab');
        if(activeSubTab){
            this.activeSubTabId = activeSubTab['id'];
        }
        //this._commonService.showLoader();
        if (this.activeTabId === 2 && this.activeSubTabId === 1 && activeSubTab['api'] !== ApiConstant.getAgentSummary) {
            // console.log('ssss',e, e.srcElement, $(this))
            // e.path?$(e.path[1]).toggleClass('selected'):'';
            e?.srcElement?.closest('tr')?.classList?.toggle('selected');
        }
        var weekNo = $(e.target).attr('weekNo');
        var agentid = $(e.target).attr('agentid')?$(e.target).attr('agentid'): $(e.target).parent().attr('agentid');
        var agentlevel = this.agentLevel = $(e.target).attr('level')?$(e.target).attr('level'): $(e.target).parent().attr('agentlevel');
        var isYOY = $(e.target).attr('isyoy');
        this.isYOYFlag = $(e.target).attr('isyoy');
        var idType = $(e.target).attr('idtype');
        var agentname = $(e.target).attr('agentname')? $(e.target).attr('agentname'):  $(e.target).parent().attr('agentname');
        var AWNHiddenId = $(e.target).attr('AWNHidden');
        this.localStorageService.set('AWNHiddenId', AWNHiddenId);
        this.localStorageService.set('ProductLine', $(e.target).attr('ProductLine'));
        var agentmarketingcompany = $(e.target).attr('agentmarketingcompany');
        this.selectedColumnName = $(e.target).attr('column');
        this.localStorageService.set('selectedColumnName', this.selectedColumnName);
        var levelarr = $(e.target).parent().parent().attr('levelname')?  $(e.target).parent().parent().attr('levelname'): [];
        var idarr = $(e.target).parent().parent().attr('hierarchyid')? $(e.target).parent().parent().attr('hierarchyid'): [];
        var namearr = $(e.target).parent().parent().attr('hierarchyname')? $(e.target).parent().parent().attr('hierarchyname'): [];
        this.policiesStartDate = $(e.target).attr('startDate');
        this.policiesEndDate = $(e.target).attr('endDate');
        if(levelarr && levelarr.includes(',') && idarr && idarr.includes(',')){
            levelarr =  levelarr.split(',');
            idarr = idarr.split(',');
        }
        if(namearr && namearr.includes('||')){
            namearr = namearr.split('||');
        }

        if(this.companyFilter === 'yes'){
            // var agentmarketingcompany = ? $(e.target).attr('agentmarketingcompany') : '';
            if($(e.target).attr('agentmarketingcompany') ){
                this.agentMarketingCompany = agentmarketingcompany
            }
        } else {
            this.agentMarketingCompany = '';
        }

        this.hierarchyDetails = {
            'levelName': levelarr,
            'hierarchyId': idarr,
            'hierarchyName': namearr
        }
        // if($(e.target)[0].innerText === 'level1'){
        //     //this.getSubTabBreadcrumbContent(agentid, agentlevel, '', agentname, '', this.agentMarketingCompany);
        // } else
        if((e.target.parentElement.parentElement.className) === 'breadcrumb-item'){
            var agentid = $(e.target.parentElement).attr('agentid');
            var agentlevel = $(e.target.parentElement).attr('agentlevel');
            var agentname = $(e.target.parentElement).attr('agentname');
            this._commonService.showLoader();
            //
            if(this.hierarchyDetails && this.hierarchyDetails['levelName']){
                var breadcrumbFilters =[];
                var hideBreadcrumbFlag = false;
                if(this.userRole.includes('level')){
                    hideBreadcrumbFlag = true;
                }
                if(Array.isArray(this.hierarchyDetails['levelName'])){
                    this.hierarchyDetails['levelName'].forEach((ele, index) => {
                        breadcrumbFilters.push({
                            agentid: this.hierarchyDetails['hierarchyId'][index],
                            level: ele
                        });
                        this.addToBreadcrumb(this.hierarchyDetails['hierarchyName'][index], this.hierarchyDetails['hierarchyId'][index], ele, $(e.target).attr('api'), hideBreadcrumbFlag);
                    })
                } else {
                    breadcrumbFilters.push({
                        agentid: this.hierarchyDetails['hierarchyId'],
                        level: this.hierarchyDetails['levelName']
                    });
                    this.addToBreadcrumb(this.hierarchyDetails['hierarchyName'], this.hierarchyDetails['hierarchyId'], this.hierarchyDetails['levelName'] , $(e.target).attr('api'), hideBreadcrumbFlag);
                }
                this.breadcrumbFilters = breadcrumbFilters;
            }
            // if(agentid && agentlevel){
            //     breadcrumbFilters.push({
            //         agentid: agentid,
            //         level: agentlevel
            //     });
            // }

            this.getMultipleAgencyValue();
            this.hideBreadcrumbs(breadcrumbFilters);
            let api = $(e.target.parentElement).attr('api');
            if(this.activeTabId === 2 && this.activeSubTabId === 1 && api === 'undefined'){
                api = ApiConstant.getAgentSummaryDetails;
            }
            this.getSubTabBreadcrumbContent(agentid, agentlevel, api, agentname, '', this.agentMarketingCompany);
        } else if ($(e.target).attr('column') === 'Agency/Agent Name') {
            if (
              $(e.target).attr('api') === 'getManagerQuarterlyIncentiveReport'
              || $(e.target).attr('api') === 'getASMAgentYearEndBonusIncentiveReport'
              || $(e.target).attr('api') === 'getProductionAllowanceIncentiveReport'
              || $(e.target).attr('api') === 'getFastStartBonusIncentiveReport'
              || $(e.target).attr('api') === 'getConventionIncentiveReportAris'
              || $(e.target).attr('api') === 'getConventionIncentiveReportAmericare'
              || $(e.target).attr('api') === 'getFastStartBonusForTrainer'
              || $(e.target).attr('api') === 'getReferralBonusIncentiveReport'
              || $(e.target).attr('api') === 'getAgencyAnalyticsReport'
              || $(e.target).attr('api') === 'getAgencyYearlyReport'
              || $(e.target).attr('api') === 'getAgencyYearlyReportSales'
              || $(e.target).attr('api') === 'getAgentTapDownlineReport'
              || $(e.target).attr('api') === 'getAgentTapDownlineReportSales'
              || $(e.target).attr('api') === 'getRsmBonusIncentiveReport'
              || $(e.target).attr('api') === 'getSipIncentiveReport'
              || $(e.target).attr('api') === 'getManagerSipIncentiveReport'
              || $(e.target).attr('api') === 'getQuarterlyProductMixBonus'
              || $(e.target).attr('api') === 'getNewManagerQuarterlyIncentiveReport'
              ) {
                this._commonService.showLoader();
                var breadcrumbFilters =[];
                if(this.hierarchyDetails && this.hierarchyDetails['levelName']){
                    var hideBreadcrumbFlag = false;
                    if(this.userRole.includes('level')){
                        hideBreadcrumbFlag = true;
                    }
                    if(Array.isArray(this.hierarchyDetails['levelName'])){
                        this.hierarchyDetails['levelName'].forEach((ele, index) => {
                            breadcrumbFilters.push({
                                agentid: this.hierarchyDetails['hierarchyId'][index],
                                level: ele
                            });
                            this.addToBreadcrumb(this.hierarchyDetails['hierarchyName'][index], this.hierarchyDetails['hierarchyId'][index], ele, $(e.target).attr('api'), hideBreadcrumbFlag);
                        })
                    } else {
                        breadcrumbFilters.push({
                            agentid: this.hierarchyDetails['hierarchyId'],
                            level: this.hierarchyDetails['levelName']
                        });
                        this.addToBreadcrumb(this.hierarchyDetails['hierarchyName'], this.hierarchyDetails['hierarchyId'], this.hierarchyDetails['levelName'] , $(e.target).attr('api'), hideBreadcrumbFlag);
                    }
                }
                if(agentid && agentlevel){
                    breadcrumbFilters.push({
                        agentid: agentid,
                        level: agentlevel
                    });
                }
                this.breadcrumbFilters = breadcrumbFilters;
                // this.addToBreadcrumb(agentname, agentid, agentlevel, $(e.target).attr('api'), hideBreadcrumbFlag);
                this.getMultipleAgencyValue();
                this.hideBreadcrumbs(breadcrumbFilters);
                this.getSubTabBreadcrumbContent(agentid, agentlevel, $(e.target).attr('api'), agentname, '', this.agentMarketingCompany);
            } else {
                this.openDropDown(e.target);
            }
        } else if ($(e.target).attr('functionToCall') === 'openPointsChart') {
            var columnDefs: any = [];
            this.openPointsChartModal(agentid, 'getPointsDistribution', `${$(e.target).attr('pointTitle') ? $(e.target).attr('pointTitle') : 'Points'} for ` + agentname, columnDefs, $(e.target).attr('idType'), agentlevel);
        } else if ($(e.target).attr('functionToCall') === 'openWeeklyFastStart') {
            var columnDefs: any = [];
            this.showOpenWeeklyFastStartModal(agentid, ApiConstant.getAgentWeeklyFastStartBonus, "Weekly Fast Start Bonus", weekNo);
        } else if ($(e.target).attr('functionToCall') === 'openTrainerFastStartBonus') {
          var columnDefs: any = [];
          let trainerId = $(e.target).attr('trainerId');
          let trainerName = $(e.target).attr('trainerName');
          this.showAgentsForTrainerFastStartModal(trainerId, ApiConstant.getAgentsForTrainer, 'Fast Start - Trainee For ' + trainerName, columnDefs, idTypeValue, openPoliciesPopupId, this.agentMarketingCompany);
        }else if ($(e.target).attr('functionToCall') === 'openReferredAgentsModal') {
          var columnDefs: any = [];
          let referralId = $(e.target).attr('referralId');
          let referralName = $(e.target).attr('referralName');
          this.showReferredAgentsModal(referralId, ApiConstant.getReferredAgents, 'Referred Count For ' + referralName, columnDefs, idTypeValue, openPoliciesPopupId, this.agentMarketingCompany);
        } else if ($(e.target).attr('column') === this.crmId &&  this.activeTabId !== 3 && !['openPointsDistribution'].includes($(e.target).attr('functionToCall'))) {
            this.openDropDown(e.target);
        } else if($(e.target).attr('functionToCall') === 'openAgentDetailsModal'){
            var level = $(e.target).attr('level');
            var month = $(e.target).attr('column');
            var level = $(e.target).attr('level');
            this.openAgentDetailsModal(agentid, AWNHiddenId, isYOY, level, idType, e.target.innerText, agentname, this.agentMarketingCompany);
        } else if($(e.target).attr('functionToCall') === 'openPendingPoliciesModal'){
            columnDefs = [];
            var apiName = '';
            var openPendingPoliciesPopupId = '';
            var pendingPoliciesAgentName = '';
            if ( this.activeTabId == 6) {
                apiName = ApiConstant.getPendingPoliciesForMissingAgent;
                openPendingPoliciesPopupId = agentid;
                pendingPoliciesAgentName = agentname ? agentname : agentid;
            } else {
                apiName = ApiConstant.getPendingPolicies;
                openPendingPoliciesPopupId = ($(e.target).attr('idType') !== 'crm') ? AWNHiddenId : agentid;
                pendingPoliciesAgentName = agentname;
            }
            this.openPendingPoliciesModal(agentid, apiName, 'Pending Policies by: ' + pendingPoliciesAgentName, columnDefs, $(e.target).attr('idType'), openPendingPoliciesPopupId,  this.agentMarketingCompany);
        } else if ($(e.target).attr('functionToCall') === 'openLapsedPoliciesModal') {
            columnDefs = [];
            this.openPendingPoliciesModal(agentid, 'getAgentLapsedPolicies', 'Lapsed Policies by: ' + agentname, columnDefs, $(e.target).attr('idType'), AWNHiddenId, this.agentMarketingCompany);
        } else if ($(e.target).attr('functionToCall') === 'openExpiringPoliciesModal') {
            columnDefs = [];
            agentid = AWNHiddenId;
            this.openPendingPoliciesModal(agentid, 'getpolicies', 'Expiring Policies by: ' + agentname, columnDefs, $(e.target).attr('idType'), AWNHiddenId, this.agentMarketingCompany);
        } else if ($(e.target).attr('functionToCall') === 'openTerminatedPoliciesModal') {
            columnDefs = [];
            agentid = AWNHiddenId;
            this.openPendingPoliciesModal(agentid, 'getpolicies', 'Terminated Policies by: ' + agentname, columnDefs, $(e.target).attr('idType'), AWNHiddenId, this.agentMarketingCompany);
        } else if($(e.target).attr('functionToCall') === 'openPoliciesModal'){
            var openPoliciesPopupId = '';
            var idTypeValue = '';
            var apiName = '';
            var policiesAgentName = '';
            if (this.clientType === 'carrier' &&  this.activeTabId == 2 && this.sapAndTapObj.reportsTabTapValue === 'sap' && $('#toggleAgents .toggleActive').attr('api') === ApiConstant.getAgentSummary) {
                openPoliciesPopupId = ($(e.target).attr('idType') !== 'crm') ? AWNHiddenId : agentid;
                idTypeValue = $(e.target).attr('idType');
            } else if (this.clientType === 'carrier' && this.sapAndTapObj.reportsTabTapValue === 'sap' && $('#toggleAgents .toggleActive').attr('api') === ApiConstant.getAgentSummary) {
                openPoliciesPopupId = AWNHiddenId;
                idTypeValue = 'agent';
            } else {
                if ( this.activeTabId == 6) {
                    openPoliciesPopupId = agentid;
                } else {
                    openPoliciesPopupId = ($(e.target).attr('idType') !== 'crm') ? AWNHiddenId : agentid;
                }
                idTypeValue = $(e.target).attr('idType');
            }
            if ( this.activeTabId == 6) {
                apiName = ApiConstant.getPoliciesForMissingAgent;
                policiesAgentName = agentname ? agentname : agentid;
            } else if (this.activeTabId === 2 && this.activeSubTabId === 33) {
                apiName = ApiConstant.getPaymentPolicies;
                policiesAgentName = agentname;
                agentid = AWNHiddenId;
                if($('#toggleAgents .toggleActive').attr('type') === '$'){
                    openPoliciesPopupId = AWNHiddenId;
                    idTypeValue = 'agent';
                }
            } else {
                apiName = ApiConstant.getPolicies;
                policiesAgentName = agentname;
                if(this.activeTabId === 2 && this.activeSubTabId === 1){
                    agentid = AWNHiddenId;
                    if($('#toggleAgents .toggleActive').attr('type') === '$'){
                        openPoliciesPopupId = AWNHiddenId;
                        idTypeValue = 'agent';
                    }
                }
                if(this.activeSubTabId == 27){
                    this._commonService.setParameters('temp_level', this.agentLevel);
                }
            }
            this.openPoliciesModal(agentid, apiName, 'Policies by: ' + policiesAgentName, columnDefs, idTypeValue, openPoliciesPopupId, this.agentMarketingCompany);
        } else if ($(e.target).attr('functionToCall') === 'openSubmittedApplicationsModal') {
                columnDefs = [];
                var openPoliciesPopupId = '';
                var idTypeValue = '';
                var apiName = '';
                var policiesAgentName = '';
                idTypeValue = $(e.target).attr('idType');
                if(this.activeTabId === 2 && this.activeSubTabId === 25){
                    // if($('#toggleAgents .toggleActive').attr('type') === '$'){
                    //     idTypeValue = 'agent';
                    // } else {
                    //     idTypeValue = 'crm';
                    // }
                    if($('#toggleAgents .toggleActive').attr('type') === '$' ){
                        idTypeValue = 'agent';
                    } else if($('#toggleAgents .toggleActive').attr('type') === '#'){
                        if($('#breadcrumb li').length>1){
                            idTypeValue = 'agent';
                        }   else {
                            idTypeValue = 'crm';
                        }
                    }
                }
                let args = {}
                if(this.apiName === 'getPolicyAlerts' && this.activeSubTabId === 23 && $(e.target).attr('column') === 'AV Ref #'){
                    args['appId'] = $(e.target).attr('appid');
                    args['insuredName'] = $(e.target).attr('insured');
                    args['AWNHiddenVal'] = $(e.target).attr('awnhiddenvalue');
                }
                openPoliciesPopupId = (idTypeValue !== 'crm') ? AWNHiddenId : agentid;
                apiName = 'getApplications';
                policiesAgentName = agentname;
                this.openSubmittedApplicationsModal(agentid, apiName, 'Applications by: ' + policiesAgentName, columnDefs, idTypeValue, openPoliciesPopupId, '', args);
        } else if ($(e.target).attr('functionToCall') === 'openYearlyPoliciesModal') {
            this.getbreadCrumbFilter(agentid, agentlevel);
            var month = $(e.target).attr('column');
            var level = $(e.target).attr('level');
            var openYearlyPoliciesPopupId = AWNHiddenId;
            var api = 'getYearlyPolicies';
            if(this.activeTabId === 5 && this.activeSubTabId === 13){
                this.activeAgentTab = parseInt($('#agentReportsToggle .toggleActive').val());
                if(this.activeAgentTab === 3){
                    api = 'getAgentReportPolicies';
                } else if(this.activeAgentTab === 2){
                    if(this.sapAndTapObj.reportsTabTapValue === 'sap'){
                        api = 'getAgentReportPolicies';
                    } else {
                        api = 'getYearlyPoliciesTAP';
                    }
                } else {
                    if(this.sapAndTapObj.reportsTabTapValue === 'tap'){
                        api = 'getYearlyPoliciesTAP';
                    }
                }
            }
            this.openYearlyPoliciesModal(agentid, month, level, api, 'Policies by: ' + agentname, openYearlyPoliciesPopupId, this.activeAgentTab, this.agentMarketingCompany);
        } else if ($(e.target).attr('functionToCall') === 'openLifeAnnuityYearlyPoliciesModal') {
                var month = $(e.target).attr('column');
                var lifeAndAnnuityPlan = $(e.target).attr('plan');
                columnDefs = [];
                var apiName = this.sapAndTapObj.reportsTabTapValue === 'sap' ? 'getLifeAndAnnuityPolicies' : 'getLifeAndAnnuityPoliciesTap';
                this.openLifeAnnuityYearlyPoliciesModal('', month, lifeAndAnnuityPlan, apiName, 'Plan by: ' + lifeAndAnnuityPlan, '');
        } else if ($(e.target).attr('functionToCall') === 'openTapPoliciesModal') {
            var openTapPoliciesPopupId;
            var idTypeValue = '';
            idTypeValue = $(e.target).attr('idType');
            if (this.sapAndTapObj.agentsTabTapValue === 'tap' || this.sapAndTapObj.analyticsTabTapValue === 'tap') {
                openTapPoliciesPopupId = agentid;
            } else {
                openTapPoliciesPopupId = (idTypeValue !== 'crm') ? AWNHiddenId : agentid;
            }
            this.openTapPoliciesModal(agentid, 'getTapPolicies', 'TAP Policies by: ' + agentname, columnDefs, $(e.target).attr('idType'), openTapPoliciesPopupId, '');
        } else if ($(e.target).attr('functionToCall') === 'openCommissionDetailsModal') {
            var openCommissionStatementsId = ($(e.target).attr('idType') !== 'crm') ? AWNHiddenId : agentid;
            idTypeValue = $(e.target).attr('idType');
            var commissionDetailsApi = '';
            var commissionDetailsAgentName = agentname ? agentname : agentid;
            if (this.activeTabId === 6) {
                commissionDetailsApi = 'getCommissionStatementsForMissingAgents';
                commissionDetailsAgentName = agentname ? agentname : agentid;
                openCommissionStatementsId = agentid;
            } else {
                commissionDetailsApi = 'getCommissionStatements';
                commissionDetailsAgentName = agentname;
            }
            this.openCommissionDetailsModal(agentid, commissionDetailsApi, 'Commission Statement for : ' + commissionDetailsAgentName, columnDefs, $(e.target).attr('idType'), openCommissionStatementsId, this.agentMarketingCompany);
        } else if ($(e.target).attr('functionToCall') === 'openPointsDistribution') {
            var columnDefs: any = [];
            this.openPointsDistributionModal(agentid, 'getPointsDistribution', 'Points Distribution for ' + agentname, columnDefs, $(e.target).attr('idType'), AWNHiddenId);
        } else if ($(e.target).attr('functionToCall') === 'openIncentivePoliciesModal') {
            var columnDefs: any = [];
            this.openIncentivePoliciesModal(agentid, 'getIncentivePolicies', 'Policies by: ' + agentname, columnDefs, $(e.target).attr('idType'), AWNHiddenId, '');
        }
    }

    hideBreadcrumbs = function(breadcrumbFilters){
        if(this.userRole.includes('level')){
            var agencyHideFromIndex = 0;
            if(this.multipleAgencyLevelsArr){
                this.multipleAgencyLevelsArr.forEach((ele, index) => {
                    var str = ele.split('=');
                    let leveIndex = breadcrumbFilters.findIndex(o => o.level === str[0]);
                    let agentIdIndex =  breadcrumbFilters.findIndex(o => o.agentid === str[1]);
                    if(leveIndex !=-1 && agentIdIndex!=-1 && leveIndex == agentIdIndex){
                        agencyHideFromIndex = leveIndex;
                    }
                });
                $('#breadcrumb li').each(function (index, ele) {
                    if(agencyHideFromIndex+1 < index){
                        // $(ele).css('display','inline-block');
                    }
                });
            }
        }
    }

    getbreadCrumbFilter = function(agentid, agentlevel){
        var breadcrumbFilters =[];
        if(this.hierarchyDetails && this.hierarchyDetails.levelName){
            if(Array.isArray(this.hierarchyDetails.levelName)){
                this.hierarchyDetails.levelName.forEach((ele, index) =>{
                    breadcrumbFilters.push({
                        agentid: this.hierarchyDetails.hierarchyId[index],
                        level: ele
                    });
                })
            } else {
                breadcrumbFilters.push({
                    agentid: this.hierarchyDetails.hierarchyId,
                    level: this.hierarchyDetails.levelName
                });
            }
        }
        if(agentid && agentlevel){
            breadcrumbFilters.push({
                agentid: agentid,
                level: agentlevel
            });
        }
        this.breadcrumbFilters = breadcrumbFilters;
    }

    getSubTabBreadcrumbContent = (agentid, agentlevel, api, text, breadcrumbClickFlag, agentMarketingCompany) =>{
        $('#spinner').show();
        $('#reportTable').remove();
        this.searchObj = [];
        this._commonService.setParameters('agentId', agentid);
        this._commonService.setParameters('level', agentlevel);
        this._commonService.setParameters('agentMarketingCompany', agentMarketingCompany);
        this._commonService.setParameters('breadcrumbFilters', this.breadcrumbFilters);
        if(api == 'getManagerQuarterlyIncentiveReport' || api == 'getProuctionAllowanceIncentiveReport' || api == 'getASMAgentYearEndBonusIncentiveReport' || api == 'getNewManagerQuarterlyIncentiveReport') {
          let row_data = this.currentTableData?.find(row => row['Agency/Agent Id'] == agentid);
          if(row_data) {
            this._commonService.setParameters('upline_premium', Helper.convertToNumber(row_data['Premium']));
            this._commonService.setParameters('upline_reward', Helper.convertToNumber(row_data['Reward']));
          }
        }
        var tableId = 'table2';
        var columnDefs = [];
        if ( this.activeTabId == 3) {
            if (this.userRole === 'Manager') {
                columnDefs = [{ 'visible': false, 'targets': [0,1] }];
            } else if (this.userRole === 'Agent') {
                columnDefs = [{ 'visible': false, 'targets': [0,3] }];
            } else {
                columnDefs = [{ 'visible': false, 'targets': [0,1] }];
            }
        } else if ( this.activeTabId == 4) {
            columnDefs = [{ 'visible': false, 'targets': [0,3] }]
        }
        var flag = 0;
        var position;
        $('#breadcrumb li').each(function (index, ele) {
            if($(ele).text() === text && (!ele.getElementsByTagName('a')[0].getAttribute('agentid')||ele.getElementsByTagName('a')[0].getAttribute('agentid')==agentid)) {
                flag = 1;
                position = index;
            } else if (text === 'Home') {
                flag = 1;
                position = index;
            }
        });
        if (!flag) {
            const div = this.renderer.createElement('li');
            const a = this.renderer.createElement('a');
            const span = this.renderer.createElement('span');
            const text1 = this.renderer.createText(text);
            this.renderer.setAttribute(a, 'agentid', agentid);
            this.renderer.setAttribute(a, 'agentname', text);
            this.renderer.setAttribute(a, 'agentlevel', agentlevel);
            this.renderer.setAttribute(a, 'api', api);
            this.renderer.setStyle(a, 'width', '100%');
            // this.renderer.listen(a, 'click', this.getSubTabBreadcrumbContent.bind(agentid, agentid, agentlevel, api, text, '', this.agentMarketingCompany));
            this.renderer.appendChild(span, text1);
            //Using parentNode to retrieve the Parent Node
            this.renderer.addClass(div, 'breadcrumb-item');
            this.renderer.appendChild( this.renderer.parentNode(this.breadcrumbDiv.nativeElement),div);
            this.renderer.appendChild( div,a);
            this.renderer.appendChild( a,span);

        } else {
            if (text === 'Home') {
                this.hierarchyDetails = {};
                this.breadcrumbFilters = [];
                $('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
            } else {
                $('#breadcrumb li:nth-child(' + (position + 1) + ')').nextAll().remove();
            }
        }
        if(breadcrumbClickFlag){
            this.hierarchyDetails = {};
            this.breadcrumbFilters = [];
            $('#breadcrumb >li:gt(0)>a').each(function (index, ele) {
                    this.breadcrumbFilters.push({
                    agentid: $(ele).attr("agentid"),
                    level: $(ele).attr("agentlevel")
                });
            });
        }

        this.tableService.setTableConfigObj({
            tabId: this.activeTabId,
            title: '',
            apiName: api,
            id: tableId,
            fileNameToExport:'',
            columnDefs:columnDefs});
        let tableConfig = this.tableService.getTableConfigObj();
        this._commonService.setParameters('breadcrumbFilters', this.breadcrumbFilters);
        let params = this._commonService.getParameters();
        params['sapOrTapToggle'] = this._commonService.getSapOrTapToggle()
        this.initializeTable(params, tableConfig);
    }

    getMultipleAgencyValue = function(){
        this.multipleAgencyLevelsArr = [];
        if(this.multiple_agency_levels){
            var multipleAgencyLevels = this.multiple_agency_levels.split('OR');
            if(multipleAgencyLevels.length){
                for(var i = 0; i< multipleAgencyLevels.length;i++){
                    this.multipleAgencyLevelsArr.push(multipleAgencyLevels[i].replace(/[() ]/g, ''));
                }
            } else {
                this.multipleAgencyLevelsArr.push(this.multiple_agency_levels.replace(/[() ]/g, ''));
            }
        }
        return this.multipleAgencyLevelsArr;
    }

    addToBreadcrumb = function(text, agentid, agentlevel, api, hideBreadcrumbFlag) {
        var flag = 0;
        var position = 0 ;
        $('#breadcrumb li').each((index, ele) => {
            // if ($(ele).text() === text) {
                flag = 1;
                position = index;
            // }
        });
        if (!flag) {
            var str = '';
            if(hideBreadcrumbFlag){
            } else {
            }
        } else {
            $('#breadcrumb li:nth-child(' + (position + 1) + ')').nextAll().remove();
        }
    }

    openDropDown =  (ele)=> {
        this.drilledDownAgentId = $(ele).attr('agentid');
        this.drilledDownAgentLevel = $(ele).attr('level');
        this.drilledDownAgentName = $(ele).attr('agentname');
        if ($(ele).hasClass('glyphicon glyphicon-chevron-up')) {
        } else {
            var agentname = $(ele).attr('agentname');
            var tableId = 'table2';
            const div = this.renderer.createElement('div');
            const text1 = this.renderer.createText(agentname);
            this.renderer.setAttribute(div, 'id', tableId);
            this.renderer.addClass(div, 'row');
            this.renderer.appendChild( this.renderer.parentNode(this.tableContainer.nativeElement),div);
            $('#spinner').show();
            if ($(ele).attr('column') === this.crmId) {
                let agentid = $(ele).attr('highriseid');
                this._commonService.setParameters('agentId', agentid);
                const div = this.renderer.createElement('li');
                const a = this.renderer.createElement('a');
                const span = this.renderer.createElement('span');
                const text1 = this.renderer.createText(agentname);
                this.renderer.setAttribute(a, 'agentid', agentid);
                this.renderer.setAttribute(a, 'agentname', agentname);
                this.renderer.setAttribute(a, 'level', $(ele).attr('level'));
                this.renderer.setAttribute(a, 'api', $(ele).attr('api'));
                this.renderer.appendChild(span, text1);
                //Using parentNode to retrieve the Parent Node
                this.renderer.addClass(div, 'breadcrumb-item');
                this.renderer.appendChild( this.renderer.parentNode(this.breadcrumbDiv.nativeElement),div);
                this.renderer.appendChild( div,a);
                this.renderer.appendChild( a,span);
                this.showAgentSummary(ele);
            } else {
                // params.agentId = $(ele).attr('agentid');
                let agentid = $(ele).attr('highriseid');
                this._commonService.setParameters('agentId', agentid);
                const div = this.renderer.createElement('li');
                const a = this.renderer.createElement('a');
                const span = this.renderer.createElement('span');
                const text1 = this.renderer.createText(agentname);
                this.renderer.setAttribute(a, 'agentid', agentid);
                this.renderer.setAttribute(a, 'agentname', agentname);
                this.renderer.appendChild(span, text1);
                //Using parentNode to retrieve the Parent Node
                this.renderer.addClass(div, 'breadcrumb-item');
                this.renderer.appendChild( this.renderer.parentNode(this.breadcrumbDiv.nativeElement),div);
                this.renderer.appendChild( div,a);
                this.renderer.appendChild( a,span);
                this.showAgencySummary(ele);
            }
        }
    };

    showAgentSummary(ele) {
        this._commonService.setParameters('level', $(ele).attr('level'));
        var api = $(ele).attr('api')? $(ele).attr('api'):'';
        var tableId = 'table2';
        var columnDefs;
        let params = this._commonService.getParameters();
        if(this.activeTabId === 2 && this.activeSubTabId === 25 || this.activeSubTabId === 26 || this.activeSubTabId === 33 ){
                this._commonService.setParameters('levelNumber', $(ele).attr('agencyname'));
                // api = 'getAgentApplicationSummaryRollUpDrill';
                params['agencyName'] = $(ele).attr('agencyname');
            }
            // else if(this.activeTabId === 2 &&  ){
            //     this._commonService.setParameters('levelNumber', $(ele).attr('agencyname'));
            //     // api = 'getAgentSummaryPersonalDetailsRollupDrill';
            //     params['agencyName'] = $(ele).attr('agencyname');
            // }  else if(this.activeTabId === 2 && ){
            //     this._commonService.setParameters('levelNumber', $(ele).attr('agencyname'));
            //     // api = 'getAgentPaymentSummaryDetails';
            //     params['agencyName'] = $(ele).attr('agencyname');
            // }
            else {
                if (this.sapAndTapObj.agentsTabTapValue === 'tap') {
                    // api = 'getAgentSummaryDetailsTap';
                    this._commonService.setParameters('param5', $(ele).attr('agentid'));
                    this._commonService.setParameters('levelNumber', $(ele).attr('agentlevel'));
                    columnDefs = [{ 'className': 'dt-left', 'targets': [0] }, { "visible": false, "targets": [2,3] }];
                } else {
                    // api = 'getAgentSummaryDetails';
                    this._commonService.setParameters('levelNumber', $(ele).attr('agencyname'));
                    columnDefs = [{ 'className': 'dt-left', 'targets': [0] }, { "visible": false, "targets": [1,2] }];
                }
            }
        this.tableService.setTableConfigObj({apiName:api,
                                            id:tableId,
                                            fileNameToExport:'',
                                            columnDefs:columnDefs});
        params['crmId'] =  this.userInfo['crm']
        this.initializeTable( params, this.tableService.getTableConfigObj());
    }

    showAgencySummary(ele) {
        let level = $(ele).attr('level');
        this._commonService.setParameters('level', level);
        var tableConfig = this.tableService.getTableConfigObj();
        var params = this._commonService.getParameters();
        this.initializeTable(params, tableConfig);
    }

    openYearlyPoliciesModal = function (id, month, level, api, header, awnHiddenId, activeAgentTab, agentMarketingCompany) {
        var width = '';
        var columnDefs = [];
        this.showYearlyPoliciesModal(id, month, level, api, header, columnDefs, width, awnHiddenId, activeAgentTab, agentMarketingCompany);
    };

    openLifeAnnuityYearlyPoliciesModal = function (id, month, plan, api, header, awnHiddenId) {
        var width = '';
        var columnDefs = [];
        this.showLifeAnnuityYearlyPoliciesModal(id, month, plan, api, header, columnDefs, width, awnHiddenId);
    };

    openTapPoliciesModal = function (id, api, header, columnDefs, idType, awnHiddenId, agentmarketingcompany) {
        this.showModal(id, api, header, [], '', idType, awnHiddenId, agentmarketingcompany);
    };

    openCommissionDetailsModal = function (id, api, header, columnDefs, idType, awnHiddenId, agentmarketingcompany) {
        var width = '';
        this.showModal(id, api, header, [], width, idType, awnHiddenId, agentmarketingcompany);
    };

    openIncentivePoliciesModal = function (id, api, header, columnDefs, idType, awnHiddenId, agentmarketingcompany) {
        this.showModal(id, api, header, columnDefs, '', idType, awnHiddenId, agentmarketingcompany);
    };

    openPointsChartModal = (id, api, header, columnDefs, idType, agentLevel) => {
        var width = '100%';
        const currentProgram = this.incentive_modal_object.name?this.incentive_modal_object.name:document.querySelector('#incentivePrograms .active #incentiveProgramname').innerHTML;
        this.showPointsChartModal(id, api, header, columnDefs, width, idType, currentProgram, agentLevel);
    };

    openPointsDistributionModal = function (id, api, header, columnDefs, idType, awnHiddenId) {
        var width = '100%';
        this.showModal(id, api, header, columnDefs, width, idType, awnHiddenId);
    };

    openPendingPoliciesModal(id, api, header, columnDefs, idType, awnHiddenId, agentmarketingcompany){
        var width = '';
        this.showModal(id, api, header, columnDefs, width, idType, awnHiddenId, agentmarketingcompany);
    }

    openPoliciesModal(id, api, header, columnDefs, idType, awnHiddenId, agentmarketingcompany){
        this.showModal(id, api, header, columnDefs, '', idType, awnHiddenId, agentmarketingcompany);
    }

    openSubmittedApplicationsModal(id, api, header, columnDefs, idType, awnHiddenId, agentmarketingcompany, args={}){
        this.showModal(id, api, header, columnDefs, '', idType, awnHiddenId, agentmarketingcompany, args);
    }

    //Open the modal(popup) showing agent details
    openAgentDetailsModal = (id, awnHiddenId, isYOY, level, idType, crmid, agentORAgentName, agentmarketingcompany)=> {
        this._commonService.setParameters('column', '');
        this.showModalPopup = true;
        this._commonService.parametersObj = cloneDeep(this._commonService.getParameters());
        var params = cloneDeep(this._commonService.getParameters());
        if(this.activeTabId === 6){
            params['param5'] = awnHiddenId ? awnHiddenId : id;
            params['missingAgentTab'] = 'true';
        } else {
            params['param5'] = awnHiddenId ? awnHiddenId : id;
            params['missingAgentTab'] = false;
        }
        this.showPopupSubmittedSubTab = 'no';
        if(this.isApplicationsApplicable === 'yes'){
            this.showPopupSubmittedSubTab = 'yes';
            this.showPremiumSubtab = 'no';
        }
        this.agentMarketingCompany = agentmarketingcompany
        params['agentMarketingCompany'] = agentmarketingcompany;
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth();
        var day = now.getDate();
        this.agentPopupDetailsObj = {
            popupHeading: 'Agent Id',
            id: id,
            agentORAgentName: agentORAgentName,
            crmId: crmid,
            crm: 'AgentVizion Id',
            awnHiddenId: awnHiddenId,
            end_date: now,
            idType: idType,
            start_date: new Date(year - 1, month + 1, day),
            level: level,
            clientType: this.clientType,
            agentMoreDetails: this.agentMoreDetails,
            agentMarketingCompany: agentmarketingcompany,
            fromDate:this.convertToDate(params['param3']),
            toDate: this.convertToDate(params['param4'])
        };
        var checkSapOrTap = 'sap';
		if(( this.activeTabId == 2 && params['sapOrTapToggle']['agentsTabTapVlue'] === 'tap') || ( this.activeTabId == 5 && params['sapOrTapToggle']['reportsTabTapValue'] === 'tap') || (this.activeTabId == 3 && params['sapOrTapToggle']['analyticsTabTapValue'] === 'tap')){
			checkSapOrTap = 'tap';
		}
        if(this.agentPopupDetailsObj['idType'] === 'crm' && this.agentPopupDetailsObj['level'] === 'Agent'){
            params['param5']= this.agentPopupDetailsObj['crmId'];
        }
        let reqObj = { tabId: this.activeTabId, sapOrTap: checkSapOrTap, lob: params['lob'], level: level, param5: params['param5'], agentId: params['param5'], idType: idType, missingAgentTab: params['missingAgentTab']};

        this._commonService.getProductsByAgent(reqObj).subscribe(res =>{
            this.agentPopupDetailsObj.products = this.tableService.formatProductList(res);
            this.agentPopupDetailsObj.selectedProduct = 'All';
        })

        if (isYOY) {
            this.agentPopupDetailsObj['popupHeading'] = 'Agency/Agent Name';
            this.performanceAgentDetailsModal(this.agentPopupDetailsObj);
        } else {
            this._commonService.showLoader();
            var agentPersonalDetailsApi = ApiConstant.agentPersonalDetailsApi;
            var businessByProductLineApi = ApiConstant.businessByProductLineApi;
            var businessByCarriersApi = ApiConstant.businessByCarriersApi;
            var agentSalesApi = ApiConstant.agentSalesApi;
            if(this.isApplicationsApplicable === 'yes') {
                this._commonService.setParameters('param11', 'application');
                agentSalesApi = ApiConstant.getAgentApplicationSales;
            }

            if ((this.activeTabId == 2 && this.sapAndTapObj.agentsTabTapValue === 'tap') || (this.activeTabId == 5 && this.sapAndTapObj.reportsTabTapValue === 'tap')|| (this.activeTabId ==3 && this.sapAndTapObj.analyticsTabTapValue === 'tap')) {
                params['param5'] = id; //agentId
                agentPersonalDetailsApi = ApiConstant.getAgentPersonalDetailsTap;
                businessByProductLineApi = ApiConstant.getBusinessByProductLineTap;
                businessByCarriersApi = ApiConstant.getBusinessByCarriersTap;
                agentSalesApi = ApiConstant.getAgentSalesTap;
            }

            if (this.activeTabId == 6 ) {
                params['param5'] = id; //agentId
                agentPersonalDetailsApi = ApiConstant.getMissingAgentPersonalDetails;
                businessByProductLineApi = ApiConstant.getBusinessByProductLineForMissingAgents;
                businessByCarriersApi = ApiConstant.getBusinessByCarriersForMissingAgents;
                agentSalesApi = ApiConstant.getAgentSalesForMissingAgents;
            }
            var agentPersonalDetailsTapParams =  {
                tabId: this.activeTabId,
                attribute1: params['attribute1'],
                param2: params['param2'],
                agentId: params['param5'],
                agentMarketingCompany: agentmarketingcompany
            }
            if(agentPersonalDetailsApi === 'getAgentPersonalDetailsTap'){
                agentPersonalDetailsTapParams  =    {
                    tabId: this.activeTabId,
                    attribute1: params['attribute1'],
                    param2: params['param2'],
                    agentId: params['param5'],
                    agentMarketingCompany: agentmarketingcompany
                }
            }
            this._commonService.agentPersonalDetailsApi(agentPersonalDetailsTapParams, agentPersonalDetailsApi).subscribe(response => {
                this.agentDetailsObject = response[0];
                if (this.activeTabId === 5 && this.activeSubTabId === 33){
                    params['level'] = this.agentPopupDetailsObj['level'];
                    params['idType'] = 'agent';
                    params['agentId'] = this.agentPopupDetailsObj['id'];
                    params['param10']  = 'category';
                }
                params['agentMarketingCompany'] = this.agentPopupDetailsObj['agentMarketingCompany'];
                popupParameter = params;
                //Pie chart for business by carrier
                var requiredChartByCarrier = this.getBusByCarrierChartProp(businessByCarriersApi);
                var chartCarrier = this.chartService.getChart(requiredChartByCarrier, params);
                chartCarrier.subscribe( (success) =>{
                    $('#showTooltip').hide();
                    this.agentPopupDetailsObj['agentCarrierGraphOptions'] = success['chart_options'];
                    this.agentPopupDetailsObj['agentCarrierGraphdata'] = success['chart_data'];

                    //Pie chart for Business by productline
                    var requiredLineChart = this.getBusByProductLineChartProp(businessByProductLineApi)
                    var chart = this.chartService.getChart(requiredLineChart, params);
                    chart.subscribe( (success)=> {
                        this.agentPopupDetailsObj['agentGraphOptions'] = success['chart_options'];
                        this.agentPopupDetailsObj['agentGraphdata'] = success['chart_data'];

                        //Line plus bar chart for agent sale
                        var requiredChart = this.getAgentSaleChartProp(agentSalesApi);
                        chart = this.chartService.getChart(requiredChart, params);
                        chart.subscribe( (success)=> {
                            this.agentPopupDetailsObj['agentSalesOptions'] = success['chart_options'];
                            this.agentPopupDetailsObj['agentSalesData'] = success['chart_data'];
                            $('#idleTimeoutPrompt').modal('show')
                            $('#idleTimeoutPrompt').resize();
                            this._commonService.hideLoader();
                            $("#popupTabs li:first-child").addClass("active");
                        });
                    });
                });
            });
        }
    };

    getBusByCarrierChartProp(businessByCarriersApi) {
        return {
            'title': {},
            'chart_type': 'pieChart',
            'stacked': false,
            'api_name': businessByCarriersApi,
            'index': 7,
            'chart_id': 'businessByCarrierChart',
            'ticks': 10,
            'height': 300,
            'donut': false,
            'margin': {
                'top': 20,
                'right': 20,
                'bottom': 55,
                'left': 60
            },
            'tabId': 'tab1'
        };
    }

    getBusByProductLineChartProp(businessByProductLineApi) {
        return {
            'title': {},
            'chart_type': 'pieChart',
            'stacked': false,
            'api_name': businessByProductLineApi,
            'index': 7,
            'chart_id': 'businessByProductLineChart',
            'ticks': 10,
            'height': 300,
            'donut': false,
            'margin': {
                'top': 20,
                'right': 20,
                'bottom': 55,
                'left': 60
            },
            'tabId': 'tab1'
        };
    }

    getAgentSaleChartProp(agentSalesApi) {
        return {
            'title': {},
            'chart_type': 'linePlusBarChart',
            'stacked': false,
            'api_name': agentSalesApi,
            'index': 7,
            'chart_id': 'getAgentSales',
            'ticks': 10,
            'height': 300,
            'donut': false,
            'margin': {
                'top': 20,
                'right': 20,
                'bottom': 55,
                'left': 60
            },
            'tabId': 'tab1'
        };
    }

    getYoYChartProp(yoyPerformanceApi) {
        return {
            'title': {},
            'chart_type': 'lineChart',
            'stacked': false,
            'api_name': yoyPerformanceApi,
            'index': 7,
            'chart_id': 'yoyPerformanceLinechart',
            'ticks': 10,
            'height': 350,
            'margin': {
                'top': 20,
                'right': 20,
                'bottom': 20,
                'left': 60
            },
            'tabId': 3
        };
    }

    carrierOrAgencyOnItemSelect(item: any) {
        this.performancePopupFiltersChanged();
    }

    carrierOrAgencyOnSelectAll(items: any) {
        this.carrierOrAgencySelectedItems = items;
        this.performancePopupFiltersChanged();
    }

    carrierOrAgencyOnDeSelect(item: any) {
        this.performancePopupFiltersChanged();
    }

    carrierOrAgencyOnDeSelectAll(items: any) {
        this.carrierOrAgencySelectedItems = items;
        this.performancePopupFiltersChanged();
    }

    policyOnItemSelect(item: any) {
        this.performancePopupFiltersChanged();
    }

    policyOnSelectAll(items: any) {
        this.policySelectedItems = items;
        this.performancePopupFiltersChanged();
    }

    policyOnDeSelect(item: any) {
        this.performancePopupFiltersChanged();
    }

    policyOnDeSelectAll(items: any) {
        this.policySelectedItems = [];
        this.performancePopupFiltersChanged();
    }

    popupLobChanged = (val, obj) =>{
        this.modalLOB = val;
        this.getProducts(val, true);
    }

    popupProductChanged = (val, obj) =>{
        this.performancePopupFiltersChanged();
    }

    getProducts = (lobParam, performancePopupFlag) => {
        if(lobParam === undefined){
            lobParam = 'All';
        }
        let reqParams = {
            lob: lobParam
        }
        var callGetProductList = this._commonService.getProducts(reqParams);
        forkJoin([callGetProductList]).subscribe(res =>{
            this.popupProducts = [];
            var productList = [];
            if(res.length){
                res[0].forEach(function(p) {
                    productList.push({ category: p['category'] });
                });
                this.popupProducts = productList;
                this.modalProduct = 'All';
                if(performancePopupFlag){
                    this.performancePopupFiltersChanged();
                }
            }
        })
    }

    performanceAgentDetailsModal = (detailsObj) => {
        this.showModalPopup = true;
        this.isYOYFlag = true;
        $('#performanceChartToggle li:first-child').addClass('active');
        this._commonService.parametersObj = cloneDeep(this._commonService.getParameters());
        var params = cloneDeep(this._commonService.getParameters());
        this.baseFilterLob = params['lob'];
        if(this.activeTab == 6){
            params['param5'] = detailsObj.awnHiddenId ? detailsObj.awnHiddenId : detailsObj.id;
            params['missingAgentTab'] = true;
        } else {
            params['param5'] = detailsObj.awnHiddenId ? detailsObj.awnHiddenId : detailsObj.id;
            params['missingAgentTab'] = false;
        }
        this.agentMarketingCompany = detailsObj.agentmarketingcompany
        params['agentMarketingCompany'] = detailsObj.agentmarketingcompany;
        params['idType'] = detailsObj.idType;
        params['level'] = detailsObj.level;
        var now = new Date();
        var year = now.getFullYear();
        var month = now.getMonth();
        var day = now.getDate();
        let allPolicyStatus:any = this._commonService.getAllPolicyStatus();
        this.policyDropdownList = allPolicyStatus;
        this.policySelectedItems = params['policyStatus'];
        this.policyDropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 0,
            allowSearchFilter: false
        }
        this._commonService.showLoader();
        if(this.lineOfBusinessFilter === 'yes'){
            var lobparams = {
                lob: 'All'
            }
            let selectedLob = this._commonService.getLobVal();
            this._commonService.getLineOfBusiness(lobparams).subscribe(res => {
                if(res.length){
                    let lineOfBusiness = [];
                    res.forEach(function (ele, index) {
                        lineOfBusiness.push({ category: ele['lob'] });
                    });
                    this.popupLineOfBusiness = lineOfBusiness;
                    this.modalLOB = selectedLob ? selectedLob : 'All';
                    params['lob'] = this.modalLOB;
                    this.getProducts(selectedLob, false);
                }
            });
        } else {
            this.getProducts('All', false);
            params['param2'] = 'All';
        }
        this.agentPopupDetailsObj = {
            popupHeading: detailsObj.popupHeading,
            id: detailsObj.id,
            agentORAgentName: detailsObj.agentORAgentName,
            crmId: detailsObj.crmid,
            crm: 'AgentVizion Id',
            awnHiddenId: detailsObj.awnHiddenId,
            end_date: now,
            idType: detailsObj.idType,
            start_date: new Date(year - 1, month + 1, day),
            level: detailsObj.level,
            clientType: this.clientType,
            agentMoreDetails: this.agentMoreDetails,
            agentMarketingCompany: detailsObj.agentmarketingcompany,
            fromDate:this.convertToDate(params['param3']),
            toDate: this.convertToDate(params['param4'])
        };
        var checkSapOrTap = 'sap';
		if(( this.activeTabId == 2 && params['sapOrTapToggle']['agentsTabTapVlue'] === 'tap') || (this.activeTabId == 3 && params['sapOrTapToggle']['analyticsTabTapValue'] === 'tap')){
			checkSapOrTap = 'tap';
		}

        var agentPersonalDetailsApi = 'getAgentOrAgencyPersonalDetails';
        var businessByProductLineApi = 'getPopupBusinessByProductLine';
        var businessByCarriersApi = 'getPopupBusinessByCarriers';
        var agentSalesApi = 'getPopupAgentSales';
        var yoyPerformanceApi = 'getYearOnYearComparision';
        if(this.isApplicationsApplicable === 'yes') {
            this._commonService.setParameters('param11', 'application');
            agentSalesApi = ApiConstant.getPopupAgentApplicationSales;
        }
        if (this.activeTabId === 3 && params['sapOrTapToggle']['analyticsTabTapValue'] === 'tap') {
            businessByProductLineApi = ApiConstant.getPopupBusinessByProductLineTap;
            businessByCarriersApi = ApiConstant.getPopupBusinessByCarriersTap;
            agentSalesApi = ApiConstant.getPopupAgentSalesTap;
            yoyPerformanceApi = ApiConstant.getYearOnYearComparisionTap;
        }

        if(agentPersonalDetailsApi === 'getAgentPersonalDetailsTap'){
            var agentPersonalDetailsTapParams = {
                tabId: this.activeTabId,
                attribute1: params['attribute1'],
                param2: params['param2'],
                agentId: params['param5'],
                agentMarketingCompany: detailsObj.agentmarketingcompany
            }
            this._commonService.agentPersonalDetailsApi(agentPersonalDetailsTapParams, agentPersonalDetailsApi).subscribe(response => {
                this.agentDetailsObject = response;
                if(this.agentDetailsObject){
                    let that = this;
                    setCarrierOrAgency(that);
                    setCommonCallPieCharts(that);
                }
            });
        } else {
            if(params['idType'] === 'crm' && params['level'] === 'Agent'){
                params['tabId']= this.activeTabId
                params['attribute1']=params['attribute1']
                params['param2']= params['param2']
                params['agentId']= detailsObj['crmId']
                params['param5']= detailsObj['crmId']
            } else {
                params['tabId']= this.activeTabId
                params['attribute1']=params['attribute1']
                params['param2']= params['param2']
                params['agentId']= params['param5']
            }
            // agentDetails = [{"CRM Id":3277651284,"name":"** **THE BROKERAGE, INC.","number":"NA","address":"commissons@thebrokerageinc.com","AgencyName":"TBI (w/o BBA)","Carriers":"BCBS","CarrierName":"BCBS"}];
            this._commonService.agentPersonalDetailsApi(params, agentPersonalDetailsApi).subscribe(async response => {
                this.agentDetailsObject = response[0];
                if(this.agentDetailsObject){
                    let that = this;
                    setCarrierOrAgency(that);
                    await setCommonCallPieCharts(that);
                    that._commonService.hideLoader();
                } else {
                    this._commonService.hideLoader();
                }
            });
        }

        this.yoytooltipSubscription = this.chartService.showYoyTooltip.subscribe(res=>{
            $('#showTooltip').show();
            this.agentPopupDetailsObj.performanceToggleType = $('#performanceChartToggle .active').attr('type') === '$' ? '$' : '#';
            this.agentPopupDetailsObj['selectedMonth'] = res[1];
            this.agentPopupDetailsObj['tooltipData'] = res[0];
        });

        function setCarrierOrAgency(that){
            let allcarrierOrAgency: any = [];
            let splittedCarrierOrAgencyList:any;
            if(that.clientType === 'agency'){
                allcarrierOrAgency = [that.getAgentNameValue(that.agentDetailsObject, 'Carriers')]
            } else {
                allcarrierOrAgency = [that.getAgentNameValue(that.agentDetailsObject,'AgencyName')]
            }
            if(allcarrierOrAgency && allcarrierOrAgency.length){
                splittedCarrierOrAgencyList = allcarrierOrAgency[0].split(',');
                splittedCarrierOrAgencyList.forEach(function (ele, index) {
                    let trimEle = ele.trim();
                    return trimEle;
                });
                that.carrierOrAgencyDropdownList = splittedCarrierOrAgencyList;
                that.carrierOrAgencySelectedItems = splittedCarrierOrAgencyList;
            }

            that.carrierOrAgencyDropdownSettings = {
                singleSelection: false,
                idField: 'item_id',
                textField: 'item_text',
                selectAllText: 'Select All',
                unSelectAllText: 'UnSelect All',
                itemsShowLimit: 0,
                allowSearchFilter: false
            }
        }

        async function setCommonCallPieCharts(that) {
            //Pie chart for business by carrier
            let allcarrierOrAgency: any;
            let tempArr: any;

            if(that.clientType === 'agency'){
                params['param1'] = [];
                allcarrierOrAgency = [that.getAgentNameValue(that.agentDetailsObject, 'Carriers')];
                tempArr = allcarrierOrAgency[0].split(',');
                tempArr.forEach(function (ele, index) {
                    let eleVal = ele.trim()
                    params['param1'].push(eleVal);
                });
            } else {
                params['param1'] = [];
                allcarrierOrAgency = [that.getAgentNameValue(that.agentDetailsObject,'AgencyName')];
                tempArr = allcarrierOrAgency[0].split(',');
                tempArr.forEach(function (ele, index) {
                    let eleVal = ele.trim()
                    params['param1'].push(eleVal);
                });
            }
            popupParameter = cloneDeep(params);
            await new Promise<void>((resolve, reject)=>{
                try{
                    var requiredChartByCarrier = that.getBusByCarrierChartProp(businessByCarriersApi);
                    var chartCarrier = that.chartService.getChart(requiredChartByCarrier, params);
                    chartCarrier.subscribe( (success) =>{
                    that.agentPopupDetailsObj['agentCarrierGraphOptions'] = success['chart_options'];
                    that.agentPopupDetailsObj['agentCarrierGraphdata'] = success['chart_data'];

                        //Pie chart for Business by productline
                        var requiredLineChart = that.getBusByProductLineChartProp(businessByProductLineApi)
                        var chart = that.chartService.getChart(requiredLineChart, params);
                        chart.subscribe( (success)=> {

                            that.agentPopupDetailsObj['agentGraphOptions'] = success['chart_options'];
                            that.agentPopupDetailsObj['agentGraphdata'] = success['chart_data'];

                            var yoyCompRequiredLineChart = that.getYoYChartProp(yoyPerformanceApi);
                            var YoYCompChart = that.chartService.getChart(yoyCompRequiredLineChart, params);
                            YoYCompChart.subscribe( (data) => {
                                that.agentPopupDetailsObj['yoyCompLineOptions'] = data['chart_options'];
                                that.agentPopupDetailsObj['yoyCompLineData'] = data['chart_data'];
                                $('#idleTimeoutPrompt').modal('show')
                                $('#idleTimeoutPrompt').resize();
                                //Line plus bar chart for agent sale
                                var requiredChart = that.getAgentSaleChartProp(agentSalesApi);
                                var agentSalesChart = that.chartService.getChart(requiredChart, params);
                                agentSalesChart.subscribe( (success)=> {
                                    that.agentPopupDetailsObj['agentSalesOptions'] = success['chart_options'];
                                    that.agentPopupDetailsObj['agentSalesData'] = success['chart_data'];
                                    $('#idleTimeoutPrompt').modal('show')
                                    $('#idleTimeoutPrompt').resize();
                                    $("#popupTabs li:first-child").addClass("active");
                                    that._commonService.hideLoader();
                                    return resolve();
                                });
                            });
                        });
                    });
                } catch (err) {
                    that._commonService.hideLoader();
                    return resolve();
                }
            });
        }
    }

    cancelTooltip(){
        $('#showTooltip').hide();
    }

    getMonthAndYear = (month, year) =>{
        var monthUppercase = '';
        if (month && month.length) {
            monthUppercase = month.toUpperCase();
        } else {
            monthUppercase = month;
        }
        return monthUppercase + '-' + year.toString().substr(-2);
    }

    getMyColor = (pvalue)=> {
        var value = '';
        if (pvalue && pvalue < 0) {
            value = pvalue.toString().substr(1);
        } else {
            value = pvalue;
        }
        return value;
    }

    openModalpopup(details, params={...this._commonService.getParameters()}) {
        this._commonService.parametersObj = params;
        details.activeSubTabId = this.activeSubTabId;
        details.selectedColumnName = this.selectedColumnName;
        details.AWNHiddenId = this.localStorageService.get('AWNHiddenId');
        details.ProductLine = this.localStorageService.get('ProductLine');
        const modalRef = this.modalService.open(TableModalComponent, {size: 'xl', backdrop: 'static', ariaLabelledBy: 'modal-basic-title'});
        // modalRef.componentInstance.my_modal_title = details.title;
        // modalRef.componentInstance.product_count_arr = details.productCountInfoArray;
        modalRef.componentInstance.my_table_modal_object = details;
        modalRef.componentInstance.baseTablePrameterObj =  params;
        // console.log("parameters", this._commonService.getParameters())
        // modalRef.componentInstance.my_modal_popupObj = popupObj;
        // modalRef.componentInstance.my_modal_content =agentDetails;
    }

    openincentivPointsModalpopup = (details) =>{
        const modalRef = this.modalService.open(IncentivePointsModalComponent, {size: 'xl', backdrop: 'static', ariaLabelledBy: 'modal-basic-title'});
        modalRef.componentInstance.incentive_modal_object = details;
    }
    openWeeklyFastStartModalpopup = (details) =>{
      const modalRef = this.modalService.open(WeeklyFastStartBonusModalComponent, {size: 'xl', backdrop: 'static', ariaLabelledBy: 'modal-basic-title'});
      modalRef.componentInstance.weekly_fast_start_bonus_modal_object = details;
    }

    openAgentDetailsModalPopup(title, popupObj, agentDetails) {
         let chartInfo = [{
            "title": {
                "$": "Premium YOY by Product Line",
                "#": "Policy YOY By Product Line"
            },
            "chart_type": "YOYBarChart",
            "api_name": "getAgencyYearOnYearComparision",
            "index": 1,
            "showArea": false,
            "chart_id": "chart1",
            "xaxis_axislabel": "Year",
            "height": 300,
            "orient": "bottom",
            "rotateLabels": -45,
            "ticks": 12,
            "showMaxMin": false,
            "tickValues": "response[0].categories",
            "yaxis_axislabel": "Loss Ratio %",
            "axisLabelDistance": "",
            "margin": {
                "top": 20,
                "right": 20,
                "bottom": 55,
                "left": 60
            },
            "tabId": "tab2",
            "role": ["Agent", "Manager", "level_1", "level_2", "level_3", "level_4", "level_5", "level_6", "level_7"]
        }];
        popupObj['chartInfo'] = chartInfo
        const modalRef = this.modalService.open(ModalComponent, {size: 'xl', backdrop: 'static', ariaLabelledBy: 'modal-basic-title'});
        modalRef.componentInstance.my_modal_title = title;
        modalRef.componentInstance.my_modal_popupObj = popupObj;
        modalRef.componentInstance.my_modal_content =agentDetails;
        modalRef.componentInstance.baseTablePrameterObj =  cloneDeep(this._commonService.getParameters());
    }

    showYearlyPoliciesModal = (id, month, level, api, header, columnDefs, width, awnHiddenId, activeAgentTab, agentMarketingCompany) => {
        var details = {
            title: header,
            apiName: api,
            agentid: id,
            // idType: idType,
            awnHiddenId: awnHiddenId,
            agentmarketingcompany: agentMarketingCompany,
            month:month,
            level:level,
            activeAgentTab: activeAgentTab
        };
        var tableConfig = {
            'title': '',
            'api_name': '/' + api,
            'id': 'table2',
            'tabId': '',
            'thresholds': [],
            'responsive': false,
            'fileNameToExport': details.title,
            'columnFilters': [],
            'clickEventOn': [],
            'width': width,
            'columnDefs': [],
            'serverSideProcessing': true
        };
        // this.modalMonth = month;
        // this.modalLevel = level;
        // var param = this.getParameters();
        // this.param5 = awnHiddenId;
        // if(this.activeAgentTab === 2){
        //     // param.contractDateInterval =  this.contractDateInterval;
        //     this._commonService.setParameters('contractDateInterval', this.contractDateInterval);
        // }

        // else if(this.activeAgentTab === 'tab1' || this.activeAgentTab === 'tab3'){
        //     param.contractDateInterval = '';
        // }
        // param.param5 = awnHiddenId;
        this._commonService.setParameters('param5', awnHiddenId);
        this._commonService.setParameters('column', '');
        // param.breadcrumbFilters = this.breadcrumbFilters;
        this._commonService.setParameters('breadcrumbFilters', this.breadcrumbFilters);
        let params = this._commonService.getParameters();
        params['agentMarketingCompany'] = agentMarketingCompany;
        this.initializeModel(details, api, tableConfig, params);
    };

    showLifeAnnuityYearlyPoliciesModal = (id, month, plan, api, header, columnDefs, width, awnHiddenId) =>{
        var details = {
            title: header,
            apiName: api,
            plan:plan,
            month:month,
        };

        var tableConfig = {
            'title': '',
            'api_name': '/' + api,
            'id': 'table2',
            'tabId': '',
            'thresholds': [],
            'responsive': false,
            'fileNameToExport': details.title,
            'columnFilters': [],
            'clickEventOn': [],
            'width': width,
            'columnDefs': [],
            'serverSideProcessing': true
        };
        let params = this._commonService.getParameters();
        params['month'] = month;
        //$scope.modalMonth = month;
        //$scope.modalsubProductPlan = plan;
        params['subProductPlan'] = plan;
        this.initializeModel(details, api, tableConfig, params);
    };

    showOpenWeeklyFastStartModal = function (id, api, header, weekNo) {
      var details = {
         title: header,
         apiName: api,
         agentid: id,
         weekNo,
         param3: this.program_start_date,
         param4: this.program_end_date,
         incentive_modal_object: this.incentive_modal_object
     };

     details.apiName = api;
     this.openWeeklyFastStartModalpopup(details)
    }

    showAgentsForTrainerFastStartModal = function (id, api, header, columnDefs, idType, awnHiddenId, agentmarketingcompany) {
      this.showModal(id, api, header, columnDefs, '', idType, awnHiddenId, agentmarketingcompany);
    }
    showReferredAgentsModal = function (id, api, header, columnDefs, idType, awnHiddenId, agentmarketingcompany) {
      this.showModal(id, api, header, columnDefs, '', idType, awnHiddenId, agentmarketingcompany);
    }

    showPointsChartModal = function (id, api, header, columnDefs, width, idType, currentProgram='', agentLevel) {
         var details = {
            title: header,
            apiName: api,
            agentid: id,
            idType: idType,
            currentProgram,
            agentLevel
            //,
            // awnHiddenId: awnHiddenId,
            // agentmarketingcompany: agentmarketingcompany
        };

        details.apiName = api;
        this.openincentivPointsModalpopup(details)

        // $uibModal.open({
        //     animation: true,
        //     templateUrl: 'dashboard/views/map_popup.html',
        //     controller: modalctrl,
        //     size: 'md',
        //     backdrop: 'static',
        //     resolve: {
        //         details: function () {
        //             return details;
        //         }
        //     }
        // }).rendered.then(function () {
        //     this.options = {
        //         chart: {
        //             type: 'bulletChart',
        //             transitionDuration: 500
        //         }
        //     };
        //     var params = {};
        //     params.param5 = id;
        //     params.program = $('#incentivePrograms .active').attr('program');
        //     $http.get('/getAgentIncentivePoints', {
        //         params: params
        //     }).success(function (data) {
        //         var ranges = data[0].ranges; //data[0].ranges.sort((a, b) => a - b);
        //         var max = ranges[ranges.length - 1];
        //         this.data = data[0];
        //         var width;
        //         var currentPoints = data[0].measures[0] * 100 / max;
        //         var str = '<div class="bulletChartContainer"><div class="bulletChartLabel"><span class="agentPoints">' + data[0].measures[0] + ' Points</span></div><div class="barsContainer"><div class="currentPoints" style="width:' + currentPoints + '%"></div>';
        //         ranges.forEach(function (range, index) {
        //             width = range * 100 / max;
        //             str = str + '<div class="chartLevel' + index + '" style="width:' + width + '%"><div class="bulletChartxaxis">' + range + '</div></div>'
        //         });
        //         str = str + '</div><div class="bulletChartLegends"><div class="chartLegend"><span class="lagendColor0"></span><span class="lagendText">6 Day, 5 Night (All Inclusive) trip for two to Riviera Maya</span></div><div class="chartLegend"><span class="lagendColor1"></span><span class="lagendText">6 Day, 5 Night (All Inclusive) trip for two to Riviera Maya & $250 cash bonus</span></div><div class="chartLegend"><span class="lagendColor2"></span><span class="lagendText">6 Day, 5 Night (All Inclusive) trip for two to Riviera Maya & $500 cash bonus</span></div></div></div>'
        //         var temp = $compile(str)(this);
        //         angular.element(document.getElementById('popupContainer')).html(temp);
        //         $('.modal-dialog').addClass('modal-xl');
        //         $('#spinner').hide();
        //     });
        // });
    };

    showModal = function (id, api, header, columnDefs, width, idType, awnHiddenId, agentmarketingcompany, args = {}) {
        var details = {
            title: header,
            apiName: api,
            agentid: id,
            idType: idType,
            awnHiddenId: awnHiddenId,
            agentmarketingcompany: agentmarketingcompany,
            policiesStartDate: this.policiesStartDate,
            policiesEndDate: this.policiesEndDate,
            args,
        };

        var tableConfig = {
            'title': '',
            'api_name': api,
            'id': 'table2',
            'tabId': '',
            'thresholds': [],
            'responsive': false,
            'fileNameToExport': details.title,
            'columnFilters': [],
            'clickEventOn': [],
            'width': width,
            'columnDefs': columnDefs,
            'serverSideProcessing': true
        };
        var idType: any = idType;
        var param5:any;
        //param.agentMarketingCompany = agentmarketingcompany;
        this._commonService.setParameters('agentMarketingCompany', agentmarketingcompany);
        if (api === 'getIncentivePolicies' || api === ApiConstant.getFastStartBonusForTrainer || api === ApiConstant.getReferralBonusIncentiveReport) {
            this.incentiveAgentId = '';
            if(api === 'getIncentivePolicies') {
              api = 'getPolicies';
            }
            //get the program start and end date and pass as a parameter to the api
            var fromStartdate = $('#incentivePrograms .active .startDate').text();
            var startdate = fromStartdate.split(':');
            var startdateValues = startdate[1]?.split('/');
            var toEndDate = $('#incentivePrograms .active .endDate').text();
            var endDate = toEndDate.split(':');
            //param.param1 = this.allCarriersArr;
            this._commonService.setParameters('param1', this.allCarriersArr);
            //param.param3 = startdateValues[2] + '-' + startdateValues[0] + '-' + startdateValues[1];
            if(startdate[1]){
                this._commonService.setSelector('param3', startdateValues[2].trim() + '-' + startdateValues[0].trim() + '-' + startdateValues[1].trim());
                this._commonService.incentive_from_date = startdateValues[2].trim() + '-' + startdateValues[0].trim() + '-' + startdateValues[1].trim();
            }
            var enddatedateValues = endDate[1]?.split('/');
            //param.param4 = enddatedateValues[2] + '-' + enddatedateValues[0] + '-' + enddatedateValues[1];
            if(endDate[1]){
                this._commonService.setSelector('param4', enddatedateValues[2].trim() + '-' + enddatedateValues[0].trim() + '-' + enddatedateValues[1].trim());
                this._commonService.incentive_to_date = enddatedateValues[2].trim() + '-' + enddatedateValues[0].trim() + '-' + enddatedateValues[1].trim();
            }
            // this._commonService.setSelector('policyStatus', 'Active');
            param5 = id;
            this.incentiveAgentId = id;
            this._commonService.setSelector('param2','param2');
            this._commonService.setSelector('filterDateType','Filter Date');
        } else if (($('#pageSubTabs li.active').attr('id') === 'subtab1' || $('#pageSubTabs li.active').attr('id') === 'subtab2' ) && api === ApiConstant.getCommissionStatements) {
            param5 = awnHiddenId;
        } else {
            param5 = awnHiddenId;
        }
        if(this.activeSubTabId == 27) {
        //   this._commonService.setSelector('policyStatus', 'Active');
        //   this._commonService.setSelector('filterDateType', 'Entry Date');
            if(api === 'getPolicies'){
                details['level'] = this.agentLevel;
            }
        } else {
            this._commonService.setParameters('program', undefined);
        }
        if ($('#pageSubTabs .active').attr('id') === 'subtab3') {
            this._commonService.setParameters('param9', $('.pending-count input').val());
        }

        this._commonService.setParameters('param5', param5);
        let params = {...this._commonService.getParameters()};
        // console.log(details);
        this.initializeModel(details, api, tableConfig, params);
    };

    getHomeBreadCrumbData = ()=>{
        this.breadcrumbFilters = [];
        this.searchObj = [];
        this._commonService.setParameters('breadcrumbFilters', this.breadcrumbFilters);
        this._commonService.setParameters('level', '');
        this._commonService.setParameters('upline_premium', null);
        this._commonService.setParameters('upline_reward', null);
        this._commonService.showLoader();
        $('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
        this._commonService.resetparameters();
        this.getParameters = this._commonService.getParameters();
        let apiname = this.getParameters['url'];
        if(this.activeTabId === 2){
            // if(this.getParameters['sapOrTapToggle'].agentsTabTapValue === 'tap'){
            //     apiname = ApiConstant.getAgentSummaryTap;
            // } else {
            //     apiname = ApiConstant.getAgentSummary;
            // }
            this._commonService.updateAgentsData.next();
        } else {
            if(this.activeTabId === 5){
                if(this.activeSubTabId === 33){
                    if($('#toggleReport button.toggleActive').attr('type') === '$'){
                        apiname = ApiConstant.getAgentTapDownlineReportSales;
                    } else {
                        apiname = ApiConstant.getAgentTapDownlineReport;
                    }
                } else if (this.activeSubTabId === 27) {
                  const currentProgram = this.incentive_modal_object.name?this.incentive_modal_object.name:(<HTMLElement>document.querySelector('#incentivePrograms .active #incentiveProgramname')).innerText;
                  if(currentProgram == "Manager’s Quarterly Bonus") {
                    apiname = ApiConstant.getManagerQuarterlyIncentiveReport;
                  }else if(currentProgram == "Production Allowance") {
                    apiname = ApiConstant.getProductionAllowanceIncentiveReport;
                  } else if (['Fast Start Bonus ARIS','Fast Start Bonus Americare', 'Fast Start Bonus'].includes(currentProgram)){
                    apiname = ApiConstant.getFastStartBonusIncentiveReport;
                  } else if (['Fast Start - Trainer'].includes(currentProgram)){
                    apiname = ApiConstant.getFastStartBonusForTrainer;
                  }else if (['Referral Bonus ARIS'].includes(currentProgram)){
                    apiname = ApiConstant.getReferralBonusIncentiveReport;
                  }else if (['Convention Standings ARIS'].includes(currentProgram)){
                    apiname = ApiConstant.getConventionIncentiveReportAris;
                  }else if (['Convention Standings Americare'].includes(currentProgram)){
                    apiname = ApiConstant.getConventionIncentiveReportAmericare;
                  }else if (['RSM Bonus'].includes(currentProgram)){
                    apiname = ApiConstant.getRsmBonusIncentiveReport;
                  }else if (['Sales incentives ARIS', 'Sales incentives Americare'].includes(currentProgram)){
                    apiname = ApiConstant.getSipIncentiveReport;
                  }else if (['Manager SIP ARIS'].includes(currentProgram)){
                    apiname = ApiConstant.getManagerSipIncentiveReport;
                  }else if (['Quarterly Product Mix Bonus'].includes(currentProgram)){
                    apiname = ApiConstant.getQuarterlyProductMixBonus;
                  }else if (['ASM New Agent Year-End Bonus'].includes(currentProgram)){
                    apiname = ApiConstant.getASMAgentYearEndBonusIncentiveReport;
                  }else if (['Manager’s Weekly Production'].includes(currentProgram)){
                    apiname = ApiConstant.getManagerWeeklyProductionIncentiveReport;
                  }else if (['New Manager Quarterly Bonus'].includes(currentProgram)){
                    apiname = ApiConstant.getNewManagerQuarterlyIncentiveReport;
                  }

                }
                else {
                    if($('#toggleReport button.toggleActive').attr('type') === '$'){
                        apiname = ApiConstant.getAgencyYearlyReportSales;
                    } else {
                      apiname = ApiConstant.getAgencyYearlyReport;
                    }
                }
            }
            if(this.activeTabId === 3){
                apiname = ApiConstant.getAgencyAnalyticsReport;
            }
            this.tableService.setTableConfigObj({apiName:apiname,
                id:'table2',
                fileNameToExport:'',
                columnDefs:[]
            });
            let tableConfig = this.tableService.getTableConfigObj();
            this.initializeTable( this.getParameters , tableConfig);
        }
    }

    onMouseover = (e) =>{
        setTimeout(()=>{
            $('[data-toggle="tooltip"]').tooltip()
        }, 10);
    }

    initializeModel = function (details, api, tableConfig, param) {
        details.apiName = api;
        this._commonService.showLoader();
        this.openModalpopup(details,param)

        // $uibModal.open({
        //     animation: true,
        //     templateUrl: 'dashboard/views/map_popup.html',
        //     controller: modalctrl,
        //     size: 'md',
        //     backdrop: 'static',
        //     resolve: {
        //         details: function () {
        //             return details;
        //         }
        //     }
        // }).rendered.then(function () {
        //     $('.modal-dialog').addClass('modal-xl');
        // });
    };

    getSubTabContent(obj, tableConfigObj) {
        this.crmId = configJson.crmId;
        this.activeAgentTab = tableConfigObj.activeAgentTab;
        this.callGetSubTabContent(obj.id, obj.api, tableConfigObj);
    }

    callGetSubTabContent(id, api, tableConfigObj) {
        this.tableColumnConfig(configJson);
        this.searchObj = [];
        this.showReportToggle = false;
        var str1 = '',
            str2 = '',
            str3 = '',
            str4 = '',
            value, param, fileNameToExport;
        $('#pageSubTabs li.active').removeClass('active');
        $('#pageAlertSubTabs li.active').removeClass('active');
        $(' #pageAlertSubTabs #' + id).closest('li').addClass('active');
        var tableId = 'table2';
        $('#fromDate_container').removeClass('none-pointer-events');
        $('#toDate_container').removeClass('none-pointer-events');
        $('#filterByDateFilter_container').removeClass('none-pointer-events');
        this.activeTabId = this.localStorageService.get('activeTabId')
        //define table filters
        if ( this.activeTabId == 2) {
            var container = $('#tableContainer').append('<div class=\'row\'></div>');
            $(container).append('<div class=\'row\' id=\'' + tableId + '_columnFilters\'></div><div id=\'' + tableId + '_container\'>');
        } else if ( this.activeTabId == 3) {
            // this.is90DayToggle = false;
            // if(this.isTAPRequired === 'yes'){
            //     $('#alertsTabTapBtn span.switch').css('margin-top', '54px');
            //     $('#alertsTabTapBtn').css('margin-right', '145px');
            // }
            // $('#toggle_container').hide();
            // $('#toggle_container').addClass('none-pointer-events');
            // this.param10 = '';
            // this.param9 = '';
            $('togglebutton').remove();
            if (id === 'subtab6') {
                $('#alertsTabTapBtn').hide();
            } else {
                $('#alertsTabTapBtn').show();
            }
            $('#breadcrumb').hide();
            // var container = $('#tableContainer').append('<div class=\'row\'></div>');
            // $(container).append('<div class=\'row\' id=\'' + tableId + '_columnFilters\'></div><div id=\'' + tableId + '_container\'>');
            switch (id) {
                case 4: $('#breadcrumb').show();
                        break;
                case 5:
                    api = ApiConstant.getHighPerformers;
                    str3 = 'Policy Count >=';
                    str4 = '';
                    param = 'param8';
                    // value = this.param8;
                    fileNameToExport = 'High Performers';
                    break;
                case 6:
                    api = ApiConstant.getLowPerformers;
                    str3 = 'Policy Count <=';
                    str4 = '';
                    param = 'param8';
                    // value = this.param8;
                    fileNameToExport = 'Low Performers';
                    break;
                case 7:
                    // $('#policyStatusFilter_container').css('visibility', 'hidden');
                    api = ApiConstant.getPendingByAgent;
                    str1 = 'Pending Policies >=';
                    str2 = 'Days';
                    param = 'param9';
                    // value = this.param9;
                    fileNameToExport = 'Pending Policies';
                    break;
                case 8:
                    // $('#policyStatusFilter_container').css('visibility', 'hidden');
                    api =  ApiConstant.getLapsedPolicies;
                    str3 = 'Lapsed Policies Count >=';
                    str4 = '';
                    param = 'param8';
                    // value = this.param8;
                    fileNameToExport = 'Lapsed Policies';
                    break;
            }
            // if(this.searchAPI !== api){
            //     this.searchObj = [];
            // }
            // if(this.userRole === 'Agent' && this.companyFilter === 'yes'){
            //     if(api === '/getLapsedPolicies' || api === '/getPendingByAgent')
            //     columnDefs = [
            //         { "className": "", "targets": 1, "sClass": "" }, { "visible": false, "targets": 2 }, { "visible": false, "targets": 4 }
            //     ]
            // } else {
            //     columnDefs = [
            //         { "className": "", "targets": 1, "sClass": "" }, { "visible": false, "targets": 2 }
            //     ]
            // }
        }
        //var tableConfig = this.getTableConfig(api, this.tableId, fileNameToExport, columnDefs, 'disabled', 'enabled');
        if (str3.length && str1.length) {
            $('#' + tableConfigObj.id + '_container').html('').append('<div id=\'tableFilter\' class=\'row\'><div class=\'pending-count\'>' +
                str1 + '<input id=\'\' param=\'param8\' class=\'form-control\' type=\'number\' value=\'\'>' +
                str2 + '</div><div class=\'pending-count\'>' + str3 + ' <input id=\'\'  param=\'param10\' class=\'form-control\' type=\'number\' value=\'>' + str4 + '</div></div>');
        } else if (str1.length) {
            $('#' + tableConfigObj.id + '_container').html('').append('<div id=\'tableFilter\' class=\'row\'><div class=\'pending-count\'>' +
                str1 + '<input id=\'\' param=\'' + param + '\' class=\'form-control\' type=\'number\' value=\'' +
                value + '\'>' + str2 + '</div></div>');
        } else {
            $('#' + tableConfigObj.id + '_container').html('').append('<div id=\'tableFilter\' class=\'row\'><div class=\'pending-count\'>' +
                str3 + '<input id=\'\' param=\'param10\' class=\'form-control\' type=\'number\' value=\'' +
                value + '\'>' + str4 + '</div></div>');
        }
        $('#' + tableId + '_container').append('<table id=\'' + tableId + '\' class=\'display\' width=\'100%\'></table>');
        if ($('#pageSubTabs li.active').attr('id') === 'subtab2') {
            // if ( this.activeTabId === 'tab2' && this.isTAPRequired === 'yes') {
            //     $('#tableFilter').remove();
            //     $('#agentsTabTapBtn').hide();
            // }
            tableConfigObj.clickEventOn = [{ "column": "Commission", "functionToCall": "openCommissionDetailsModal" }];
        }
        if ( this.activeTabId == 2) {
            $('#tableFilter').remove();
        }
        this._commonService.setParameters('url',tableConfigObj.api_name)
        this.contractDateInterval = tableConfigObj.contractDateInterval ? tableConfigObj.contractDateInterval : '';
        tableConfigObj.serverSideProcessing = true;
        var parameters = this._commonService.getParameters();
        parameters['contractDateInterval'] = this.contractDateInterval;
        parameters['crmId'] = this.crmId;
        parameters['month'] = tableConfigObj.month;
        if(api === ApiConstant.getIncentiveProgramAgents){
            parameters['program'] = $('#incentivePrograms .active #incentiveProgramname').text();
        }
        this.initializeTable(parameters , tableConfigObj);
        //Table filter value change event - update table based on the filter value
        // $('#tableFilter input').off().on('change', function () {
        //     $('#spinner').show();
        //     $('#table2_wrapper').remove();
        //     $('#' + tableId + '_container').append('<table id=\'' + tableId + '\' class=\'display\' width=\'100%\'></table>');
        //     if ($('#pageSubTabs .active').attr('id') === 'subtab3' && this.value === '') {
        //         this.value = 0;
        //     }
        //     params[$(this).attr('param')] = this.value;
        //     this[$(this).attr('param')] = this.value;
        //     this.initializeTable(tableId, api, params, tableConfig);
        // });
        // setTimeout(function () {
        //     $compile($('#' + tableId))(this);
        // }, 1000);
    };

    performancePopupFiltersChanged = () => {
        this._commonService.showLoader();
        var type = $('#businessByToggleBtn .active').attr('type');
        var params = popupParameter;
        if(this.activeTabId === 5) {
            params['agentMarketingCompany'] = this.agentMarketingCompany;
        }
        params['param1'] = [];
        this.carrierOrAgencySelectedItems.forEach(function (ele, index) {
            let trimEle = ele.trim();
            params['param1'].push(trimEle);
        });
        // params['param1'] = this.carrierOrAgencySelectedItems;
        params['policyStatus'] = this.policySelectedItems;
        params['lob'] = this.modalLOB;
        params['param2'] = this.modalProduct;
        if (type === '$') {
            params['param11'] = 'sale';
            var api = 'getAgentSales';
            var isYoYFlagVal = false;
            if(this.isYOYFlag === true){
                api = 'getPopupAgentSales';
                isYoYFlagVal = true;
            }
        } else if (type === '#') {
            params['param11'] = 'policy';
            var api = 'getAgentSales';
            var isYoYFlagVal = false;
            if(this.isYOYFlag === true){
                api = 'getPopupAgentSales';
                isYoYFlagVal = true;
            }
        }  else if (type === 'applications') {
            params['param11'] = 'application';
            var isYoYFlagVal = false;
            var api = 'getAgentApplicationSales';
            if (this.activeTabId == 6 ) {
                api = ApiConstant.getAgentSalesForMissingAgents;
            }
            if(this.isYOYFlag === true){
                api = 'getPopupAgentApplicationSales';
                isYoYFlagVal = true;
            }
        } else {
            params['param11'] = 'commission';
            var api = 'getAgentSales';
            var isYoYFlagVal = false;
            if(this.isYOYFlag === true){
                api = 'getPopupAgentSales';
                isYoYFlagVal = true;
            }
        }
        this.getPieChartData(params);
        this.productChanged(this.modalProduct, this.agentPopupDetailsObj['awnHiddenId'], api, isYoYFlagVal,'')
    }

    changePopupChartData = function (date, selector) {
        this.popupDateCount = this.popupDateCount + 1;
        if(this.popupDateCount > 2) {
            this._commonService.showLoader();
            var type = $('#businessByToggleBtn .active').attr('type');
            var params = popupParameter;
            if(this.activeTabId === 5) {
                params.agentMarketingCompany = this.agentMarketingCompany;
            }
            if (type === '$') {
                params.param11 = 'sale';
                var api = 'getAgentSales';
                var isYoYFlagVal = false;
                if(this.isYOYFlag === true){
                    api = 'getPopupAgentSales';
                    isYoYFlagVal = true;
                }
            } else if (type === '#') {
                params.param11 = 'policy';
                var api = 'getAgentSales';
                var isYoYFlagVal = false;
                if(this.isYOYFlag === true){
                    api = 'getPopupAgentSales';
                    isYoYFlagVal = true;
                }
            }  else if (type === 'applications') {
                params.param11 = 'application';
                var isYoYFlagVal = false;
                var api = 'getAgentApplicationSales';
                if (this.activeTabId == 6 ) {
                    api = ApiConstant.getAgentSalesForMissingAgents;
                }
                if(this.isYOYFlag === true){
                    api = 'getPopupAgentApplicationSales';
                    isYoYFlagVal = true;
                }
            } else {
                params.param11 = 'commission';
                var api = 'getAgentSales';
                var isYoYFlagVal = false;
                if(this.isYOYFlag === true){
                    api = 'getPopupAgentSales';
                    isYoYFlagVal = true;
                }
            }
            if (selector === 'fromDate') {
                // $scope.fromDate = date;
                params.param3 = this._commonService.convertDate(date);
                params.param4 = this._commonService.convertDate(params.param4);
            } else {
                // $scope.toDate = date;
                params.param4 = this._commonService.convertDate(date);
                params.param3 = this._commonService.convertDate(params.param3);
            }
            this.agentPopupDetailsObj['fromDate'] = params.param3;
            this.agentPopupDetailsObj['toDate'] = params.param4;
            this.getPieChartData(params);
            if($('#popupTabs .active').text() === 'Premium ($)'){
                api = 'getAgentSales';
            } else if($('#popupTabs .active').text() === 'Pending'){
                api = 'getPendingBusiness';
            }
            // this.productChanged(this.agentPopupDetailsObj['selectedProduct'], this.agentPopupDetailsObj['awnHiddenId'], api, isYoYFlagVal,'')
            this.productChanged(this.agentPopupDetailsObj['selectedProduct'], this.agentPopupDetailsObj['awnHiddenId'], api, isYoYFlagVal,'')
        }
    };

    //Converts the string to date format
    convertToDate = (date) =>{
        var dateValues = date.split('-');
        return new Date(Number(dateValues[0]), Number(dateValues[1]) - 1, Number(dateValues[2]));
    };

    getData() {
        let activeSubTabObj = this.localStorageService.get('activeSubTab');
        let tableConfig = this.tableService.getTableConfigObj();
        if(this.activeTabId === 5 && activeSubTabObj && activeSubTabObj['id'] === 22 && $('#toggleReport .toggleActive').attr('type') === '#'){
            tableConfig['apiName'] = ApiConstant.getAgencyYearlyReport;
            $('#breadcrumb').show();
        }
        if(this.activeTabId === 5 && activeSubTabObj && activeSubTabObj['id'] === 22 && $('#toggleReport .toggleActive').attr('type') === '$'){
            tableConfig['apiName'] = ApiConstant.getAgencyYearlyReportSales;
            $('#breadcrumb').show();
        }

        if(activeSubTabObj && activeSubTabObj['id'] === 13){
            $('#breadcrumb').hide();
        }

        if(activeSubTabObj && activeSubTabObj['id'] === 27){
            $('#breadcrumb').hide();
        }
        if(tableConfig['apiName'] === '') {
            tableConfig = this.defaulttableConfig;
        }
        // Find all the <li> elements with class "breadcrumb-item"
        const liElements = document.querySelectorAll('.breadcrumb-item');
        if(liElements.length) {
        // Get the last <li> element
        const lastLiElement = liElements[liElements.length - 1];
        // Get the value of the "agentlevel" attribute from the <a> tag inside the last <li> element
        const agentLevelValue = lastLiElement.querySelector('a').getAttribute('agentlevel');
        this._commonService.setParameters('level', agentLevelValue);
        }
        this.getParameters = this._commonService.getParameters();
        this._commonService.setParameters('param5', 'All');
        this.initializeTable( this.getParameters , tableConfig);
    };

    updateYOYLineCharts = (type, e) => {
        this._commonService.showLoader();
        $('#performanceChartToggle .active').removeClass('active');
        $(e.target).closest('li').addClass('active');
        var params = popupParameter;
        var apiName = 'getYearOnYearComparision';
        if (type === '$') {
            params['salepolicytoggle'] = '$';
        } else {
            params['salepolicytoggle'] = '#';
        }
        if(params['sapOrTapToggle'].analyticsTabTapValue === 'tap'){
            apiName = 'getYearOnYearComparisionTap';
        }
        var yoyCompRequiredLineChart = this.getYoYChartProp(apiName);
        var YoYCompChart = this.chartService.getChart(yoyCompRequiredLineChart, params);
        YoYCompChart.subscribe( (data) => {
            this._commonService.hideLoader();
            this.agentPopupDetailsObj['yoyCompLineOptions'] = data['chart_options'];
            this.agentPopupDetailsObj['yoyCompLineData'] = data['chart_data'];
        })
    }

    policyOrPremiumChanged = function(e, product, id, api, isYOYFlag, popupActiveTab, tabId?){
        $('#popupTabs .active').removeClass('active');
        // $(e.target).closest('li').addClass('active');
        $('#'+tabId).addClass('active')
        this.productChanged(product, id, api, isYOYFlag,'')
    }

    productChanged = (product, id, apiName, isYOYFlag, isGetProductsByAgent) => {
        this._commonService.showLoader();
        this.agentPopupDetailsObj.selectedProduct  = product;
        var api = '';
        if(isGetProductsByAgent === ApiConstant.getProductsByAgent){
            api = apiName;
            if($('#popupTabs .active').text() === 'Premium ($)'){
                api = 'getAgentSales';
            } else if($('#popupTabs .active').text() === 'Pending'){
                api = 'getPendingBusiness';
            }
        } else {
            api = apiName;
        }
        var params = popupParameter;
        params['param3'] = this._commonService.convertDate(this.agentPopupDetailsObj['fromDate']);
        params['param4'] = this._commonService.convertDate(this.agentPopupDetailsObj['toDate']);
        // params['param5'] = id;
        if(this.activeTabId ==4 && this.clientType === 'carrier' && $('#pageSubTabs .active').text().trim() === 'TAP Full Downline Summary Report'){
            api = $('#popupTabs li.active').attr('api')+'Tap';
        } else {
            if($('#popupTabs li.active').attr('api') !== undefined){
                api = $('#popupTabs li.active').attr('api');
                if(api === 'getAgentApplicationSales' || api === 'getAgentSales' || api === 'getPendingBusiness'){
                    params['param2'] = product;
                }
            }
        }
        if ((this.activeTabId == 2 && params['sapOrTapToggle'].agentsTabTapValue === 'tap') || (this.activeTabId == 5 && params['sapOrTapToggle'].reportsTabTapValue === 'tap')|| (this.activeTabId == 3 && params['sapOrTapToggle'].analyticsTabTapValue === 'tap')) {
            if (isYOYFlag) {
                if (api === 'getPopupAgentSales') {
                    api = 'getAgentSalesTap';
                } else if (api === 'getPopupPendingBusiness') {
                    api = 'getPendingBusinessTap';
                }
                //params.param5 = scope.tapAgentId;
            } else {
                if (api === 'getAgentSales') {
                    api = 'getAgentSalesTap';
                } else if (api === 'getPendingBusiness') {
                    api = 'getPendingBusinessTap';
                }
            }
        }
        if(this.activeTabId === 3 && this.activeSubTabId === 4){
            params['param1'] = [];
            this.carrierOrAgencySelectedItems.forEach(function (ele, index) {
                let trimEle = ele.trim();
                params['param1'].push(trimEle);
            });
            params['policyStatus'] = this.policySelectedItems;
            params['lob'] = this.modalLOB;
            params['param2'] = this.modalProduct;
            if(params['sapOrTapToggle'].analyticsTabTapValue === 'sap'){
                if(params['param11'] === 'application'){
                    api = ApiConstant.getPopupAgentApplicationSales;
                } else {
                    if($('#popupTabs .active').text() === 'Premium ($)'){
                        api = 'getPopupAgentSales';
                    } else if($('#popupTabs .active').text() === 'Pending'){
                        api = 'getPopupPendingBusiness';
                    }
                }
            }
            if(params['sapOrTapToggle'].analyticsTabTapValue === 'tap'){
                if(params['param11'] === 'application'){
                    api = ApiConstant.getPopupAgentApplicationSalesTap;
                } else {
                    if($('#popupTabs .active').text() === 'Premium ($)'){
                        api = 'getPopupAgentSalesTap';
                    } else if($('#popupTabs .active').text() === 'Pending'){
                        api = 'getPopupPendingBusinessTap';
                    }
                }
            }
        }

        if (this.activeTabId == 6) {
            // params.param5 = scope.tapAgentId;
            if (api === 'getAgentSales') {
                api = 'getAgentSalesForMissingAgents';
            } else if (api === 'getPendingBusiness') {
                api = 'getPendingBusinessForMissingAgents';
            }
        }

        var requiredChart = {
            'title': {},
            'chart_type': 'linePlusBarChart',
            'stacked': false,
            'api_name': api,
            'index': 7,
            'chart_id': 'getAgentSales',
            'ticks': 10,
            'height': 300,
            'donut': false,
            'margin': {
                'top': 20,
                'right': 20,
                'bottom': 55,
                'left': 60
            },
            'tabId': 'tab1'
        };
        if(api){
            var chart = this.chartService.getChart(requiredChart, params);
            chart.subscribe( (success)=> {
            this.agentPopupDetailsObj['agentSalesOptions'] = success['chart_options'];
            this.agentPopupDetailsObj['agentSalesData'] = success['chart_data'];
            if(!this.isUpdatePieChartFlag) {
                this._commonService.hideLoader();
            }
            });
        }
    };

    updatePieCharts = (type, e, isYOYFlag) =>{
        $('#businessByToggleBtn .active').removeClass('active');
        $(e.target).closest('li').addClass('active');
        var params = popupParameter;
        params['param3'] = this._commonService.convertDate(params['param3']);
        params['param4'] = this._commonService.convertDate(params['param4']);
        params['agentMarketingCompany'] = this.agentMarketingCompany;
        var temp = this.clientType === 'agency' ? 'Carrier' : 'Agency';
        this.isUpdatePieChartFlag = true;
        if (type === '$') {
            params['param11'] = 'sale';
            $('#pieChart1 b').html('Premium by ' + temp + ':');
            $('#pieChart2 b').html('Premium by Product Line:');
            var api = 'getAgentSales';
            var isYoYFlagVal = false;
            if(this.isYOYFlag === true){
                api = 'getPopupAgentSales';
                isYoYFlagVal = true;
            }
            this.policyOrPremiumChanged({}, this.agentPopupDetailsObj.selectedProduct, this.agentPopupDetailsObj.awnHiddenId, api,isYOYFlag, 'agent_sales_tab',)
            this.showPremiumSubtab = 'yes';
            this.showPopupSubmittedSubTab = 'no';
        } else if (type === '#') {
            params['param11'] = 'policy';
            $('#pieChart1 b').html('Policies by ' + temp + ':');
            $('#pieChart2 b').html('Policies by Product Line:');
            var api = 'getAgentSales';
            var isYoYFlagVal = false;
            if(this.isYOYFlag === true){
                api = 'getPopupAgentSales';
                isYoYFlagVal = true;
            }
            this.showPremiumSubtab = 'yes';
            this.showPopupSubmittedSubTab = 'no';
            this.policyOrPremiumChanged({}, this.agentPopupDetailsObj.selectedProduct, this.agentPopupDetailsObj.awnHiddenId, api,isYOYFlag, 'agent_sales_tab' )
        } else if (type === 'applications') {
            params['param11'] = 'application';
            $('#pieChart1 b').html('Applications by ' + temp + ':');
            $('#pieChart2 b').html('Applications by Product Line:');
            var isYoYFlagVal = false;
            var api = 'getAgentApplicationSales';
            if (this.activeTabId == 6 ) {
                api = ApiConstant.getAgentSalesForMissingAgents;
            }
            if(this.activeTabId === 3 && this.activeSubTabId === 4 ){
                api = 'getPopupAgentApplicationSales';
                isYoYFlagVal = true;
            }
            this.policyOrPremiumChanged({}, this.agentPopupDetailsObj.selectedProduct, this.agentPopupDetailsObj.awnHiddenId, api,isYOYFlag, 'agent_sales_tab' )
            this.showPremiumSubtab = 'no';
            this.showPopupSubmittedSubTab = 'yes';
        } else {
            var text = this.clientType === 'agency' ? 'Override' : 'Commission';
            params['param11'] = 'commission';
            $('#pieChart1 b').html(text + ' by ' + temp + ':');
            $('#pieChart2 b').html(text + ' by Product Line:');
            var api = 'getAgentSales';
            var isYoYFlagVal = false;
            if(this.isYOYFlag === true){
                api = 'getPopupAgentSales';
                isYoYFlagVal = true;
            }
            this.policyOrPremiumChanged({}, this.agentPopupDetailsObj.selectedProduct, this.agentPopupDetailsObj.awnHiddenId, api,isYOYFlag, 'agent_sales_tab')
            this.showPremiumSubtab = 'yes';
            this.showPopupSubmittedSubTab = 'no';
        }
        this.getPieChartData(params);
    };

    getPieChartData = (params) =>{
        this._commonService.showLoader();
        var businessByProductLineApi = 'getBusinessByProductLine';
        var businessByCarriersApi = 'getBusinessByCarriers';
        var yoyPerformanceApi = '';
        if (this.activeTabId === 3 && this.activeSubTabId === 4) {
            businessByCarriersApi = 'getPopupBusinessByCarriers';
            businessByProductLineApi = 'getPopupBusinessByProductLine';
            yoyPerformanceApi = 'getYearOnYearComparision';
        }
        if(this.activeTabId === 5 && this.clientType === 'carrier' && $('#pageSubTabs .active').text().trim() === 'TAP Full Downline Summary Report'){
            businessByProductLineApi = 'getBusinessByProductLineTap';
            businessByCarriersApi = 'getBusinessByCarriersTap';
        }

        if ((this.activeTabId === 2 && this.sapAndTapObj.agentsTabTapValue === 'tap') || (this.activeTabId === 3 && this.sapAndTapObj.analyticsTabTapValue === 'tap')|| (this.activeTabId === 5 && this.sapAndTapObj.reportsTabTapValue === 'tap')) {
            businessByProductLineApi = 'getBusinessByProductLineTap';
            businessByCarriersApi = 'getBusinessByCarriersTap';
            yoyPerformanceApi = ApiConstant.getYearOnYearComparisionTap;
        }

        if (this.performanceSapTapEnable === true) {
            businessByProductLineApi = 'getPopupBusinessByProductLineTap';
            businessByCarriersApi = 'getPopupBusinessByCarriersTap';
        }

        if (this.activeTabId === 6) {
            businessByProductLineApi = 'getBusinessByProductLineForMissingAgents';
            businessByCarriersApi = 'getBusinessByCarriersForMissingAgents';
        }

        //Pie chart for business by carrier
        var requiredChartByCarrier = this.getBusByCarrierChartProp(businessByCarriersApi);
        var chartCarrier = this.chartService.getChart(requiredChartByCarrier, params);
        var secondPieChartparams = cloneDeep(params);
        chartCarrier.subscribe( (success) =>{
            this.agentPopupDetailsObj['agentCarrierGraphOptions'] = success['chart_options'];
            this.agentPopupDetailsObj['agentCarrierGraphdata'] = success['chart_data'];

            //Pie chart for Business by productline
            var requiredLineChart = this.getBusByProductLineChartProp(businessByProductLineApi)
            var chart = this.chartService.getChart(requiredLineChart, secondPieChartparams);
            chart.subscribe( (success)=> {
                this.agentPopupDetailsObj['agentGraphOptions'] = success['chart_options'];
                this.agentPopupDetailsObj['agentGraphdata'] = success['chart_data'];
            });

            if (this.activeTabId === 3 && this.activeSubTabId === 4) {
                var yoyCompRequiredLineChart = this.getYoYChartProp(yoyPerformanceApi);
                var YoYCompChart = this.chartService.getChart(yoyCompRequiredLineChart, params);
                YoYCompChart.subscribe( (data) => {
                    this._commonService.hideLoader();
                    this.agentPopupDetailsObj['yoyCompLineOptions'] = data['chart_options'];
                    this.agentPopupDetailsObj['yoyCompLineData'] = data['chart_data'];
                    $('#idleTimeoutPrompt').modal('show')
                    $('#idleTimeoutPrompt').resize();
                });
            }
            $("#popupTabs li:first-child").addClass("active");
            setTimeout(() => {
                this._commonService.hideLoader();
                this.isUpdatePieChartFlag = false;
            }, 1500);
        });
    };

    addColumnFilters(tableConfig) {
        var optionsString;
        tableConfig.columnFilters.forEach((filter) => {
            optionsString = '';
            filter.options.forEach((option) => {
                optionsString = optionsString + '<option>' + option + '</option>';
            });
            $('#' + tableConfig.id + '_columnFilters')
                .append('<div class=\'col-xs-2 col-sm-2 col-lg-2 selectorContainer\'><div class=\'selectorLabel\'>' +
                    filter.column + '</div><select dbParam=\'' + filter.dbparam + '\' class=\'col-xs-11 col-sm-11 col-lg-11\'>' +
                    optionsString + '</select></div>');
        });
        $('#' + tableConfig.id + '_columnFilters select').off().on('change', function () {
            this.drawTable(tableConfig);
        });
    };

    //converts number to ',' separated number
    convertNumber = (number, checkFormatFlag) =>{
        if (checkFormatFlag) {
            if ($('#toggleReport button.toggleActive').attr('type') === '$') {
                return '$' + number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            } else {
                return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            }
        } else {
            return number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        }
    };

    initializeTable = (params, tableConfig) => {
        this.activeTabId = this.localStorageService.get('activeTabId')
        let activeSubTab = this.localStorageService.get('activeSubTab');
        if(activeSubTab && Object.keys(activeSubTab).length){
            this.activeSubTabId = activeSubTab['id'];
        }
        if( tableConfig && tableConfig.apiName != ''){
            let apiName = this.apiName =  tableConfig.apiName;
            if(apiName == undefined) {
                this.spinner.hide();
                return;
            }
            var tableId = tableConfig.id;
            //  Delete existing base table without popup before rendering new
            if ($('#popupContainer').length != 1) {
                let tables = $("table");
                for (let i = 0; i < tables.length; i++) {
                    const table = tables[i];
                    if ($.fn.DataTable.isDataTable(table)) {
                        $(table).DataTable().destroy();
                        $(table).empty();
                    }
                }
            }
            let that = this;
            that.tableColumnConfig(configJson);
            // if($('#toggleAgents .toggleActive').attr('api') === ApiConstant.getAgentSummary){
            //     tableConfig.apiName = ApiConstant.getAgentSummary;
            // }
            // if($('#popupContainer').length === 1 && tableConfig.apiName !== ApiConstant.getStateSaleData &&
            //     tableConfig.apiName !== ApiConstant.getStatePoliciesData && tableConfig.apiName !== ApiConstant.getStateSaleDataReport && tableConfig.apiName !== ApiConstant.getStatePoliciesDataReport){
            //     tableConfig.clickEventOn = [];
            //     tableConfig.columnDefs = [{ 'className': 'text-end', 'targets': [2] }];
            // }
            let getTableConfig = {};
            getTableConfig = that.tableService.getTableDataConfig(tableConfig.apiName);


            //Add class and visibilty values for table column using tabledata config.
            if(tableConfig.apiName === 'getPolicyAlerts'){
                tableConfig.columnDefs = [{'className': 'text-end', 'targets': [3]}];
                tableConfig.clickEventOn = [];
                // params.tableDataConfig = getTableConfig['tableDataConfig']
                // configJson.tableColumnConfig.tableDataConfigAlertPolicies.forEach((ele, index) => {
                //     tableConfig.columnDefs.push({ 'targets': index, 'className': ele['class'], 'visible': ele['visibility']})
                // });
                this._commonService.setParameters('alertDropDownValue', params.alertDropDownValue);
                this._commonService.setParameters('selectedInfolabalValue', params.selectedInfolabalValue);
                params['param8'] = params['end_date_temp'] ? params['end_date_temp'] : params['param8'];
            }

            if(tableConfig.apiName === 'getAgentApplicationDetails'){
                this._commonService.setParameters('param11','application');
            } else{
                // params.tableDataConfig =  getTableConfig['tableDataConfig'].filter(obj => {
                //     return obj.role === JSON.parse(localStorage.getItem('userInfo'))['role'];
                // });
            }

            if(tableConfig.apiName !== ApiConstant.getStateSaleDataReport && tableConfig.apiName !== ApiConstant.getStateSaleData && tableConfig.apiName !== ApiConstant.getStatePoliciesData && tableConfig.apiName !== ApiConstant.getStatePoliciesDataReport){
                params['param6'] = 'All';
            }

            if (CommonConstant.sapOrTapToggle['alertsTabTapVlue'] === 'tap') {
                params.orderBy = this.orderByConfigTap;
            } else {
                params.orderBy = that.orderByConfig;
            }
            params.pageNo = 0;
            params.column = '';
            params.offset = configJson.pageOffset;
            // params.url = apiName;
            this.searchObj = this.searchObj?.filter(ele=>ele.api === apiName);
            if(! this.searchObj) {
                this.searchObj =[];
            }
            params.searchObj = this.searchObj;
            if(this.activeTabId === 5 && [27, 37].includes(this.activeSubTabId)){
              const updated_params = this._commonService.getParameters();
              if(params['start_date_temp']) {
                this.program_start_date = params['start_date_temp'];
              }
              if(params['end_date_temp']) {
                this.program_end_date = params['end_date_temp'];
              }
            let fromStartdate = $('#incentivePrograms .active .startDate').text();
            let startdate = fromStartdate.split(':');
            let startdateValues = startdate[1]?.split('/');
            
            let toEndDate = $('#incentivePrograms .active .endDate').text();
            let endDate = toEndDate.split(':');
            let enddatedateValues = endDate[1]?.split('/');

            if(startdate[1]){
                params['param3'] = startdateValues[2].trim() + '-' + startdateValues[0].trim() + '-' + startdateValues[1].trim();
            } else {
                params['param3'] = this.program_start_date;
            }

            if(endDate[1]){
                params['param4'] = enddatedateValues[2].trim() + '-' + enddatedateValues[0].trim() + '-' + enddatedateValues[1].trim();
            } else {
                params['param4'] = this.program_end_date;
            }
              params['policyStatus'] = 'active';
            }
            //for persistency tracker report changes in params
            let selectedColumnName = this.localStorageService.get('selectedColumnName')
            let AWNHiddenId = this.localStorageService.get('AWNHiddenId');
            let ProductLine = this.localStorageService.get('ProductLine');
            let LapsedColumnArray = ["Lapsed in 3 months", "Lapsed in 6 months", "Lapsed in 9 months", "Lapsed in 13 months"];
            if(this.activeSubTabId == 39 && selectedColumnName == "Placed Policies") {
                params['reportIdentifier'] = this.activeSubTabId;
                params['policyStatus'] = ['Active'];
                params['param2'] = ProductLine;
                params['param5'] = AWNHiddenId;
                params['clickedColumnName'] = selectedColumnName;
            } if(this.activeSubTabId == 39 && selectedColumnName == "Submitted Applications") {
                params['reportIdentifier'] = this.activeSubTabId;
                params['param2'] = ProductLine;
                params['param5'] = AWNHiddenId;
                params['clickedColumnName'] = selectedColumnName;
                params['applicationStatus'] = ["Expired", "Submitted"];
                params['param11'] =  "application";
            } else if(this.activeSubTabId == 39 && LapsedColumnArray.includes(selectedColumnName)) {
                params['reportIdentifier'] = this.activeSubTabId;
                params['policyStatus'] = ['Lapsed'];
                params['param2'] = ProductLine;
                params['param5'] = AWNHiddenId;
                params['clickedColumnName'] = selectedColumnName;
            } else {
                params['reportIdentifier'] = this.activeSubTabId;
                params['clickedColumnName'] = selectedColumnName;
            }

            if(this.activeSubTabId == 40) {
                params['param4'] = params['end_date_temp'] ? params['end_date_temp'] : params['param4'];
            }

            if(this.activeSubTabId == 42) {
                params['param3'] = this.localStorageService.get('debtYear');
                params['salepolicytoggle'] = '$';
            }

            if(tableConfig.apiName === ApiConstant.getHighPerformers) {
                params['param8'] = "";
            }

            this.params = params;
            this._commonService.getTableData(params, apiName).subscribe(res => {
                this.spinner.hide();
                this.localStorageService.remove('selectedColumnName');
                this.localStorageService.remove('AWNHiddenId');
                this.localStorageService.remove('ProductLine');
                this.searchObj = params.searchObj;
                if(apiName === ApiConstant.getPolicies || apiName === ApiConstant.getAgentsForTrainer || apiName === ApiConstant.getTapPolicies || apiName === ApiConstant.getPoliciesForMissingAgent ||
                    apiName === ApiConstant.getYearlyPolicies || apiName === ApiConstant.getAgentReportPolicies ||
                    apiName === ApiConstant.getPendingPoliciesForMissingAgent || apiName === ApiConstant.getPendingPolicies ||
                    apiName === ApiConstant.getAgentLapsedPolicies || apiName === ApiConstant.getLifeAndAnnuityPoliciesTap ||
                    apiName === ApiConstant.getLifeAndAnnuityPolicies || apiName === ApiConstant.getCommissionStatements ||
                    apiName === ApiConstant.getYearlyPoliciesTAP || apiName === ApiConstant.getReferredAgents){
                    $('#policyCountSummary').show();
                }
                let response = []; response = res;
                this.currentTableData = res['data'];
                var tableDataLength = 0;
                this.reportTotal = [];
                if (response && response['totalCount'] && response['data']) {
                    if(apiName === ApiConstant.getSubmittedMonthlyReport || apiName === ApiConstant.getSubmittedWeeklyReport ||
                    apiName === ApiConstant.getReportDashboard){
                        this.reportTotal =  response['reportTotal'][0]
                    }
                    tableDataLength = response['totalCount'][0] ? response['totalCount'][0].count : 0;
                    response = response['data'];
                }
                if ( apiName === ApiConstant.getStateSaleDataReport || apiName === ApiConstant.getStatePoliciesDataReport ||
                        apiName === ApiConstant.getStatePoliciesData || apiName === ApiConstant.getStateSaleData ||
                        apiName === ApiConstant.getNewAgentsReport) {
                    tableDataLength = response.length;
                }
                if( apiName == ApiConstant.getWeeklyPremiumByAgent && response[0] ){
                    let keys = Object.keys(response[0]);
                    for(let k=0; k<keys.length; k++){
                        k>=5?getTableConfig['tableColumnDef'][2].targets.push(k):'';
                    }
                }

                tableConfig.columnDefs = getTableConfig['tableColumnDef'];

                if (response && tableDataLength) {
                    if($('#toggleReport .toggleActive').attr('type') === '#'){
                        if(tableConfig.apiName === ApiConstant.getReportDashboard){
                            tableConfig.columnDefs = [{ "targets": [13], 'className': 'bold-header-value text-center' }];
                        }
                    }
                    if(apiName === ApiConstant.getStateSaleDataReport || apiName === ApiConstant.getStateSaleData || apiName === ApiConstant.getStatePoliciesData || apiName === ApiConstant.getStatePoliciesDataReport && tableDataLength > 0){
                        // tableConfig.columnDefs = [];
                        var columnAlignment = 'text-center';
                        if((this.activeTabId === 5 && $('#toggleReport .toggleActive').attr('type') === '$')){
                            columnAlignment = 'text-end'
                        } else if ((this.activeTabId === 1 && $('#toggle .toggleActive').attr('type') === '$')){
                            columnAlignment = 'text-end'
                        }
                        let columnDefArr = [];
                        for (let i = 0; i < Object.keys(response[0]).length; i++) {
                            if(i !== 0){
                               columnDefArr.push(i);
                            }
                        }
                        tableConfig.columnDefs = [...tableConfig.columnDefs, {'className': columnAlignment, 'targets': columnDefArr} ];
                    }

                    $('#noDataAvailable').hide();
                    $('#' + tableId).html('');
                    $("#" + tableId).append('<tfoot></tfoot>');
                    var columns = [];
                    var searchCols = [];
                    var getTableConfigObj = that.tableService.getTableConfigObj();

                    var rightAlignColumnsArr = [];
                    var centerAlignColumnsArr = [];
                    centerAlignColumnsArr.push('Split %')
                    if($('#popupContainer').length === 1){
                        rightAlignColumnsArr.push('Commission', 'Commission Basis', 'Total Sale', 'Annualized Premium', 'ACA', 'Target Premium', 'Total Premium', 'Submitted Premium', 'LIFE', 'Medicare Advantage','TAP','Payment Amount', 'Initial Deposit Amount', 'Total Estimated 1035', 'Balance');
                    } else {
                        rightAlignColumnsArr.push('Commission Basis', 'Annualized Premium', 'Submitted Premium', 'Pending Premium', 'Earned Premium', 'Commission', 'Override', 'TAP', 'Amount','Target Premium', 'Total Premium', '1 Premium', '2 Premium', '3 Premium', '4 Premium', 'Jan Premium', 'Feb Premium', 'Mar Premium', 'Apr Premium',
                        'May Premium', 'Jun Premium', 'Jul Premium', 'Aug Premium', 'Sep Premium', 'Oct Premium', 'Nov Premium',
                        'Dec Premium', 'YTD Premium', 'Premium', 'Initial Deposit Amount', 'Total Estimated 1035', 'Balance')
                        centerAlignColumnsArr.push('Percentage','TAP Policies', 'Submitted Applications', 'Submitted Policies', 'Pending Policies', 'Policies', 'Lapsed Policies', 'Commission Basis', 'Count');
                        if($('#toggleReport .toggleActive').attr('type') == '#'){
                            centerAlignColumnsArr.push('YTD', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Q1', 'Q2', 'Change', 'Y1', 'Y2', 'Y3');
                        } else {
                            rightAlignColumnsArr.push('YTD', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Commission Basis', 'Annualized Premium', 'Submitted Premium', 'Pending Premium',
                            'Earned Premium', 'Override', 'TAP', 'Commission', 'Y1', 'Y2', 'Y3', 'Initial Deposit Amount', 'Total Estimated 1035', 'Balance');
                        }
                    }

                    //get colum names from data

                    Object.keys(response[0]).forEach(function (ele, index) {

                        rightAlignColumnsArr.forEach(function (columnVal) {
                            if (ele === columnVal) {
                                tableConfig.columnDefs = [...tableConfig.columnDefs, { 'className': 'text-end', 'targets': [index] }];
                            }
                        });
                        centerAlignColumnsArr.forEach(function (columnVal) {
                            if (ele === columnVal) {
                                tableConfig.columnDefs =  [...tableConfig.columnDefs, { 'className': 'text-center', 'targets': [index] }];
                            } else if (columnVal == 'Count' && ele.match('\W*Count\W*')){
                                tableConfig.columnDefs =  [...tableConfig.columnDefs, { 'className': 'text-center', 'targets': [index] }];
                            }
                        });

                        // if($('#popupContainer').length === 1 && apiName == ApiConstant.getStateSaleData){
                        //     response.forEach(function (stateEle, sateIndex) {
                        //         if(sateIndex > 1){
                        //             tableConfig.columnDefs.push({ 'className': 'text-center', 'targets': [sateIndex] });
                        //         }
                        //     })
                        // }

                        // var leftAlignColumnsArr = [];
                        // if(apiName === ApiConstant.getAgentSummary){
                        //     leftAlignColumnsArr.push('AgentVizion Id')
                        // }
                        // leftAlignColumnsArr.push('Agency/Agent Name')
                        // leftAlignColumnsArr.forEach(function (leftcolumnVal) {
                        //     if (ele === leftcolumnVal) {
                        //         tableConfig.columnDefs.push({ 'className': 'text-start', 'targets': [index] });
                        //     }
                        // });

                        var title = ele;
                        var result = [];
                        if (apiName == ApiConstant.getSubmittedMonthlyReport || apiName == ApiConstant.getSubmittedWeeklyReport) {
                            var col = (ele) ? ele.split(' ') : ele;
                            if (col.length && col[1] === 'Count') {
                                title = 'Count';
                            } else if (col.length && col[1] === 'Premium') {
                                title = 'Premium';
                            }
                        }

                        if ( apiName === ApiConstant.getAgentTapDownlineReport || apiName === ApiConstant.getAgentTapDownlineReportSales) {
                            var foryear = params['forYear'];
                            var colYear = new Date().getFullYear();
                            if (foryear && foryear.split('-').length > 0) {
                                colYear = foryear.split('-')[0]
                            }
                            if (ele === 'Y1') {
                                title = params['lob'] + "-" + colYear;
                            } else if (ele === 'Y2') {
                                title = params['lob']  + "-" + (colYear - 1);
                            } else if (ele === 'Y3') {
                                title = params['lob'] + "-" + (colYear - 2);
                            }
                        }
                        if (apiName === ApiConstant.getAgentsIncDecReport) {
                            var monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                            let currentDate = new Date();
                            let currentMonth:any = String(currentDate.getMonth() + 1);
                            let currentYear:any = currentDate.getFullYear();
                            let lastYear:any = currentYear - 1;
                            currentYear = currentYear.toString().slice(-2);
                            var q2:any = monthNames[currentMonth] + "'" + currentYear;
                            var q21:any = currentMonth - 3;
                            var q1:any = currentMonth - 6;
                            var q12:any = (currentMonth - 6) + 3;
                            if (q21 < 0 || q12 < 0 ) {
                                q21 = ((monthNames.length) + (q21));
                                q21 = monthNames[q21] + "'" + parseInt(lastYear.toString().slice(-2));
                                q12 = ((monthNames.length-1) + (q12));
                                q12 = monthNames[q12] + "'" + parseInt(lastYear.toString().slice(-2));
                            } else if (q21 == 0 || q12 == 0) {
                                q21 = monthNames[q21 + 1] + "'" + currentYear;
                                q12 = ((monthNames.length-1) + (q12));
                                q12 = monthNames[q12] + "'" + parseInt(lastYear.toString().slice(-2));
                            } else {
                                q21 = monthNames[q21 + 1] + "'" + currentYear;
                                q12 = monthNames[q12] + "'" + currentYear;
                            }
                            if (q1 < 0) {
                                q1 = ((monthNames.length) + (q1));
                                q1 = monthNames[q1] + "'" + parseInt(lastYear.toString().slice(-2));
                            } else {
                                q1 = monthNames[q1 + 1] + "'" + currentYear;
                            }
                            if (ele === 'Q1') {
                                title = q1 + "-" + q12;
                                that.q1Title = title;
                                that._commonService.setQ1Title(title);
                            } else if (ele === 'Q2') {
                                title = q21 + "-" + q2;
                                that.q2Title = title;
                                that._commonService.setQ2Title(title);
                            }
                        }
                        if(that.searchObj) {
                            var isSearchIndex = that.searchObj.findIndex(searchEle => searchEle.column === ele)
                            if(isSearchIndex >-1){
                                searchCols.push( { "sSearch": that.searchObj[isSearchIndex].value })
                            } else {
                                searchCols.push(null)
                            }
                        }

                        columns.push({
                            'title': title+that.getColumnToolTips(title, apiName),
                            'data': ele,
                            'render': function (data, type, row) {
                                //Check if click event needs to bind on cell
                                if (data && typeof data !== 'string') {
                                    if (apiName === 'getAgentTapDownlineReportSales' || apiName === 'getAgencyYearlyReport' || apiName === 'getAgencyYearlyReportSales' || apiName === 'getReportDashboard') {
                                        data = that.convertNumber(data, true);
                                    } else {
                                        if(!['getIncentiveProgramAgents', 'getManagerQuarterlyIncentiveReport', 'getASMAgentYearEndBonusIncentiveReport', 'getNewManagerQuarterlyIncentiveReport'].includes(apiName)){
                                            data = that.convertNumber(data, false);
                                        }
                                    }
                                } else if (data == 0 && typeof data !== 'string') {
                                    if ( apiName === 'getAgentTapDownlineReportSales' || apiName === 'getAgencyYearlyReport' || apiName === 'getAgencyYearlyReportSales' || apiName === 'getReportDashboard') {
                                        data = that.convertNumber(data, true);
                                    } else {
                                        if(!['getIncentiveProgramAgents', 'getManagerQuarterlyIncentiveReport', 'getASMAgentYearEndBonusIncentiveReport', 'getNewManagerQuarterlyIncentiveReport'].includes(apiName)){
                                            data = that.convertNumber(data, false);
                                        }
                                    }
                                }

                                let clickEventOn:any;
                                if ($('#popupContainer').length === 1) {
                                    clickEventOn = [];
                                } else if(tableConfig.apiName === 'getPolicyAlerts'){
                                      clickEventOn = [];
                                } else if(tableConfig.apiName === ApiConstant.getCommissionData){
                                    clickEventOn = [{"column":"Commission", "functionToCall":"openCommissionDetailsModal"}];
                                } else if(tableConfig.apiName === 'getReportDashboard' && $('#toggleReport .toggleActive').attr('type') === '#'){
                                    clickEventOn = [{
                                        'column': 'Jan',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Feb',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Mar',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Apr',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'May',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Jun',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Jul',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Aug',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Sep',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Oct',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Nov',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }, {
                                        'column': 'Dec',
                                        'functionToCall': 'openLifeAnnuityYearlyPoliciesModal'
                                    }];
                                } else {
                                    if(getTableConfigObj['clickEventOn'].length === 0){
                                        clickEventOn = that.tableService.getTableClickEvents();
                                        if(tableConfig.apiName === ApiConstant.getAgencyAnalyticsReport){
                                            clickEventOn[0] = {column: "AgentVizion Id", functionToCall: "openAgentDetailsModal"};
                                        }
                                    } else {
                                        if(that.activeTabId === 2 && that.activeSubTabId === 2){
                                            clickEventOn = [{"column":"Commission", "functionToCall":"openCommissionDetailsModal"}];
                                        } else if(that.activeTabId == 5 && $('#toggleReport button.toggleActive').attr('type') === '$'){
                                            if(tableConfig.apiName === ApiConstant.getAgencyYearlyReportSales){
                                                clickEventOn = that.tableService.getTableYearlyReportSaleClickEvents();
                                            }
                                            if(tableConfig.apiName === ApiConstant.getAgentHighestMonthReport || tableConfig.apiName === ApiConstant.getNewAgentsReport || tableConfig.apiName === ApiConstant.getAgentsIncDecReport || tableConfig.apiName === ApiConstant.getDebtBalanceReport || tableConfig.apiName === ApiConstant.getDebtMonthlyAgentReport){
                                                clickEventOn = [{column: "Agent Id", functionToCall: "openAgentDetailsModal"}]
                                            }
                                            if(tableConfig.apiName === ApiConstant.getAgentPersistencyTrackerReport  && that.activeSubTabId === 39) {
                                                clickEventOn = [{column: "Agent Id", functionToCall: "openAgentDetailsModal"},{column: "Submitted Applications", functionToCall: "openSubmittedApplicationsModal"}, {column: "Placed Policies", functionToCall: "openPoliciesModal"}, {column: "Lapsed in 3 months", functionToCall: "openPoliciesModal"}, {column: "Lapsed in 6 months", functionToCall: "openPoliciesModal"}, {column: "Lapsed in 9 months", functionToCall: "openPoliciesModal"}, {column: "Lapsed in 13 months", functionToCall: "openPoliciesModal"}]
                                            }
                                        } else {
                                            clickEventOn = tableConfig['clickEventOn'];
                                            if(tableConfig.apiName === ApiConstant.getAgencyAnalyticsReport){
                                                clickEventOn[0] = {column: "AgentVizion Id", functionToCall: "openAgentDetailsModal"};
                                            }
                                        }
                                    }
                                }
                                if (Object.keys(tableConfig).length) {
                                    if(clickEventOn && clickEventOn.length){
                                        result = clickEventOn.filter(function (obj) {
                                            if(apiName === ApiConstant.getAgentSummaryPersonalDetails || apiName === ApiConstant.getAgentSummaryPersonalDetailsRollup || apiName === ApiConstant.getAgentSummaryPersonalDetailsRollupDrill){
                                                if(obj.column !== 'Agency/Agent Name'){
                                                   return obj.column === ele;
                                                }
                                            } else if(apiName === ApiConstant.getReportDashboard){
                                                if(obj.column !== 'YTD'){
                                                   return obj.column === ele;
                                                }
                                            } else if(apiName === ApiConstant.getHighPerformers || apiName === ApiConstant.getHighPerformersTap || apiName === ApiConstant.getLowPerformers || apiName === ApiConstant.getLowPerformersTap){
                                                if(obj.column !== 'AgentVizion Id'){
                                                   return obj.column === ele;
                                                }
                                            } else {
                                                return obj.column === ele;
                                            }
                                        });
                                    }
                                }
                                // /** Add '$' to commission and basis value */
                                if ((that.activeTabId == 2 || that.activeTabId == 3 || that.activeTabId == 4 || that.activeTabId == 5 || that.activeTabId == 6)) {//apiName === '/getAgentDetails' || apiName === '/getHighPerformers' || apiName === 'getLowPerformers') {
                                    var addDollarColumnsArr = [];
                                    addDollarColumnsArr.push('Commission Basis', 'Annualized Premium', 'Submitted Premium',
                                     'Pending Premium', 'Earned Premium', 'Commission', 'Override', 'TAP', 'Amount',
                                     'Target Premium', 'Total Premium', 'Premium', 'Trainee Premium', 'Trainer Reward',
                                     'Trainee/Agent Premium', 'Trainee/Agent Target', 'Trainee/Agent Reward', 'Referred Premium',
                                     'Reward ', 'Agent Premium','SIP Premium', 'ASM Premium','DSM Premium', 'Begining Balance', 'Current Earnings', 'Current Advances', 'Current Adjustments', 'Current Check', 'Ending Balance', 'YTD Earnings', 'Agt Adv Balance', 'Initial Deposit Amount', 'Total Estimated 1035', 'Balance');
                                    if(data!='N/A') addDollarColumnsArr.push('Reward')
                                    addDollarColumnsArr.forEach(function (columnVal) {
                                        if (ele === columnVal && data) {
                                            data = '$' + data.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                                            // tableConfig.columnDefs = [...tableConfig.columnDefs, { 'className': 'text-end', 'targets': [index] }];
                                        }
                                    });
                                    let re = new RegExp('([0-9]{4}-((0[1-9]|1[0-2])|([1-9]{1,1}))-((0[1-9]|[1-2][0-9]|3[0-1])|([1-9]{1,1})))|([0-9]{4}\/((0[1-9]|1[0-2])|([1-9]{1,1}))\/((0[1-9]|[1-2][0-9]|3[0-1])|([1-9]{1,1})))');
                                    if ([ApiConstant.getWeeklyPremiumByAgent].includes(apiName)&&
                                        ele.match(re)) {
                                            data = '$' + data?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                                    }
                                }

                                /**
                                 * Remove , from the columns
                                 */
                                if(( that.activeTabId == 5)) {
                                  let removeCommaColumnsArr = [];
                                  removeCommaColumnsArr.push('Referrer Id', 'Trainer Id', 'AgentVizion Id', 'Agency/Agent Id', 'Agent Id', 'DSM Id', 'ASM Id', 'Agent ID', 'Plan Year');
                                  removeCommaColumnsArr.forEach(function (columnName) {
                                    if (ele === columnName) {
                                      data = data && typeof data == 'string'? data.replaceAll(',','') : data;
                                    }
                                  });
                                }

                                /* in drilldown table, Adding '$' prefix for columns value  */
                                // if (apiName == ApiConstant.getPointsDistribution || apiName === ApiConstant.getCommissionStatements || apiName === ApiConstant.getLifeAndAnnuityPolicies || apiName == ApiConstant.getApplications || apiName == ApiConstant.getPendingPoliciesForMissingAgent || apiName == ApiConstant.getAgentLapsedPolicies || apiName === ApiConstant.getPoliciesForMissingAgent || apiName === ApiConstant.getNewAgentsReport || apiName === ApiConstant.getAgentReportPolicies || apiName === ApiConstant.getPolicies || apiName === ApiConstant.getYearlyPolicies || apiName == ApiConstant.getYearlyPoliciesTAP || apiName === ApiConstant.getPendingPolicies || apiName === ApiConstant.getAgentSummaryDetailsTap ) {
                                //     var addDollarColumnsArr = [];
                                //     // if (title === 'Premium') {
                                //     //     addDollarColumnsArr.push(ele);
                                //     // }
                                //     addDollarColumnsArr.push('Annualized Premium', 'Submitted Premium', 'Commission Basis', 'Commission', 'Earned Premium', 'Override', 'Target Premium', 'Total Premium');
                                //     addDollarColumnsArr.forEach(function (columnVal) {
                                //         if (ele === columnVal) {
                                //             data = '$' + data;
                                //             tableConfig.columnDefs = [...tableConfig.columnDefs, { 'className': 'text-end', 'targets': [index] }];
                                //         }
                                //     });
                                // }

                                if (apiName === ApiConstant.getSubmittedMonthlyReport || apiName === ApiConstant.getSubmittedWeeklyReport || apiName === ApiConstant.getPaymentPolicies) {
                                    var addDollarColumnArr = [];
                                    addDollarColumnArr.push('1 Premium', '2 Premium', '3 Premium', '4 Premium', 'Jan Premium', 'Feb Premium', 'Mar Premium', 'Apr Premium',
                                                            'May Premium', 'Jun Premium', 'Jul Premium', 'Aug Premium', 'Sep Premium', 'Oct Premium', 'Nov Premium',
                                                            'Dec Premium', 'YTD Premium', 'Premium', 'Payment Amount');
                                    addDollarColumnArr.forEach(function (columnVal) {
                                        if (ele === columnVal) {
                                            data = '$' + data;
                                            tableConfig.columnDefs = [...tableConfig.columnDefs, { 'className': 'text-end', 'targets': [index] }];
                                        }
                                    });
                                }

                                if(apiName == ApiConstant.getReportDashboard || apiName === ApiConstant.getNewAgentsReport || apiName === ApiConstant.getAgentReportPolicies || apiName === ApiConstant.getAgencyYearlyReportSales || apiName === ApiConstant.getAgentHighestMonthReport || apiName === ApiConstant.getAgentsIncDecReport){
                                    var saleToggleColumns = [ 'YTD', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Q1', 'Q2', 'Change'];
                                    if($('#toggleReport button.toggleActive').attr('type') === '$'){
                                        saleToggleColumns.forEach(function (columnVal) {
                                            if (ele === columnVal) {
                                                data = '$' + data;
                                            }
                                        });
                                    }
                                }
                                
                                if(['Grand Total'].includes(ele) && [ApiConstant.getDebtMonthlyAgentReport].includes(apiName)) {
                                    return '$'+data;
                                }

                                if ((type === 'display' || type === 'type') && ele === 'Percentage') {
                                    var attr;
                                    var data1 = data.split("%")[0]
                                    var color = (parseInt(data1) < 0) ? 'negative' : 'positive';
                                    attr = {
                                        'class': '' + color + ' links-inactive' + ' padding-0' + ' margin-0'
                                    };
                                    return $('<p>')
                                        .attr(attr)
                                        .text(data)
                                        .wrap('<div></div>')
                                        .parent()
                                        .html();
                                }
                                if ((type === 'display' || type === 'type') && result.length) {
                                    var attr;
                                    var agentName = row['Agency/Agent Name'] ? row['Agency/Agent Name'] : row['Agent Name'] ? row['Agent Name'] : row['Agency Name'];
                                    var agentId = row['Agency/Agent Id'] ? row['Agency/Agent Id'] : row['CRM Id'] ? row['CRM Id'] : row['Agency Id'] ? row['Agency Id'] : row['Agent Id'];
                                    var highriseId = row['CRM Id'] ? agentId : row[that.crmId];
                                    var agencyName = row['Agency Name'];
                                    var agentMarketingCompany = '';
                                    if(that.companyFilter === 'yes'){
                                        if(that.activeTabId === 5){
                                            agentMarketingCompany = row['Marketing Company'];
                                        } else {
                                            agentMarketingCompany = row['Agency'];
                                        }
                                    }
                                    that._commonService.setParameters('agentMarketingCompany', agentMarketingCompany);
                                    var plan = row['Plan'];
                                    var idType = '';
                                    if(apiName !== ApiConstant.getApplications && apiName !== '' && apiName !== 'getAgentSummary' && apiName !== 'getIncentiveProgramAgents' && apiName !== 'getAgentApplicationSummaryRollUp' && apiName !== 'getManagerQuarterlyIncentiveReport' && apiName !== 'getProductionAllowanceIncentiveReport' && apiName !== 'getBrokerageBucks' && apiName !== 'getASMAgentYearEndBonusIncentiveReport' && apiName !== 'getNewManagerQuarterlyIncentiveReport') {
                                        idType = 'agent';
                                    } else {
                                        idType = 'crm';
                                    }
                                    var highriseOrAgentId = (idType !== 'agent' && !['getManagerQuarterlyIncentiveReport', ApiConstant.getProductionAllowanceIncentiveReport, 'getASMAgentYearEndBonusIncentiveReport', 'getNewManagerQuarterlyIncentiveReport'].includes(apiName)) ? highriseId : agentId;
                                    var AWNHiddenValue = row['AWN Hidden'];
                                    var ProductLineValue = row['Product Line'];

                                    if (ele === 'Agency/Agent Name' && row.Level !== 'Agent' && ![ApiConstant.getAgentSummaryPersonalDetails].includes(apiName)) {
                                        if ([ApiConstant.getAgentContactDetails].includes(apiName)){
                                            $('#breadcrumb').hide();
                                            return data;
                                        }
                                        var className = 'fa fa-angle-down text-decoration-none';
                                        attr = { 'functionToCall': result[0].functionToCall, api: apiName, 'idType': idType, 'agentname': agentName, 'highriseid': highriseId, 'agentid': highriseOrAgentId, 'column': ele, 'level': row['Level'], 'class': className, 'agentMarketingCompany': agentMarketingCompany };
                                        //Bind click event on the cell
                                        //<i class="fa fa-angle-down text-decoration-none"></i>
                                        return $('<a>')
                                            .attr(attr)
                                            .text(data)
                                            .wrap('<div></div>')
                                            .parent()
                                            .html();
                                    } else if (ele === 'Agency/Agent Id') {
                                        if (row.Level === 'Agent' && ![ApiConstant.getManagerQuarterlyIncentiveReport, ApiConstant.getProductionAllowanceIncentiveReport, ApiConstant.getASMAgentYearEndBonusIncentiveReport, 
                                             ApiConstant.getFastStartBonusIncentiveReport, ApiConstant.getConventionIncentiveReportAris,
                                              ApiConstant.getConventionIncentiveReportAmericare, ApiConstant.getRsmBonusIncentiveReport,
                                              ApiConstant.getSipIncentiveReport, ApiConstant.getManagerSipIncentiveReport, ApiConstant.getQuarterlyProductMixBonus, ApiConstant.getNewManagerQuarterlyIncentiveReport].includes(apiName)) {
                                            if (that.activeTabId === 3 && apiName === ApiConstant.getAgencyAnalyticsReport) {
                                                attr = { 'functionToCall': result[0].functionToCall, isYOY: true, 'idType': 'agent', 'agentname': agentName, 'highriseid': highriseId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'agentMarketingCompany': agentMarketingCompany };
                                                return $('<a>')
                                                    .attr(attr)
                                                    .text(data)
                                                    .wrap('<div></div>')
                                                    .parent()
                                                    .html();
                                            } else {
                                                attr = { 'functionToCall': result[0].functionToCall, 'idType': idType, 'agentname': agentName, 'highriseid': highriseId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'agentMarketingCompany': agentMarketingCompany };
                                                return $('<a>')
                                                    .attr(attr)
                                                    .text(data)
                                                    .wrap('<div></div>')
                                                    .parent()
                                                    .html();
                                            }
                                        } else {
                                            if (that.activeTabId === 3 && apiName === ApiConstant.getAgencyAnalyticsReport) {
                                                attr = { 'functionToCall': result[0].functionToCall, isYOY: true, 'idType': 'agent', 'agentname': agentName, 'highriseid': highriseId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'agentMarketingCompany': agentMarketingCompany };
                                                return $('<a>')
                                                    .attr(attr)
                                                    .text(data)
                                                    .wrap('<div></div>')
                                                    .parent()
                                                    .html();
                                            } else if (that.activeTabId === 3 &&  apiName === ApiConstant.getAgentTapDownlineReportSales) {
                                                    var className = 'fa fa-angle-down text-decoration-none';
                                                    attr = { 'functionToCall': result[0].functionToCall, api: apiName, 'idType': idType, 'agentname': agentName, 'highriseid': highriseId, 'agentid': highriseOrAgentId, 'column': ele, 'level': row['Level'], 'class': className, 'agentMarketingCompany': agentMarketingCompany };
                                                    //Bind click event on the cell
                                                    //<i class="fa fa-angle-down text-decoration-none"></i>
                                                    return $('<a>')
                                                        .attr(attr)
                                                        .text(data)
                                                        .wrap('<div></div>')
                                                        .parent()
                                                        .html();
                                            } else if(that.activeTabId === 5 && [ApiConstant.getManagerQuarterlyIncentiveReport, ApiConstant.getFastStartBonusIncentiveReport, ApiConstant.getProductionAllowanceIncentiveReport, ApiConstant.getConventionIncentiveReportAris, ApiConstant.getConventionIncentiveReportAmericare, ApiConstant.getRsmBonusIncentiveReport, ApiConstant.getASMAgentYearEndBonusIncentiveReport, ApiConstant.getNewManagerQuarterlyIncentiveReport].includes(apiName)){
                                                return data && typeof data == 'string'? data.replaceAll(',',''):data;
                                            } else {
                                                return data;
                                            }
                                        }
                                    } else if (ele === 'Agent Id' && [ApiConstant.getWeeklyAwardIncentiveReport].includes(apiName)){
                                        return data;
                                    } else if (ele === 'Agency/Agent Name' && row.Level === 'Agent' && apiName !== ApiConstant.getAgentSummaryPersonalDetails) {
                                        attr = { 'class': 'fa fa-user' };
                                        return $('<p style="font-family: inherit;margin-bottom:0px;">')
                                            .attr(attr)
                                            .text(data)
                                            .wrap('<div></div>')
                                            .parent()
                                            .html();
                                    } else if (ele === that.crmId && that.activeTabId === 2 && ($('#toggleAgents .toggleActive').attr('api') === ApiConstant.getAgentSummary || $('#toggleAgents .toggleActive').attr('api') === ApiConstant.getAgentApplicationSummaryRollUp || $('#toggleAgents .toggleActive').attr('api') === ApiConstant.getAgentSummaryPersonalDetailsRollup || $('#toggleAgents .toggleActive').attr('api') === ApiConstant.getAgentPaymentSummary)) {
                                        let api ='';
                                        if(that.activeSubTabId === 25 ){
                                            api = 'getAgentApplicationSummaryRollUpDrill';
                                        }  else if(that.activeSubTabId === 26 ){
                                            api = 'getAgentSummaryPersonalDetailsRollupDrill';
                                        } else if(that.activeSubTabId === 33 ) {
                                            api = 'getAgentPaymentSummaryDetails';
                                        } else {
                                            if (that.sapAndTapObj.agentsTabTapValue === 'tap') {
                                                api = 'getAgentSummaryDetailsTap';
                                            } else {
                                                api = 'getAgentSummaryDetails';
                                            }
                                        }
                                        attr = { 'functionToCall': result[0].functionToCall,  'api': api, 'idType': idType, 'agentname': agentName, 'agencyname': agencyName, 'highriseid': highriseId, 'agentid': highriseOrAgentId, 'column': ele, 'level': row['Level'], 'agentlevel': row['Agent Level'], 'class': 'fa fa-angle-down text-decoration-none' };
                                        //Bind click event on the cell
                                        return $('<a>')
                                            .attr(attr)
                                            .text(data)
                                            .wrap('<div></div>')
                                            .parent()
                                            .html();
                                    } else if (ele === that.crmId && that.activeTabId === 5 && apiName === ApiConstant.getIncentiveProgramAgents) {
                                        attr = { 'functionToCall': 'openPointsDistribution', 'idType': idType, 'agentname': agentName, 'highriseid': highriseOrAgentId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'plan': plan };
                                        return $('<a>')
                                            .attr(attr)
                                            .text(data)
                                            .wrap('<div></div>')
                                            .parent()
                                            .html();
                                    } else if (ele === that.crmId && that.activeTabId === 5 && apiName === ApiConstant.getBrokerageBucks) {
                                        attr = { 'functionToCall': 'openPointsDistribution', 'idType': idType, 'agentname': agentName, 'highriseid': highriseOrAgentId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'plan': plan };
                                        return $('<a>')
                                            .attr(attr)
                                            .text(data)
                                            .wrap('<div></div>')
                                            .parent()
                                            .html();
                                    } else if (ele === 'Week No' && that.activeTabId === 5 ) {
                                        if([ApiConstant.getFastStartBonusIncentiveReport].includes(apiName)){
                                            attr = { 'functionToCall': 'openWeeklyFastStart', 'idType': idType, 'agentname': agentName, 'highriseid': highriseOrAgentId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'plan': plan, 'weekNo': row['Week No'] };
                                            return $('<a>')
                                                .attr(attr)
                                                .text(data)
                                                .wrap('<div></div>')
                                                .parent()
                                                .html();
                                        } else {
                                            return data;
                                        }
                                    } else if (ele === 'Trainee Count' && that.activeTabId === 5) {
                                      attr = { 'functionToCall': 'openTrainerFastStartBonus', 'idType': idType, 'agentname': agentName, 'highriseid': highriseOrAgentId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'plan': plan, trainerId: row['Trainer Id'], trainerName: row['Trainer Name']};
                                      return $('<a>')
                                          .attr(attr)
                                          .text(data)
                                          .wrap('<div></div>')
                                          .parent()
                                          .html();

                                    } else if (ele === 'Referred Agents' && that.activeTabId === 5) {
                                      attr = { 'functionToCall': 'openReferredAgentsModal', 'idType': idType, 'agentname': agentName, 'highriseid': highriseOrAgentId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'plan': plan, referralId: row['Referrer Id'], referralName: row['Referrer Name']};
                                      return $('<a>')
                                          .attr(attr)
                                          .text(data)
                                          .wrap('<div></div>')
                                          .parent()
                                          .html();
                                        } else if (ele === that.crmId && that.activeTabId !== 3 && $('#toggleAgents .toggleActive').attr('api') !== ApiConstant.getAgentSummary && $('#toggleAgents .toggleActive').attr('api') !== ApiConstant.getAgentApplicationSummaryRollUp && $('#toggleAgents .toggleActive').attr('api') !== ApiConstant.getAgentSummaryPersonalDetailsRollup) {
                                        if([ApiConstant.getAgentBonusIncentiveReport].includes(apiName)){
                                            return data && typeof data == 'string'? data.replaceAll(',',''):data;
                                        }
                                        else return data;
                                    } else if (ele === 'AgentVizion Id' && that.activeTabId === 3 && apiName === ApiConstant.getAgencyAnalyticsReport) {
                                        attr = { 'functionToCall': result[0].functionToCall, isYOY: true, 'idType': 'crm', 'agentname': agentName, 'agencyname': agencyName, 'highriseid': highriseId, 'agentid': highriseOrAgentId, 'column': ele, 'level': row['Level'], 'agentlevel': row['Agent Level'],'agentMarketingCompany': agentMarketingCompany };
                                        //Bind click event on the cell
                                        return $('<a>')
                                            .attr(attr)
                                            .text(data)
                                            .wrap('<div></div>')
                                            .parent()
                                            .html();
                                    } else if(ele === 'Reward' && [ApiConstant.getRsmBonusIncentiveReport].includes(apiName)){
                                        return data;
                                    } else if(ele === 'SIP Premium'){
                                        if(([ApiConstant.getSipIncentiveReport].includes(apiName) && ['Agent','level_1','level_2','level_3'].includes(row['Level']))||
                                            ([ApiConstant.getManagerSipIncentiveReport, ApiConstant.getQuarterlyProductMixBonus].includes(apiName) && ['level_3','level_1'].includes(row['Level']))){
                                            attr = { 'functionToCall': result[0].functionToCall, 'idType': idType, 'agentname': agentName, 'highriseid': highriseId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'plan': plan, 'agentMarketingCompany': agentMarketingCompany, "pointTitle":'Premium' };
                                            return $('<a>')
                                                .attr(attr)
                                                .text(data)
                                                .wrap('<div></div>')
                                                .parent()
                                                .html();
                                        } else {
                                            return data;
                                        }
                                    } else if(ele === 'Agent Policies'){
                                        let startDate='', endDate='';
                                        if([ApiConstant.getWeeklyAwardIncentiveReport].includes(apiName)){
                                            let temp1 = row['First Day Of Week'].split('/')
                                            let temp2 = row['Last Day Of Week'].split('/')
                                            startDate = temp1[2]+"-"+temp1[0]+"-"+temp1[1];
                                            endDate = temp2[2]+"-"+temp2[0]+"-"+temp2[1];
                                        }
                                        attr = { 'functionToCall': result[0].functionToCall, isYOY: false, 'idType': 'crm', 'agentname': row['Agent Name'],
                                         'highriseid': row['Agent Id'], 'agentid': row['Agent Id'], 'AWNHidden': AWNHiddenValue, 'column': ele, 
                                         'level': row['Agent Level'], 'agentMarketingCompany': agentMarketingCompany, 'startDate':startDate,'endDate':endDate };
                                        return $('<a>')
                                            .attr(attr)
                                            .text(data)
                                            .wrap('<div></div>')
                                            .parent()
                                            .html();
                                    } else if(ele === 'DSM Policies'){
                                        let startDate='', endDate='';
                                        if([ApiConstant.getWeeklyAwardIncentiveReport].includes(apiName)){
                                            let temp1 = row['First Day Of Week'].split('/')
                                            let temp2 = row['Last Day Of Week'].split('/')
                                            startDate = temp1[2]+"-"+temp1[0]+"-"+temp1[1];
                                            endDate = temp2[2]+"-"+temp2[0]+"-"+temp2[1];
                                        }
                                        attr = { 'functionToCall': result[0].functionToCall, isYOY: false, 'idType': 'crm', 'agentname': row['DSM Name'],
                                         'highriseid': row['DSM Id'], 'agentid': row['DSM Id'], 'AWNHidden': AWNHiddenValue, 'column': ele, 
                                         'level': row['DSM Level'], 'agentMarketingCompany': agentMarketingCompany, 'startDate':startDate,'endDate':endDate };
                                        return $('<a>')
                                            .attr(attr)
                                            .text(data)
                                            .wrap('<div></div>')
                                            .parent()
                                            .html();
                                    } else if(ele === 'ASM Policies'){
                                        let startDate='', endDate='';
                                        if([ApiConstant.getWeeklyAwardIncentiveReport].includes(apiName)){
                                            let temp1 = row['First Day Of Week'].split('/')
                                            let temp2 = row['Last Day Of Week'].split('/')
                                            startDate = temp1[2]+"-"+temp1[0]+"-"+temp1[1];
                                            endDate = temp2[2]+"-"+temp2[0]+"-"+temp2[1];
                                        }
                                        attr = { 'functionToCall': result[0].functionToCall, isYOY: false, 'idType': 'crm', 'agentname': row['ASM Name'],
                                         'highriseid': row['ASM Id'], 'agentid': row['ASM Id'], 'AWNHidden': AWNHiddenValue, 'column': ele, 
                                         'level': row['ASM Level'], 'agentMarketingCompany': agentMarketingCompany, 'startDate':startDate,'endDate':endDate };
                                        return $('<a>')
                                            .attr(attr)
                                            .text(data)
                                            .wrap('<div></div>')
                                            .parent()
                                            .html();
                                    } else if(['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'].includes(ele) && [ApiConstant.getDebtMonthlyAgentReport].includes(apiName)) {
                                        return '$'+data;
                                    } else {
                                        if(ele === 'AgentVizion Id' && that.activeTabId === 3 && (apiName === ApiConstant.getHighPerformers || apiName === ApiConstant.getHighPerformersTap || apiName === ApiConstant.getLowPerformers || apiName === ApiConstant.getLowPerformersTap) && (that.userRole !== 'Manager' && that.userRole !== 'Agent')){
                                            return data;
                                        } else {
                                            attr = { 'functionToCall': result[0].functionToCall, 'idType': idType, 'agentname': agentName, 'highriseid': highriseId, 'agentid': highriseOrAgentId, 'AWNHidden': AWNHiddenValue, 'column': ele, 'level': row['Level'], 'plan': plan, 'agentMarketingCompany': agentMarketingCompany, 'ProductLine': ProductLineValue };
                                            return $('<a>')
                                                .attr(attr)
                                                .text(data)
                                                .wrap('<div></div>')
                                                .parent()
                                                .html();
                                        }
                                    }

                                } else {
                                    // if(that.activeSubTabId === 2 && ele === 'App-ID'){
                                    let activeSubTab = that.localStorageService.get('activeSubTab');
                                    let AWNHiddenValue = row['AWN Hidden'];
                                    let agentName = row['Agent Name']
                                    if(activeSubTab && Object.keys(activeSubTab).length){
                                        that.activeSubTabId = activeSubTab['id'];
                                    }
                                    if(apiName === 'getApplications' && that.activeSubTabId === 25 && ele === 'App-ID'){
                                        attr = { 'functionToCall': 'showAppDetails', 'insured': row['Insured'], 'appId': row['App-ID'], 'AWNHiddenValue': AWNHiddenValue,'agentname': agentName};
                                            return $('<a>')
                                                .attr(attr)
                                                .text(data)
                                                .wrap('<div></div>')
                                                .parent()
                                                .html();
                                    } else if(apiName === 'getPolicyAlerts' && that.activeSubTabId === 23 && ele === 'AV Ref #' && that.isApplicationsApplicable === 'yes'){
                                        attr = { 'functionToCall': 'openSubmittedApplicationsModal', 'insured': row['Insured'], 'appId': row['AV Ref #'], 'AWNHiddenValue': AWNHiddenValue,'agentname': agentName, column:ele};
                                            return $('<a>')
                                                .attr(attr)
                                                .text(data)
                                                .wrap('<div></div>')
                                                .parent()
                                                .html();
                                    } else {
                                      return data;
                                    }
                                }
                            },
                            'defaultContent': '',
                        });
                    });
                    var paging = tableConfig.paging === 'disabled' ? false : true;
                    var info = tableConfig.info === 'disabled' ? false : true;

                    if (that.activeTabId == 4 && $('#pageSubTabs .active').attr('id') !== 'subtab2' && !$('#' + tableId).closest('.modal-content').length) {
                        that.buttons = [];
                    } else {
                        that.buttons = [];

                        // if (that.activeTabId != 2) {
                        //     that.buttons = [];
                        // } else
                        if(that.activeTabId == 2){
                            that.buttons.push({
                                extend: 'excelHtml5',
                                text: 'Export',
                                title: tableConfig.fileNameToExport,
                                footer: true,
                                action: function (e, dt, button, config) {
                                    var rowSelectedArrValues = [];
                                        if ( this.activeTabId == 2 && (tableConfig.apiName === ApiConstant.getAgentDetails || tableConfig.apiName === ApiConstant.getAgentDetailsTap)) {
                                            var table = $('#table2').DataTable();
                                            var data = table.rows('.selected').data();
                                            var arrValues = JSON.stringify(data.toArray());
                                            rowSelectedArrValues = JSON.parse(arrValues);
                                        } else {
                                            rowSelectedArrValues = [];
                                        }
                                        if (rowSelectedArrValues.length) {
                                            // var getParams = this.getParameters;
                                            // var data = rowSelectedArrValues;
                                            // var csv = this.JSON2CSV(rowSelectedArrValues, getParams);
                                            // var blob = new Blob(['\ufeff', csv]);

                                            // var reader = new FileReader();
                                            // reader.onload = function () {
                                            //     var is_iPad = navigator.userAgent.match(/iPad/i) !== null;
                                            //     if (is_iPad) {
                                            //         // Chrome, Firefox, and Chrome and Safari on iOS
                                            //         var dataUrl = reader.result;
                                            //         var open = window.open(dataUrl);
                                            //         if (open === null || typeof (open) === 'undefined') {
                                            //             var blobUrl = URL.createObjectURL(blob);
                                            //             window.location.href = blobUrl;
                                            //         }
                                            //     } else {
                                            //         // works on IE and Edge to save file, would also work on Chrome, Firedox for desktop
                                            //         saveAs(blob, 'Agents' + '.csv');
                                            //     }
                                            // };
                                            // reader.readAsDataURL(blob);
                                        } else {
                                            var params1 = this._commonService.getParameters();
                                            //params.breadcrumbFilters = this.breadcrumbFilters;
                                            // if((tableConfig.api_name === '/getAgentReportPolicies' && this.activeAgentTab === 'tab3') ||
                                            //     tableConfig.api_name === '/getYearlyPoliciesTAP' || tableConfig.api_name === '/getYearlyPoliciesTAP'){
                                            //     params.contractDateInterval = '';
                                            // }
                                            // if (this.params) {
                                            //     params.agentId = this.params.agentId;
                                            // }
                                            // if(this.activeTab === 'tab4' && $('#pageSubTabs li.active').attr('id') === 'subtab2')	{
                                            //     if (tableConfig.api_name === 'getPolicies' || tableConfig.api_name === "/getIncentivePolicies") {
                                            //         tableConfig.api_name = 'getPolicies';
                                            //         var startdate = $('#incentivePrograms .active .startDate').attr('startdate');
                                            //         var endDate = $('#incentivePrograms .active .endDate').attr('enddate');
                                            //         var startdateValues = startdate.split('/');
                                            //         params.param3 = startdateValues[2] + '-' + startdateValues[0] + '-' + startdateValues[1];
                                            //         var enddatedateValues = endDate.split('/');
                                            //         params.param4 = enddatedateValues[2] + '-' + enddatedateValues[0] + '-' + enddatedateValues[1];
                                            //         params.policyStatus = 'Active';
                                            //         params.param5 = this.incentiveAgentId;
                                            //         params.param2 = 'All';
                                            //         params.filterDateType = 'Filter Date';
                                            //     }
                                            // }
                                            that.callExportToCSV(params1, tableConfig)
                                        }
                                },
                                className: 'glyphicon glyphicon-save excelButton btn btn-success export-btn'
                            });
                        }
                    }

                    var scroll = $(window).width() >= 1024 ? true : false;
                    var events = [];
                    this.obj = {
                        "bDestroy": true,
                        dom: 'Bfrtip',
                        'scrollX': true,
                        'columns': columns,
                        responsive: false, //responsive,
                        "deferRender": true,
                        'paging': true,
                        'info': info,
                        'order': [],
                        'aaSorting': [],
                        fixedHeader: {
                            header: true,
                            footer: true
                        },
                        searching: true,
                        "aoSearchCols":  searchCols,
                        pagingType: 'full',
                        pageLength: 100,
                        language: {
                            infoFiltered: '',
                            oPaginate: {
                                sNext: '<i class="fa fa-forward"></i>',
                                sPrevious: '<i class="fa fa-backward"></i>',
                                sFirst: '<i class="fa fa-step-backward"></i>',
                                sLast: '<i class="fa fa-step-forward"></i>'
                            }
                        },
                        // stateSave: false,
                        'columnDefs': tableConfig.columnDefs,
                        buttons: [],
                        'initComplete': (ele) => {
                            var count = 200;
                            // $(".badge").html(count);
                            // $('.dataTables_scrollHead thead th').each(function (ele, index) {
                            //     var title = $(this).text();
                            //     if (this.activeTab === 'tab2' && ($('#pageSubTabs .active').attr('id') === 'subtab1' || $('#pageSubTabs .active').attr('id') === undefined) && title.trim() === 'Agent Name' && (tableConfig.api_name === '/getAgentDetails' || tableConfig.api_name === '/getAgentDetailsTap')) {
                            //         if (columnSearchOn.indexOf(title) >= 0) {
                            //             if(this.searchObj.length){
                            //                 this.searchObj.forEach(ele=>{
                            //                     if(ele.column === title){
                            //                         $(this).css('outline', 'none');
                            //                         $(this).html('<span style="">' + title + '</span><div id="agentNameTooltip"><div style="display: inline-block;" data-title="Choose any combination of agents on the current page to export by clicking on their name(s). This feature does not allow selecting agents across multiple pages.">' +
                            //                             '<span id="agentNameTooltipSpan" style="font-family: Glyphicons Halflings !important;margin-left: -30px;" class="glyphicon glyphicon-info-sign helpTip"></span></div>'+
                            //                             '<input type="text" class="col-search-input" style="position: relative;width:100%;" value='+ele.value+' /></div>');


                            //                     } else {
                            //                         $(this).css('outline', 'none');
                            //                         $(this).html('<span style="">' + title + '</span><div id="agentNameTooltip"><div style="display: inline-block;" data-title="Choose any combination of agents on the current page to export by clicking on their name(s). This feature does not allow selecting agents across multiple pages.">' +
                            //                             '<span id="agentNameTooltipSpan" style="font-family: Glyphicons Halflings !important;margin-left: -30px;" class="glyphicon glyphicon-info-sign helpTip"></span></div>'+
                            //                             '<input type="text" class="col-search-input" style="position: relative;width:100%;" placeholder="" /></div>');

                            //                     }
                            //                 })
                            //             } else {
                            //                 $(this).css('outline', 'none');
                            //                 $(this).html('<span style="">' + title + '</span><div id="agentNameTooltip"><div style="display: inline-block;" data-title="Choose any combination of agents on the current page to export by clicking on their name(s). This feature does not allow selecting agents across multiple pages.">' +
                            //                     '<span id="agentNameTooltipSpan" style="font-family: Glyphicons Halflings !important;margin-left: -30px;" class="glyphicon glyphicon-info-sign helpTip"></span></div>'+
                            //                     '<input type="text" class="col-search-input" style="position: relative;width:100%;" placeholder="" /></div>');

                            //             }
                            //        }
                            //         if ($('#pageSubTabs .active').attr('id') === 'subtab1') {
                            //             $('#agentNameTooltip ').addClass('agentname-tooltip-left-align');
                            //         }
                            //     } else {
                            //         if (columnSearchOn.indexOf(title) >= 0) {
                            //             // $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 98%" placeholder="" />');
                            //             if(this.searchObj.length){
                            //                 this.searchObj.forEach(ele=>{
                            //                     if(ele.column === title){
                            //                         $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 98%" value='+ele.value+' />');
                            //                     } else {
                            //                         $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 98%" placeholder="" />');
                            //                     }
                            //                 })
                            //             } else {
                            //                 $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 98%" placeholder="" />');
                            //             }
                            //         }
                            //     }
                            // });
                            if($('#popupContainer').length !== 1 ){
                                var styleString = "";
                                if(this.activeTab == 2 && $('#pageSubTabs .active').attr('id') !== 'subtab2'){
                                    if($('#toggleAgents .toggleActive').attr('api') === 'getAgentDetails' || $('#toggleAgents .toggleActive').attr('api') === 'getAgentDetailsTap'){
                                        styleString = 'position: absolute;top: 28px;display:flex;font-style: italic;';
                                    } else {
                                        if(this.clientType === 'carrier'){
                                            styleString = 'position: absolute;top: 50px;display:flex;font-style: italic;';
                                            $('div.dt-buttons').css('margin-bottom','30px');
                                        } else {
                                            styleString = 'position: relative;top: 38px;display:flex;font-style: italic;';
                                        }
                                    }
                                    this.showTerminatedIcon(styleString, 'table2_container');
                                } else if (this.activeTab == 3){
                                    if($('#pageSubTabs .active').attr('id') === 'subtab6'){
                                        styleString = 'margin-bottom: 10px;display:flex;font-style: italic;';
                                        this.showTerminatedIcon(styleString, 'reportTable');
                                    } else if($('#pageSubTabs .active').attr('id') !== 'subtab6' && $('#pageSubTabs .active').attr('id') !== 'subtab7'){
                                        styleString = 'position: absolute;top: 65px;display:flex;font-style: italic;';
                                        this.showTerminatedIcon(styleString, 'table2_container');
                                    }
                                } else if (this.activeTab ==4 ){
                                    if($('#pageSubTabs .active').attr('id') === 'subtab1'){
                                        styleString = 'position: absolute;top: 0px;display:flex;font-style: italic;';
                                        this.showTerminatedIcon(styleString, 'reportTable');
                                    } else if($('#pageSubTabs .active').attr('id') === 'subtab5'){
                                        styleString = 'position: absolute;top: 42px;display:flex;font-style: italic;';
                                        $('.dt-buttons').css('margin-bottom','32px');
                                        this.showTerminatedIcon(styleString, 'reportTable');
                                    } else if($('#pageSubTabs .active').attr('id') === 'subtab6'){
                                        styleString = 'position: absolute;top: -3px;display:flex;font-style: italic;';
                                        this.showTerminatedIcon(styleString, 'reportTable');
                                    }
                                }

                                if(this.activeTabId === 2 && this.activeSubTabId === 1){
                                    if($('#toggleAgents .toggleActive').attr('type') === '$'){
                                        $('#agentNameTooltipSpan').show();
                                    }
                                }

                                if(this.activeTab == 2 && ($('#toggleAgents .toggleActive').attr('api') === 'getAgentDetails' || $('#toggleAgents .toggleActive').attr('api') === 'getAgentDetailsTap') && $('#pageSubTabs .active').attr('id') !== 'subtab2'){
                                    var info = "<div id='agentNameTooltip' style='float: right;margin-top: 8px; margin-left: 5px;font-size:14px;'><div style='display: inline-block;' data-title='Choose any combination of agents on the current page to export by clicking on their name(s). This feature does not allow selecting agents across multiple pages.'>" +
                                    "<span id='agentNameTooltipSpan' style='font-family: Glyphicons Halflings !important;' class='glyphicon glyphicon-info-sign helpTip'></span></div>"+
                                    "</div>";
                                    $('#table2_wrapper').prepend(info);
                                    if(this.isTAPRequired === 'yes'){
                                        $('#agentsTabTapBtn').css('margin-right', '165px');
                                    }
                                }
                            } else {
                                if($('#agentNameTooltip').length !== 1 && this.isTAPRequired === 'yes'){
                                    $('#agentsTabTapBtn').css('margin-right', '145px');
                                }
                            }

                            // if(this.checkLoaderFlag === true && this.activeTab ==4 && $('#pageSubTabs .active').attr('id') === 'subtab1'){
                            //     var loader = "<div id='customLoader' style='position: absolute;margin-top: 22px;'> <span class='buttonload-label label label-important' >Your report is downloading...</span>"+
                            //     "<button class='buttonload'><i class='fa fa-spinner fa-spin'></i></button></div>";
                            //     $('#table2_wrapper').prepend(loader);
                            // }
                            if (tableConfig.width === '100%') {
                                $('.modal-dialog .dataTables_scrollHeadInner, .modal-dialog .table').css('width', '100%');
                            }

                            setTimeout(function () {
                                $(window).resize();
                            }, 100);

                            if (this.isTAPRequired === 'yes' && this.sapAndTapObj.reportsTabTapValue === 'tap' && tableConfig.width === '') {
                                $('.modal-dialog .dataTables_scrollHeadInner, .modal-dialog .table').css('width', '100%');
                            }



                            if($('#popupContainer').length === 1){
                                $('#mapPopupTable_wrapper .dataTables_scroll, .modal-dialog .table').css('overflow-x', 'auto');
                            }

                            let activeSubTabObj = that.localStorageService.get('activeSubTab');
                            if(activeSubTabObj){
                                that.activeSubTabId = activeSubTabObj['id'];
                            }
                            if(this.activeTab ==2  && $('#toggleAgents .toggleActive').attr('api') === 'getAgentSummary' && ($('#pageSubTabs .active').attr('id') === 'subtab1' || $('#pageSubTabs .active').attr('id') === undefined)){
                                if(! $('#breadcrumb').length){
                                var breadcrumbEle = '<div class = "col-xs-10 col-sm-10 col-lg-10 padding-left-0 padding-right-0" style="z-index:1;position: absolute;">'+
                                                    '  <ul  id="breadcrumb" class = "col-xs-9 col-sm-9 col-lg-10" >'+
                                                    '  <li><a ng-click="getHomeBreadCrumbData()"><span class="fa fa-home"> </span></a></li>'+
                                                    '</ul></div>';
                                                    // var temp = $compile(breadcrumbEle)(this);
                                                    // angular.element(document.getElementById('tableContainer')).prepend(temp);

                                                    // $('#tableContainer').prepend(breadcrumbEle)
                                }
                            } else if(that.activeTabId === 5 ) {
                                if(that.activeSubTabId === 13 || that.activeSubTabId === 27) {
                                    $('#breadcrumb').show();
                                } else if(that.activeSubTabId === 22 || that.activeSubTabId === 33) {
                                    $('#breadcrumb').show();
                                }
                            } else if(that.activeTabId === 2){
                                if($('#toggleAgents .toggleActive').attr('type') === '#'){
                                    $('#breadcrumb').show();
                                } else{
                                    $('#breadcrumb').hide();
                                }
                                if(that.activeSubTabId === 2 || that.activeSubTabId === 46){
                                    $('#breadcrumb').hide();
                                }
                            } else if(that.activeTabId === 3){
                                if(that.activeSubTabId === 5 || that.activeSubTabId === 6 || that.activeSubTabId === 7 || that.activeSubTabId === 8 || that.activeSubTabId === 44 || that.activeSubTabId === 45){
                                    $('#breadcrumb').hide();
                                } else {
                                    $('#breadcrumb').show();
                                }
                            } else if(that.activeTabId === 6){
                                $('#breadcrumb').hide();
                            }

                            // if ($('#toggleAgents .toggleActive').attr('api') === 'getAgentSummary') {
                            //     $('.dt-buttons a').addClass('glyphicon glyphicon-save excelButton btn btn-success export-btn');
                            //     if ($('.dataTables_scrollHeadInner table.childTable ')) {
                            //         $('.childTableContainer .dataTables_scroll').css('width', this.drillDownWidth + 'px');
                            //         setTimeout(function () {
                            //             $(window).resize();
                            //         }, 100);
                            //     }
                            // }

                            /* Adding width to parent and child table width should be same */
                            // if (this.sapAndTapObj.agentsTabTapValue) {
                            //     if ($('#table2_wrapper')) {
                            //         // var table2Width = angular.copy($('#table2').width());
                            //         // if (table2Width) {
                            //         //     $('#table2_wrapper .dataTables_scrollHeadInner').css('width', table2Width + 'px')
                            //         //     $('#table2_wrapper .dataTables_scrollHeadInner table').css('width', table2Width + 'px')
                            //         // }
                            //     }
                            // }

                            if (tableConfig.headers === 'disabled') {
                                //setTimeout( function () {
                                $('.childTable, .dataTables_scrollHeadInner').css('width', '100%');
                                //}, 0);
                            }
                            if (that.activeTabId == 5 && (that.isTAPRequired === 'yes' || that.isPlacedToggleRequired === 'yes')) {
                                if (apiName === 'getSubmittedMonthlyReport') {
                                    // $('#reportTable .dataTables_scroll .dataTables_scrollHeadInner table > thead  > tr:first-child').remove();
                                    $('#tableContainer  .dataTables_scroll .dataTables_scrollHeadInner table > thead:first-child').prepend('<tr><th colspan="1" style="border-right: 1px solid #ddd">Summary</th><th colspan="2" style="border-right: 1px solid #ddd">January</th><th colspan="2" style="border-right: 1px solid #ddd">February</th><th colspan="2" style="border-right: 1px solid #ddd">March</th><th colspan="2" style="border-right: 1px solid #ddd">April</th><th colspan="2" style="border-right: 1px solid #ddd">May</th><th colspan="2" style="border-right: 1px solid #ddd">June</th><th colspan="2" style="border-right: 1px solid #ddd">July</th><th colspan="2" style="border-right: 1px solid #ddd">August</th><th colspan="2" style="border-right: 1px solid #ddd">September</th><th colspan="2" style="border-right: 1px solid #ddd">October</th><th colspan="2" style="border-right: 1px solid #ddd">November</th><th colspan="2" style="border-right: 1px solid #ddd">December</th><th colspan="2">YTD</th></tr>');
                                } else if (apiName === 'getSubmittedWeeklyReport') {
                                    // $('#reportTable .dataTables_scroll .dataTables_scrollHeadInner table > thead:first-child').remove();
                                    $('#tableContainer  .dataTables_scroll .dataTables_scrollHeadInner table > thead:first-child').prepend('<tr><th colspan="1" style="border-right: 1px solid #ddd">Summary</th><th colspan="2" style="border-right: 1px solid #ddd">4 Weeks Ago</th><th colspan="2" style="border-right: 1px solid #ddd">3 Weeks Ago</th><th colspan="2" style="border-right: 1px solid #ddd">2 Weeks Ago</th><th colspan="2" style="border-right: 1px solid #ddd">1 Week Ago</th><th colspan="2" style="border-right: 1px solid #ddd">YTD</th></tr>');
                                }
                            }
                            $('#table2_filter').css('display', 'none');
                            $('#mapPopupTable_filter').css('display', 'none');
                        },
                        "fnRowCallback": function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                            var intVal = function (i) {
                                let no = i;
                                return typeof no === 'string' ?
                                    i.replace(/[\$,]/g, '') * 1 :
                                    typeof i === 'number' ?
                                        i : 0;
                            };
                            $('.dataTables_scrollHead thead th').each(function (ele, index) {
                                var title = $(this).text();
                                if (that.activeTabId == 2 && ($('#pageSubTabs .active').attr('id') == 1 || $('#pageSubTabs .active').attr('id') === undefined) && title.trim() === 'Agent Name' && (tableConfig.api_name === 'getAgentDetails' || tableConfig.api_name === 'getAgentDetailsTap')) {
                                    if (that.columnSearchOn.indexOf(title) >= 0) {
                                        if(that.searchObj && that.searchObj.length){
                                            that.searchObj.forEach(ele=>{
                                                // if(ele.column === title){
                                                //     $(this).css('outline', 'none');
                                                //     $(this).html('<span style="">' + title + '</span><div id="agentNameTooltip"><div style="display: inline-block;" data-title="Choose any combination of agents on the current page to export by clicking on their name(s). This feature does not allow selecting agents across multiple pages.">' +
                                                //         '<span id="agentNameTooltipSpan" style="font-family: Glyphicons Halflings !important;margin-left: -30px;" class="glyphicon glyphicon-info-sign helpTip"></span></div>'+
                                                //         '<input type="text" class="col-search-input" style="position: relative;width:100%;" value='+ele.value+' /></div>');


                                                // } else {
                                                //     $(this).css('outline', 'none');
                                                //     $(this).html('<span style="">' + title + '</span><div id="agentNameTooltip"><div style="display: inline-block;" data-title="Choose any combination of agents on the current page to export by clicking on their name(s). This feature does not allow selecting agents across multiple pages.">' +
                                                //         '<span id="agentNameTooltipSpan" style="font-family: Glyphicons Halflings !important;margin-left: -30px;" class="glyphicon glyphicon-info-sign helpTip"></span></div>'+
                                                //         '<input type="text" class="col-search-input" style="position: relative;width:100%;" placeholder="" /></div>');

                                                // }
                                                if(ele.column === title){
                                                    $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 100%" value='+ele.value+' />');
                                                } else {
                                                   $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 100%" placeholder="" />');
                                                }
                                            })
                                        } else {
                                            $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 100%" placeholder="" />');

                                            // $(this).css('outline', 'none');
                                            // $(this).html('<span style="">' + title + '</span><div id="agentNameTooltip"><div style="display: inline-block;" data-title="Choose any combination of agents on the current page to export by clicking on their name(s). This feature does not allow selecting agents across multiple pages.">' +
                                            //     '<span id="agentNameTooltipSpan" style="font-family: Glyphicons Halflings !important;margin-left: -30px;" class="glyphicon glyphicon-info-sign helpTip"></span></div>'+
                                            //     '<input type="text" class="col-search-input" style="position: relative;width:100%;" placeholder="" /></div>');

                                        }
                                    }
                                    // if ($('#pageSubTabs .active').attr('id') === 'subtab1') {
                                    //     $('#agentNameTooltip ').addClass('agentname-tooltip-left-align');
                                    // }
                                } else {
                                    if (that.columnSearchOn && that.columnSearchOn.indexOf(title) >= 0) {
                                        // $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 98%" placeholder="" />');
                                        if(that.searchObj && that.searchObj.length){
                                            that.searchObj.forEach(ele=>{
                                                if(ele.column === title){
                                                    $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 100%" value='+ele.value+' />');
                                                } else {
                                                    $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 100%" placeholder="" />');
                                                }
                                            })
                                        } else {
                                            $(this).html(title + ' </br><input type="text" class="col-search-input" style="width: 100%" placeholder="" />');
                                        }
                                    }
                                }
                            });
                            if (aData['Agent Name'] === 'MISSING AGENTS') {
                                $('td:eq(' + 1 + ')', nRow).css('color', 'Red');
                            }
                            if (apiName === 'getAgentDetails'|| apiName === 'getAgentDetailsTap' || apiName === 'getAgentSummaryDetails' || apiName === 'getAgentSummaryDetailsTap' ||
                                apiName === 'getHighPerformers'|| apiName === 'getHighPerformersTap' ||
                                apiName === 'getLowPerformers'|| apiName === 'getLowPerformersTap' ||
                                apiName === 'getPendingByAgent'|| apiName === 'getPendingByAgentTap' ||
                                apiName === 'getLapsedPolicies'|| apiName === 'getLapsedPoliciesTap' ||
                                apiName === 'getAgencyAnalyticsReport' || apiName === 'getAgencyYearlyReport' ||
                                apiName === 'getAgencyYearlyReport' || apiName === 'getAgencyYearlyReportSales' ||
                                apiName === 'getAgentHighestMonthReport' || apiName === 'getNewAgentsReport' || apiName === 'getAgentsIncDecReport' ||
                                apiName === 'getAgentTapDownlineReportSales' || apiName ==='getAgentTapDownlineReport' ||
                                apiName ==='getAgentSummaryPersonalDetails' || apiName === 'getAgentApplicationDetails' ||
                                apiName ==='getAgentApplicationSummaryRollUpDrill' || apiName === 'getAgentSummaryPersonalDetailsRollupDrill' || apiName === 'getExpiringPolicies' || apiName === 'getRTSDetails'
                                ) {
                                var aData1 = Object.values(aData);
                                aData1.forEach((ele, index) => {
									if((that.activeTabId === 5 && that.activeSubTabId === 22) || (that.activeTabId === 3 && that.activeSubTabId === 4)){
										var checkLevelVal = aData1[0];
										if(checkLevelVal === 'Agent' && ele === 'Terminated'){
											that.terminatedCommonFun(apiName, index,nRow);
										}
									} else {
										if(ele === 'Terminated'){
											that.terminatedCommonFun(apiName, index,nRow);
										}
									}

									this.terminatedCommonFun = function(apiName, index,nRow){
										if(apiName === 'getAgencyAnalyticsReport' || apiName === 'getAgentTapDownlineReportSales' || apiName ==='getAgentTapDownlineReport'){
											$('td:eq(' + (1-1) + ')', nRow).css('background-color', '#FFBF00');
										} else if(this.companyFilter === 'no' && (apiName === 'getAgentHighestMonthReport' || apiName === 'getNewAgentsReport' || apiName === 'getAgentsIncDecReport')){
											$('td:eq(' + (0+1) + ')', nRow).css('background-color', '#FFBF00');
										} else {
											$('td:eq(' + (index-1) + ')', nRow).css('background-color', '#FFBF00');
										}
									}

                                });

                            }
                            if (apiName === 'getAgentHighestMonthReport') {
                                var aData1 = Object.values(aData);
                                aData1.shift(); // Removes the first element from an array and returns only that element.
                                aData1.shift(); // Removes the first element from an array and returns only that element.
                                aData1.shift();
                                if(that.companyFilter === 'yes'){
                                    aData1.shift(); // Removes the first element from an array and returns only that element.
                                }
                                aData1.pop(); // Removes the last element from an array and returns only that element.
                                aData1.shift();
                                aData1 = aData1.map((i:any) => { let  ele = typeof i == 'string' ?i.replace(',', ""):i; return Number(ele)});
                                var minMax = that.minMax(aData1);
                                // console.log("row", aData1, "minmax", minMax);
                                aData1.forEach((ele, index) => {
                                    // console.log("ele", ele, index);
                                    if (that.intVal(ele) == minMax[1] && that.intVal(ele) > 0) {
                                        // var tr = $(nRow);
                                        var cellIndex = index + 2;
                                        $('td:eq(' + cellIndex + ')', nRow).css('background-color', '#aeffb4');
                                        // $('td:eq(' + cellIndex + ')', nRow).css('background-color', '#88bdf1');
                                        $('td:eq(' + cellIndex + ')', nRow).css('color', 'black');
                                    }
                                });
                            } else if (apiName === 'getNewAgentsReport') {
                                var aData1 = Object.values(aData);
                                aData1.shift(); // Removes the first element from an array and returns only that element.
                                aData1.shift(); // Removes the first element from an array and returns only that element.
                                aData1.shift();
                                if(that.companyFilter === 'yes'){
                                    aData1.shift(); // Removes the first element from an array and returns only that element.
                                }
                                aData1.pop(); // Removes the last element from an array and returns only that element.
                                aData1.shift();
                                aData1.shift();
                                var minMax = that.minMax(aData1);
                                // console.log("row", aData1, "minmax", minMax);
                                aData1.forEach((ele, index) => {
                                    // console.log("ele", ele, index);
                                    if (that.intVal(ele) == minMax[1] && that.intVal(ele) > 0) {
                                        // var tr = $(nRow);
                                        var cellIndex = index + 3;
                                        $('td:eq(' + cellIndex + ')', nRow).css('background-color', '#aeffb4');
                                        // $('td:eq(' + cellIndex + ')', nRow).css('background-color', '#88bdf1');
                                        $('td:eq(' + cellIndex + ')', nRow).css('color', 'black');
                                    }
                                });
                            } else if (apiName === 'getManagerQuarterlyIncentiveReport' || apiName === 'getASMAgentYearEndBonusIncentiveReport' || apiName === 'getNewManagerQuarterlyIncentiveReport') {
                                var aData1 = Object.values(aData);
                                if(aData['recruited_agent_flag']){
                                    aData1.forEach((e,i)=>{
                                        $('td:eq(' + i + ')', nRow).css('background-color', '#aeffb4');
                                    })
                                } else if (aData['agent_on_pace_flag']) {
                                    aData1.forEach((e,i)=>{
                                        $('td:eq(' + i + ')', nRow).css('background-color', '#ffff91');
                                    })
                                }
                            }
                        },
                        "footerCallback": function (tfoot, data, start, end, display) {
                            var api = this.api();
                            // Remove the formatting to get integer data for summation
                            var intVal = function (i) {
                                let no = i;
                                return typeof no === 'string' ?
                                    i.replace(/[\$,]/g, '') * 1 :
                                    typeof i === 'number' ?
                                        i : 0;
                            };
                            if (apiName === 'getReportDashboard' || apiName === 'getSubmittedMonthlyReport' || apiName === 'getSubmittedWeeklyReport') {
                                // Output the data for the visible rows to the browser's console
                                // console.log( api.rows( {page:'current'} ).data() );
                                //  $('<tfoot/>').append($("#myTable thead tr").clone())

                                setTimeout(function () {
                                    if ($('table >tfoot >tr').length >= 1) {
                                        $('table >tfoot >tr').each(function (i, v) {
                                            $(v).remove();
                                        })
                                    }

                                    $('.dataTables_scrollFootInner table').css('table-layout', 'fixed');

                                    $('tfoot').append('<tr style="position:relative"></tr>');
                                    var columnWidth = [];

                                    // $("#table2 >thead>tr>th").each(function (i, v) {
                                    //     //console.log(i,$(v).width());
                                    //     columnWidth.push($(v).width())
                                    // });
                                    // Total over single pages
                                    this.api().columns().every(function () {
                                        let colIndex = this.index();
                                        let bodyColumn = $('.dataTables_scrollBody td')[colIndex];
                                        columnWidth[colIndex] = bodyColumn.offsetWidth - 26; //paddingleft=16 and right is 10 total=26
                                        if (colIndex == 0) {
                                            $('tfoot tr').append('"<th style="width:' + columnWidth[colIndex] + 'px !important;" class="text-start">Report Total</th>');
                                        } else {
                                            var column = this;
                                            var col = column.header();
                                            // var sum = this.column(colIndex, { page: 'current' })
                                            //     .data()
                                            //     .reduce(function (a, b) {
                                            //         return intVal(a) + intVal(b);
                                            //     }, 0);
                                            var reportTotalValues = Object.values(that.reportTotal);
                                            var sum:any = reportTotalValues[colIndex];

                                            // this.addTableColumnTotalRow(col, colIndex, columnWidth, sum);
                                            if ($(col)[0].innerText === 'Premium') {
                                                sum = '$' + new Intl.NumberFormat().format(sum);
                                                $('tfoot tr').append('<th style="width:' + columnWidth[colIndex] + 'px !important;" class="text-end">' + (sum) + '</th>');
                                            } else {
                                                var colName = $(col)[0].innerText;
                                                let classList = bodyColumn.classList;
                                                if (colName === 'Contract Date') {
                                                    $('tfoot tr').append('<th  style="width:' + columnWidth[colIndex] + 'px !important;" class="'+classList+'"></th>');
                                                } else if (colName.includes('Agent Name')) {
                                                    $('tfoot tr').append('<th style="width:' + columnWidth[colIndex] + 'px !important;"  class="'+classList+'"></th>');
                                                } else {
                                                    if ($('#toggleReport .toggleActive').attr('type') === '$' && colName != 'Count') {
                                                        // if ($('#toggleReport .toggleActive').attr('type') === '$') {
                                                        $('tfoot tr').append('<th style="width:' + columnWidth[colIndex] + 'px !important;"  class="'+classList+'">' + '$' + new Intl.NumberFormat().format(sum) + '</th>');

                                                    } else {
                                                        $('tfoot tr').append('<th style="width:' + columnWidth[colIndex] + 'px !important;" class="'+classList+'">' + new Intl.NumberFormat().format(sum) + '</th>');
                                                    }
                                                }
                                            }
                                        }

                                    });
                                    // $('tfoot').append('<tr style="position:relative"></tr>');
                                    // var columnWidth = [];

                                    // $("#table2 >thead>tr[role='row']>th").each(function (i, v) {
                                    //     // console.log(i,$(v).width());
                                    //     columnWidth.push($(v).width())
                                    // });
                                    // // Total over single pages
                                    // this.api().columns().every(function () {
                                    //     let colIndex = this.index();
                                    //     var col = this.header();
                                    //     if (colIndex == 0) {
                                    //         $('tfoot tr').append('"<th style="width:' + columnWidth[colIndex] + 'px !important;" class="text-center">Report Total</th>');
                                    //     } else {
                                    //         // var sum = this
                                    //         //     .column(colIndex, { page: 'all' })
                                    //         //     .data()
                                    //         //     .reduce(function (a, b) {
                                    //         //         return intVal(a) + intVal(b);
                                    //         //     }, 0);
                                    //         if(this.reportTotal && this.reportTotal[0]) {
                                    //             var reportTotalValues = Object.values(this.reportTotal[0]);
                                    //             var sum = reportTotalValues[colIndex];
                                    //             this.addTableColumnTotalRow(col, colIndex, columnWidth, sum);
                                    //         }
                                    //         // $('tfoot tr').append('<th style="width:' + columnWidth[colIndex] + 'px !important;">' + new Intl.NumberFormat().format(sum) + '</th>');
                                    //     }
                                    // });
                                }.bind(this));
                            }
                            // var size = 0;
                            // aaData.forEach(function(x) {
                            //     size += (x['size']);
                            // });
                            // $(api.column(1).footer()).html(size);
                        },
                        "createdRow": function( row, data, dataIndex ) {
                            $(row).attr('HierarchyId', data.HierarchyId);
                            $(row).attr('HierarchyName', data.HierarchyName);
                            $(row).attr('LevelName', data.LevelName);
                        }
                    };

                    if (tableConfig.serverSideProcessing !== false) {
                        if (tableConfig.api_name === ApiConstant.getAgencyYearlyReport || tableConfig.api_name === ApiConstant.getAgencyYearlyReport || tableConfig.api_name === ApiConstant.getAgencyYearlyReportSales ||
                            tableConfig.api_name === ApiConstant.getPointsDistribution) {
                            tableConfig.serverSideProcessing = true;
                        }
                    }

                    if (tableConfig.serverSideProcessing) {
                        this.seachTableColunBy = '';
                        //Enable Server side processing
                        this.obj.processing = true;
                        this.obj.serverSide = true;
                        this.obj.paging = true;
                        this.obj.bDestroy = true;
                        this.obj.bJQueryUI = true;
                        // apiName === '/getAgentSummary' || apiName === '/getAgentSummaryTap'  ||  apiName === '/getAgentSummaryDetails' || apiName === '/getAgentSummaryDetailsTap'
                        if (apiName === ApiConstant.getNewAgentsReport || apiName === ApiConstant.getStateSaleDataReport || apiName === ApiConstant.getStatePoliciesDataReport || apiName === ApiConstant.getStatePoliciesData || apiName === ApiConstant.getStateSaleData) {
                            this.obj.serverSide = false;
                            //Pass all the data
                            this.obj.data = response;
                            // request.columns.forEach(function (ele) {
                            //     if (ele.search.value.length) {
                            //         that.popupSearchObj.push({
                            //             column: ele.data,
                            //             value: ele.search.value
                            //         })
                            //     }
                            // });
                            // obj.data.params.searchObj = that.popupSearchObj;
                            let search = $('#mapPopupTable_wrapper .dataTables_scrollHeadInner th')
                            let popupSearchObj = [];
                            for(let col of search){
                                let s = col?.querySelector('col-search-input');
                                if (s.value.length) {
                                            popupSearchObj.push({
                                                column: col.textContent.trim(),
                                                value: s.value
                                            })
                                        }
                            }
                            this._commonService.tableSearchObj = popupSearchObj;
                            console.log('this._commonService.tableSearchObj',this._commonService.tableSearchObj);
                        } else {
                            this.obj.ajax = this.tableDataOperations({
                                'type': 'POST',
                                api: apiName,
                                data: { params: params, 'length': this.pageOffset, 'count': tableDataLength },
                                tableData: response
                            });
                        }

                    } else {
                        //Pass all the data
                        this.obj.data = response;
                    }
                    if (tableConfig.scrollY) {
                        this.obj.scrollY = tableConfig.scrollY;
                        this.obj.scrollCollapse = true;
                    }
                    if ($('#pageSubTabs .active').attr('id') === 'subtab8') {
                        this.obj.paging = false;
                    }
                    // Initialize the datatable
                    // this.rowSelectedArrValues = [];
                    var table = $('#' + tableId)
                        .DataTable(this.obj)
                        .off('click')
                        .on('click', 'a', function (ele) {
                            $('#spinner').show();
                            if ($('a.dt-button').length > 0) {
                                $('#spinner').hide();
                            }
                        })
                        .on('click', 'tr', function () {
                            if (that.activeTabId == 2 && ($('#pageSubTabs .active').attr('id') === 'subtab1' || $('#pageSubTabs .active').attr('id') === undefined) && (tableConfig.api_name === '/getAgentDetails' || tableConfig.api_name === '/getAgentDetailsTap')) {
                                $(this).toggleClass('selected');
                            }
                        }).on('search.dt', function() {
                            setTimeout(()=>{
                            let search = $('#mapPopupTable_wrapper .dataTables_scrollHeadInner th');
                            search?.length==0 ? search = $('#table2_wrapper .dataTables_scrollHeadInner th') : '';
                            let popupSearchObj = [];
                            for(let i=0; i<search.length; i++){
                                let s = search[i]?.children[1]?search[i]?.children[1]:null;
                                if (s?.value?.length) {
                                            popupSearchObj.push({
                                                column: search[i].textContent.trim(),
                                                value: s.value
                                            })
                                        }
                            }
                            this._commonService.tableSearchObj = popupSearchObj;
                            },1000)
                        }.bind(this));

                    function delay(callback, ms) {
                        var timer;
                        return function() {
                            var context = this, args = arguments;
                            clearTimeout(timer);
                            timer = setTimeout(function () {
                                callback.apply(context, args);
                            }, ms || 0);
                        };
                    }

                    this.table = table;

                    this.table.columns().every(function(column) {
                        var table = this;
                        // setTimeout(()=>{
                        $('input', this.header()).on('keyup change', delay(function(e) {
                            // console.log("keyup");
                            var searchBy = this.value;
                            if (table.search() !== searchBy) {
                                that.seachTableColunBy = this.value;
                                that.seachTableColunIndex = column;
                                table.search(this.value).draw();
                            }
                        }, that.keyStrokesDelay));
                        // },2000);

                        //Get all the headers from the table
                        var headers = table.columns().header().toArray();
                        var temp = [];

                        //get all the events binded to the headers
                        headers.forEach(function (header, index) {
                            temp = [];
                            $.each($._data(headers[index], 'events'), function (name, obj) {
                                temp[name] = obj[0];
                            });
                            events.push(temp);
                        });

                        //bind erlear events to the header again
                        $('input', this.header()).on('blur', function (e) {
                            var $th = $(e.target).closest('th');
                            var headerIndex = table.column($th[0]).index();
                            $th.unbind();
                            for (let name in events[headerIndex]) {
                                $th.bind(name, events[headerIndex][name]);
                            }
                        });

                        //unbind events from the header
                        $('input', this.header()).on('focus', function (e) {
                            $(e.target).closest('th').unbind();
                        });
                    });
                } else {
                    $('#table2_filter').css('display', 'none');
                    $('#mapPopupTable_filter').css('display', 'none');
                    if(params.tabId === 4){
                        $('#table2_wrapper').hide();
                        $('#noDataAvailable').show();
                    } else if(params.tabId === 5){
                        $('#' + tableId).html('<div class=\'no-data\'>No Data Available</div>');
                        // $('#terminatedAgentRecords').hide();
                    } else {
                        // if ( this.activeTabId === 'tab5') {
                        //     $('#tableFilter').remove();
                        // }
                        $('#' + tableId).html('<div class=\'no-data\'>No Data Available</div>');
                        // $('#terminatedAgentRecords, #agentNameTooltipSpan').hide();
                        if ($('#popupContainer').length != 1) {
                            $('#breadcrumb').hide();
                        }
                    }
                    // $('#' + tableId).html('<div class=\'no-data\'>No Data Available</div>');
                }
                if(this.activeTabId === 5 && this.activeSubTabId === 27) {
                  if(this.userRole != 'Agent' && this.apiName!=ApiConstant.getAgentBonusIncentiveReport) {
                    $('#breadcrumb').show();
                  } else {
                    $('#breadcrumb').hide();
                  }
                } else if(this.activeTabId === 5 && (this.activeSubTabId === 13 || this.activeSubTabId === 35)) {
                  $('#breadcrumb').hide();
                }
            });
        }
    };

    getColumnToolTips = (column, apiName) => {
        let tooltipVal = '';
        if(apiName == 'getDebtBalanceReport') {
            let svgVal = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            class="bi bi-info-circle-fill" viewBox="0 0 16 16">
            <path
                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
            </svg>`
            const tooltip = this.tableTooltips?.find(obj => column in obj);
            if(tooltip) {
                tooltipVal = `<span type="button"
                data-toggle="tooltip" data-bs-placement="bottom"
                title="${tooltip[column]}"
                class="tableTooltips">
                ${svgVal}</span>`
            }
        }
        return tooltipVal;
    }

    showTerminatedIcon = (styleStr, tableId)=>{
        var terminatedLegend = "<div style='"+styleStr+"'>"+
        "<div style='height: 12px;width: 12px;border-radius: 10px;background-color: #FFBF00;'></div>"+
        "<div style='font-size: 14px;padding-left: 5px;line-height: 1;'>Terminated Agent Records</div></div>";
        $('#'+tableId).prepend(terminatedLegend);
    };

    terminatedCommonFun = (apiName, index, nRow) =>{
        if(apiName === ApiConstant.getAgencyAnalyticsReport || apiName === ApiConstant.getAgentTapDownlineReportSales || apiName === ApiConstant.getAgentTapDownlineReport){
            $('td:eq(' + (1-1) + ')', nRow).css('background-color', '#FFBF00');
        } else if(this.companyFilter === 'no' && (apiName === ApiConstant.getAgentHighestMonthReport || apiName === ApiConstant.getNewAgentsReport || apiName === ApiConstant.getAgentsIncDecReport)){
            $('td:eq(' + (0+1) + ')', nRow).css('background-color', '#FFBF00');
        } else if(apiName === ApiConstant.getAgentSummaryPersonalDetails || apiName === ApiConstant.getAgentSummaryPersonalDetailsRollupDrill) {
            if(index <= 5){
                $('td:eq(' + (index-1) + ')', nRow).css('background-color', '#FFBF00');
            }
        } else if(apiName === ApiConstant.getAgentHighestMonthReport || apiName === ApiConstant.getAgentsIncDecReport) {
            $('td:eq(' + (1) + ')', nRow).css('background-color', '#FFBF00');
        } else {
            $('td:eq(' + (index-1) + ')', nRow).css('background-color', '#FFBF00');
        }
    }

    minMax = function (items){
        return items.reduce((acc, val) => {
            acc[0] = (acc[0] === undefined || this.intVal(val) < acc[0]) ? this.intVal(val) : acc[0]
            acc[1] = (acc[1] === undefined || this.intVal(val) > acc[1]) ? this.intVal(val) : acc[1]
            return acc;
        }, []);
    }

    intVal = (i)=> {
        let val = i;
        typeof val === 'string' ? i.replace(/[\$,]/g, '') * 1 : typeof i === 'number' ? i : 0;
        return i;
    };

    @HostListener('window:keydown', ['$event'])
     handleKeyDown(event: KeyboardEvent) {
    }

    tableDataOperations = (obj) =>{
        if ($('#popupContainer').length === 1) {
            return (request, drawCallback, settings) =>{
                this.renderUpdatedTableData(true, request, drawCallback, obj)
            }
        } else {
            return (request, drawCallback, settings)=> {
                this.renderUpdatedTableData(false, request, drawCallback, obj)
                // setTimeout(function () {
                //     $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
                // })
            }
        }
    }

    updateTableData(request, data, count, drawCallback) {
        if (request.order.length) {
            //Get the column by which we need to sort the table
            var column = request.columns[request.order[0].column].data;

            //Sort the object
            data = data.sort(function (a, b) {
                a[column] = a[column] !== null ? a[column] : '';
                b[column] = b[column] !== null ? b[column] : '';
                if (request.order[0].dir === 'asc') {
                    return a[column] > b[column] ? 1 : a[column] < b[column] ? -1 : 0;
                } else {
                    return a[column] < b[column] ? 1 : a[column] > b[column] ? -1 : 0
                }
            })
        }
        var requestStart = request.start;
        var drawStart = request.start;

        var requestLength = request.length;
        var requestEnd = requestStart + requestLength;
        var json:any = {};
        var pageData = data;
        var baseTableColumns = ['Premium', 'Change', 'Q1', 'Q2',
            '1 Premium', '2 Premium', '3 Premium', '4 Premium', 'Jan Premium', 'Feb Premium', 'Mar Premium', 'Apr Premium',
            'May Premium', 'Jun Premium', 'Jul Premium', 'Aug Premium', 'Sep Premium', 'Oct Premium', 'Nov Premium',
            'Dec Premium', 'YTD Premium'];

        var popupTableColumns = ['Target Premium', 'Submitted Premium', 'Total Premium', 'Commission Basis', 'TAP', 'Commission', 'Annualized Premium', 'Initial Deposit Amount', 'Total Estimated 1035', 'Balance']

        var saleToggleColumns = ['YTD Total', 'YTD', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        pageData.forEach(function (ele) {
            baseTableColumns.forEach(columnName => {
                // if( $('#toggleReport .toggleActive').attr('type') === '$' && this.activeAgentTab === 3 ){
                //     if (ele[columnName] || ele[columnName] === 0) {
                //         ele[columnName] = this.formatter.format(ele[columnName]);
                //     }
                // } else if($('#toggleReport .toggleActive').attr('type') === '#' && this.activeAgentTab === 'tab3' && (columnName === 'Q1' || columnName === 'Q2' || columnName === 'Change')){
                //     return ele[columnName];
                // }
                if (ele[columnName] || ele[columnName] === 0) {
                    ele[columnName] = new Intl.NumberFormat().format(ele[columnName]);
                }
                return ele[columnName];
            });
            if ($('#popupContainer').length === 1) {
                popupTableColumns.forEach(columnName => {
                    if (ele[columnName] || ele[columnName] === 0) {
                        ele[columnName] = new Intl.NumberFormat().format(ele[columnName]);
                    }
                });
            }
            if (ele['CRM Id'] || ele['CRM Id'] === 0) {
                ele['CRM Id'] = ele['CRM Id'].toString();
            }
            if (ele['Split %'] || ele['Split %'] === 0) {
                ele['Split %'] = new Intl.NumberFormat().format(ele['Split %']) + '%';
            }
            if (ele['Commission Rate'] || ele['Commission Rate'] === 0) {
                ele['Commission Rate'] = new Intl.NumberFormat().format(ele['Commission Rate']) + '%';
            }
            if (ele['Commission Split'] || ele['Commission Split'] === 0) {
                ele['Commission Split'] = new Intl.NumberFormat().format(ele['Commission Split']) + '%';
            }
            if (ele['Percentage'] || ele['Percentage'] === 0) {
                ele['Percentage'] = new Intl.NumberFormat().format(ele['Percentage']) + '%';
            }
            if (ele['% Gross Producing'] || ele['% Gross Producing'] === 0) {
                ele['% Gross Producing'] = new Intl.NumberFormat().format(ele['% Gross Producing']) + '%';
            }
            if (ele['% Net Producing'] || ele['% Net Producing'] === 0) {
                ele['% Net Producing'] = new Intl.NumberFormat().format(ele['% Net Producing']) + '%';
            }

            let percentageValColumnArr = ['% Lapsed in 3 months', '% Lapsed in 6 months', '% Lapsed in 9 months', '% Lapsed in 13 months'];
            Object.keys(ele).forEach(key => percentageValColumnArr.includes(key) && (ele[key] += '%'));

            if ($('#toggleReport .toggleActive').attr('id') === 'saleToggle') {
                saleToggleColumns.forEach(columnName => {
                    if (ele[columnName] || ele[columnName] === 0) {
                        //ele[columnName] = formatter.format(ele[columnName]);
                        ele[columnName] = new Intl.NumberFormat().format(ele[columnName]);
                    }
                });
            }
        });
        json.draw = request.draw;
        json.data = pageData;
        json.iTotalDisplayRecords = count;
        if (json.data.length || (this.seachTableColunBy && this.seachTableColunBy.length)) {
            drawCallback(json);
            var totalRows = json.data.length < 10 ? json.data.length : requestEnd;
            var toRows = request.start + json.data.length;
            $('#mapPopupTable_info').html('Showing ' + (drawStart + 1) + ' to ' + toRows + ' of ' + count + ' entries');
        }
    }

    //formats the number to the $ currency
    // const formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: 'USD',
    //     minimumFractionDigits: 0,
    //     maximumFractionDigits: 0
    // });
    // const percentformatter = new Intl.NumberFormat('en-US', {
    //     style: 'percent',
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    //     // minimumFractionDigits: 0
    // })

    renderUpdatedTableData(isPopupRequest, request, drawCallback, obj){
        var count = obj.data.count;
        var data = obj.tableData;//angular.copy(obj.tableData);
        var column = request.order && request.order.length > 0 ? request.columns[request.order[0].column].data : '';
        var columnOrderType = request.order && request.order.length > 0 ? request.order[0].dir : 'asc';
        if(this.activeTabId === 5 && [27,37].includes(this.activeSubTabId)){
            const updated_params = obj.data.params;
            if(updated_params['start_date_temp']) {
                this.program_start_date = updated_params['start_date_temp'];
            }
            if(updated_params['end_date_temp']) {
                this.program_end_date = updated_params['end_date_temp'];
            }
            let fromStartdate = $('#incentivePrograms .active .startDate').text();
            let startdate = fromStartdate.split(':');
            let startdateValues = startdate[1]?.split('/');
            
            let toEndDate = $('#incentivePrograms .active .endDate').text();
            let endDate = toEndDate.split(':');
            let enddatedateValues = endDate[1]?.split('/');

            if(startdate[1]){
                obj.data.params['param3'] = startdateValues[2].trim() + '-' + startdateValues[0].trim() + '-' + startdateValues[1].trim();
            } else {
                obj.data.params['param3'] = this.program_start_date;
            }

            if(endDate[1]){
                obj.data.params['param4'] = enddatedateValues[2].trim() + '-' + enddatedateValues[0].trim() + '-' + enddatedateValues[1].trim();
            } else {
                obj.data.params['param4'] = this.program_end_date;
            }
            obj.data.params['policyStatus'] = 'active';
        }
        obj.data.params.column = column;
        // obj.data.params.url = obj.api;
        obj.data.params.columnOrderType = columnOrderType;
        obj.data.params.pageNo = request.start;
        obj.data.params.offset = 100;
        // let tableDataConfig = {};
        // tableDataConfig = this.tableService.getTableDataConfig(obj.api);
        // if(obj.api === 'getPolicyAlerts'){
        //     obj.data.params.tableDataConfig = tableDataConfig['tableDataConfig']
        // } else {
        //     obj.data.params.tableDataConfig = tableDataConfig['tableDataConfig'].filter(obj => {
        //         return obj.role === JSON.parse(localStorage.getItem('userInfo'))['role'];
        //     });
        // }
        if(isPopupRequest){
            this.popupSearchObj = [];
            let that = this;
            request.columns.forEach(function (ele) {
                if (ele.search.value.length) {
                    that.popupSearchObj.push({
                        column: ele.data,
                        value: ele.search.value
                    })
                }
                /*data = $.map(data, function (elem, index) {
                    var column = ele.data;
                    if (!ele.search.value.length) {
                        return elem;
                    } else {
                        if (elem[column] && elem[column].toLowerCase().indexOf(ele.search.value.toLowerCase()) !== -1) {
                            return elem;
                        }
                    }
                });*/
            });
            obj.data.params.searchObj = that.popupSearchObj;
            this._commonService.tableSearchObj = that.popupSearchObj;
        } else {
            let that = this;
            request.columns.forEach(function (ele) {
                // if (ele.search.value.length) {
                //     this.searchObj.push({
                //         column: ele.data,
                //         value: ele.search.value
                //     })
                // }
                var objIndex;
                if(that.searchObj.length){
                    objIndex = that.searchObj.findIndex(data=> data.column === ele.data);
                }
                if (ele.search.value.length) {
                    if(objIndex > -1){
                        that.searchObj = that.searchObj?.filter(ele=>ele.api === obj.api)
                        if( that.searchObj) {
                            that.searchObj[objIndex].value = ele.search.value;
                            that.searchObj[objIndex].api = obj.api;
                        } else {
                            that.searchObj =[];
                        }
                    } else {
                        that.searchObj.push({
                            column: ele.data,
                            value: ele.search.value,
                            api: obj.api
                        })
                    }
                } else {
                    if(objIndex > -1){
                        that.searchObj.splice(objIndex, 1)
                    }
                }
                /*data = $.map(data, function (elem, index) {
                    var column = ele.data;
                    if (!ele.search.value.length) {
                        return elem;
                    } else {
                        if (elem[column] && elem[column].toLowerCase().indexOf(ele.search.value.toLowerCase()) !== -1) {
                            return elem;
                        }
                    }
                });*/
            });
            if(obj.api == "getWeeklyPremiumByAgent") {
                obj.data.params.param3 = obj.data.params.start_date_temp;
            }
           obj.data.params.searchObj = that.searchObj;
           this._commonService.tableSearchObj = that.searchObj;
        }

        // console.log("search obj", searchObj)
        if (request.draw == 1) {
            this.updateTableData(request, data, count, drawCallback);
        } else {
            this._commonService.showLoader();
            if(this.activeSubTabId == 40) {
                obj.data.params['param4'] = obj.data.params['end_date_temp'] ? obj.data.params['end_date_temp'] : obj.data.params['param4'];
            }

            if(this.activeSubTabId == 42) {
                obj.data.params['param3'] = this.localStorageService.get('debtYear');
                obj.data.params['salepolicytoggle'] = '$';
            }
            this._commonService.getTableData(obj.data.params, obj.api).subscribe(response => {
                this._commonService.hideLoader();
                if (response && response['data'] && response['data'].length) {
                    data = response['data'];// angular.copy(response.data);
                    count = response['totalCount'][0] ? response['totalCount'][0].count : 0;
                    if((obj.api === 'getAgencyYearlyReport' || obj.api === 'getAgencyYearlyReportSales' || obj.api === 'getAgencyAnalyticsReport')){
                        if (data[0] && data[0].HierarchyName && data[0].HierarchyId && data[0].LevelName) {
                            if (data.length == 1 && obj.data.params.searchObj ) {
                                var agentName = data[0].HierarchyName.includes('||') ? data[0].HierarchyName.split('||') : data[0].HierarchyName;
                                var agentId = data[0].HierarchyId.includes(',') ? data[0].HierarchyId.split(',') : data[0].HierarchyId;
                                var LevelName = data[0].LevelName.includes(',') ? data[0].LevelName.split(',') : data[0].LevelName;
                                var breadcrumbFilters =[];
                                if(agentId && LevelName){
                                    var hideBreadcrumbFlag = false;
                                    if(this.userRole.includes('level')){
                                        hideBreadcrumbFlag = true;
                                    }
                                    if(Array.isArray(LevelName)){
                                        LevelName.forEach((ele, index) => {
                                             breadcrumbFilters.push({
                                                agentid: agentId[index],
                                                level: ele
                                            });
                                            this.addToBreadcrumb(agentName[index], agentId[index], ele, obj.api, hideBreadcrumbFlag);
                                        })
                                    } else {
                                        if(agentName !== '' && obj.data.params.searchObj.length){
                                            breadcrumbFilters.push({
                                                agentid: agentId,
                                                level: LevelName
                                            });
                                            this.addToBreadcrumb(agentName, agentId, LevelName, obj.api, hideBreadcrumbFlag);
                                        }
                                    }
                                }
                                this.multipleAgencyLevelsArr = this.getMultipleAgencyValue();
                                this.hideBreadcrumbs(breadcrumbFilters);
                                // if (agentName instanceof Array) {
                                //     for (let i = 0; i < agentName.length; i++) {
                                //         addToBreadcrumb(agentName[i], agentId[i], LevelName[i], obj.api);
                                //     }
                                // } else {
                                //     if(agentName !== '' && obj.data.params.searchObj.length){
                                //         addToBreadcrumb(agentName, agentId, LevelName, obj.api);
                                //     }
                                // }
                            }
                            if(!obj.data.params.searchObj) { // || obj.data.params.searchObj.length ==0

                                if( $('#breadcrumb').length)
                                    $('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
                            }
                        } else if(obj.data.params.searchObj && obj.data.params.searchObj.length ==0) { // || obj.data.params.searchObj.length ==0
                            if( $('#breadcrumb').length)
                                $('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
                        } else {
                            if( $('#breadcrumb').length)
                            $('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
                        }
                    }
                    this.updateTableData(request, data, count, drawCallback);
                } else {
                    // if( $('#breadcrumb').length)
                    // $('#breadcrumb li:nth-child(' + (1) + ')').nextAll().remove();
                    this.updateTableData(request, [], count, drawCallback);
                    // $('#spinner').hide();
                }
            });
        }
    }

    // const percentformatter = new Intl.NumberFormat('en-US', {
    //     style: 'percent',
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2
    //     // minimumFractionDigits: 0
    // })

    callExportToCSV = (params, tableConfig) => {
        // console.log('in callExportToCSV')
    }

    closeCustom = () => {
        // this.activeModal.close();
        this.performanceSapTapEnable = false;
        for (const [key, value] of Object.entries(this._commonService.sapOrTapToggle)) {
            this._commonService.setSapOrTapToggle(this.activeTabId, key, value);
        }
        this._commonService.parametersObj = cloneDeep(this._commonService.getParameters());
        $('#idleTimeoutPrompt').modal('hide');
        $('#businessByToggleBtn .active').removeClass('active');
        $('#performanceChartToggle .active').removeClass('active');
        $('#businessByToggleBtn li:first-child').addClass('active');
        $('#popupTabs .active').removeClass('active');
        if(this.clientType === 'agency'){
            $('#pieChart1 b').html('Policies by Carrier:');
            $('#pieChart2 b').html('Policies by Product Line:');
        } else {
            if(this.isApplicationsApplicable === 'yes'){
                $('#pieChart1 b').html('Applications by Agency:');
                $('#pieChart2 b').html('Applications by Product Line:');
            } else {
                $('#pieChart1 b').html('Policies by Agency:');
                $('#pieChart2 b').html('Policies by Product Line:');
            }
        }
        // this._commonService.setParameters('param5', 'All');
        // this._commonService.setParameters('param11', 'policy');
        // if(this.isApplicationsApplicable === 'yes'){
        //     this._commonService.setParameters('param11', 'application');
        // }
        // this._commonService.setParameters('level', '');
        // this._commonService.setParameters('idType', 'crm');
        // this._commonService.setParameters('breadcrumbFilters', []);
        // this._commonService.setParameters('agentId', '');
        if(this.activeTabId === 3 && this.activeSubTabId === 4){
            this._commonService.setParameters('lob', this.baseFilterLob);
        }
        $('#showTooltip').hide();
    }

    ngOnDestroy() {
        if(this.eventsSubscription){
            this.eventsSubscription.unsubscribe();
        }

        if(this.updateTableSubscription){
            this.updateTableSubscription.unsubscribe();
        }

        if(this.yoytooltipSubscription){
            this.yoytooltipSubscription.unsubscribe();
        }
    }

    updateMultiselectText(){
        try{
            let multiSelectEle:any = $('#policy_multiselect .dropdown-btn span span.ng-star-inserted');
                for(let i=0;i<multiSelectEle.length; i++){
                        if(this.policySelectedItems.length>0){
                            multiSelectEle[i].parentElement.style.float='none';
                            multiSelectEle[i].innerText = `Policy Status (${this.policySelectedItems.length})`;
                        }
                }
        } catch(e){}
        try{
            let multiSelectEle:any = $('#carrier_agency_multiselect .dropdown-btn span span.ng-star-inserted');
                for(let i=0;i<multiSelectEle.length; i++){
                        if(this.carrierOrAgencySelectedItems.length>0){
                            multiSelectEle[i].parentElement.style.float='none';
                            multiSelectEle[i].innerText = `Carrier/Agency (${this.carrierOrAgencySelectedItems.length})`;
                        }
                }
        } catch(e){}
    }

    getAgentNameValue(data=[], key) {
        const nameObject = data.find(item => item.key === key);
        return nameObject ? `${nameObject.value ? nameObject.value : ''}` : '';
    }
}

export var popupParameter:{[key:string]:any} = {};
